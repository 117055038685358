import React, { useState, useEffect, useContext, useRef } from 'react'
import {
    Row,
    Col,
    Container,
    Card,
    CardHeader,
    CardBody,
    Button,
    Modal,
    ModalBody,
    ModalFooter
} from "reactstrap";
import "./FinishOrder.scss";
import { useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { useReactToPrint } from "react-to-print";
import CustomSimpleLottiePlayer from "../components/CustomSimpleLottiePlayer";
import animation from "../animations/delivery.json";
import { numberWithCommas, formatDate } from "../utils/utils";
// import LanguageContext from "../store/LanguageContext";
import { mainContext } from "../store/MainContext";
import Layout from "../components/Reusable/Layout";

const FinishOrder = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const ctxm = useContext(mainContext);

    const navigate = useNavigate();

    useEffect(() => {
        if (!ctxm.finished) {
            navigate("/");
        }
    }, [ctxm.finished]);


    const [showModal, setShowModal] = useState(false);

    // React Toast (Alert)
    const { addToast } = useToasts();

    useEffect(() => {
        if (ctxm.finished === true) {
            function Comp() {
                return <span className="alert-notif">Pesanan berhasil dibuat!</span>;
            }
            addToast(Comp(), {
                appearance: "success",
            });
            setTimeout(() => {
                toggleModal();
            }, 1000);
        }
    }, [ctxm.finished])

    // Print
    const printRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    });

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    return (
        <>
            <Layout>
                <Container className="mb-5">
                    <Row>
                        <Col md="12">
                            <Card className='finish-order'>
                                <CardHeader className='finish-order-header py-4'>
                                    <Row>
                                        <Col md={12}>
                                            <h3 className="mb-0">Finish Order</h3>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col md={12}>
                                            <p className="my-5 mx-md-3" style={{ fontSize: 'calc(0.2vw + 15px)', textAlign: 'justify' }}>Terima kasih telah menggunakan Layanan Pelangi Laundry Coin Delivery kami. Anda akan menerima whatsapp message dari Admin kami dalam 5 menit. Jika Anda tidak menerimanya mohon hubungi ke nomor customer service kami di <b style={{ color: '#EB3B3B' }}>08978774888</b>.</p>
                                        </Col>

                                        <Col md={12} className="mb-5">
                                            <div className='text-sm-end'>
                                                <Button
                                                    className='mx-sm-1'
                                                    style={{
                                                        color: 'white',
                                                        border: '1px solid var(--primary-color)',
                                                        backgroundColor: 'var(--primary-color)',
                                                        fontWeight: '700'
                                                    }}
                                                    onClick={() => setShowModal(true)}
                                                >
                                                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M4.00046 19H16.0005C16.7961 19 17.5592 18.6839 18.1218 18.1213C18.6844 17.5587 19.0005 16.7956 19.0005 16V15C19.0005 14.7348 18.8951 14.4804 18.7076 14.2929C18.52 14.1054 18.2657 14 18.0005 14H8.00046C7.73524 14 7.48089 14.1054 7.29335 14.2929C7.10581 14.4804 7.00046 14.7348 7.00046 15V16C7.00104 16.4078 6.91847 16.8114 6.7578 17.1862C6.59713 17.5611 6.36173 17.8992 6.06598 18.18C5.77024 18.4607 5.42035 18.6783 5.0377 18.8193C4.65505 18.9602 4.24768 19.0217 3.84046 19C3.05462 18.927 2.32537 18.5598 1.79874 17.972C1.27211 17.3842 0.986986 16.6191 1.00046 15.83V1L5.00046 3L8.00046 1L11.0005 3L15.0005 1V14" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg> Receipt
                                                </Button>
                                                <a
                                                    href="/"
                                                >
                                                    <Button
                                                        className='mx-sm-1'
                                                        style={{
                                                            color: 'var(--primary-color)',
                                                            border: '1px solid var(--primary-color)',
                                                            backgroundColor: 'transparent'
                                                        }}
                                                    >Back to Home</Button>
                                                </a>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Layout>

            {/* Modal Receipt */}
            <Modal
                size="md"
                ref={printRef}
                scrollable
                isOpen={showModal}
                toggle={toggleModal}
                centered
                className="modal"
            >
                <ModalBody className="p-4 p-md-4 d-flex flex-column">
                    <Row>
                        <Col xs={3} className="text-muted modal__header">
                            Order: {Math.floor(Math.random() * 100000)}
                        </Col>
                        <Col
                            xs={6}
                            className="d-flex modal__lottie flex-column text-center mx-auto mb-2 justify-content-center align-items-center"
                        >
                            {ctxm.finished && (
                                <>
                                    <CustomSimpleLottiePlayer
                                        animationData={animation}
                                        width="calc(10vw + 5.8rem)"
                                        height="calc(7vw + 3.8rem)"
                                    />
                                    <span
                                        className="my-1 mt-sm-0 text-nowrap"
                                        style={{
                                            fontSize: "calc(15px + 0.5vw)",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Your order is on its way!
                                    </span>
                                </>
                            )}
                        </Col>
                        <Col xs={3} className="text-muted text-end modal__header">
                            {formatDate(Date.now())}
                        </Col>
                    </Row>

                    <span
                        className="mt-4"
                        style={{ fontSize: "calc(8px + 0.4vw)", fontWeight: "700" }}
                    >
                        PAYMENT SUMMARY
                    </span>
                    <table className="mt-2">
                        <thead className="text-muted">
                            <tr>
                                <th>ITEMS</th>
                                <th className="text-center">QUANTITY</th>
                                <th className="text-end">PRICE</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="whitespace"></tr>
                            {ctxm.service.length > 0 && (
                                <>
                                    {ctxm.service.map((item, i) =>
                                        <tr key={i}>
                                            <td>{item.name}</td>
                                            <td align="center">{item.qty}</td>
                                            <td align="right">
                                                Rp. {numberWithCommas(item.qty * item.price)},00
                                            </td>
                                        </tr>
                                    )}
                                </>
                            )}
                            <tr className="whitespace"></tr>
                            <tr className="bordered"></tr>
                            <tr>
                                {ctxm.deliveryPrice > 0 ? (
                                    <>
                                        <td>Delivery Fee <span style={{ marginLeft: '5px', fontSize: "calc(6px + 0.3vw)", fontWeight: '500', color: 'grey' }}> <br />Free Delivery Fee (Disc 50%)</span></td>
                                        <td></td>
                                        <td align="right">
                                            <span style={{ textDecoration: 'line-through' }}>Rp. {numberWithCommas(ctxm.deliveryPrice)},00</span>
                                            <br />
                                            <span>Rp. {numberWithCommas(ctxm.deliveryPrice / 2)},00</span>
                                        </td>
                                    </>
                                ) : (
                                    <>
                                        <td>Delivery Fee</td>
                                        <td></td>
                                        <td align="right">To be info by Admin</td>
                                    </>
                                )}
                            </tr>
                            <tr>
                                <td>Online Charge Fee <span style={{ marginLeft: '5px', fontSize: "calc(6px + 0.3vw)", color: 'grey' }}> <br />Free Charge (Disc 100%)</span></td>
                                <td></td>
                                <td align="right" style={{ textDecoration: 'line-through' }}>Rp. 2,000,00</td>
                            </tr>
                        </tbody>
                    </table>
                    <br />
                    <br />
                    <div className="d-flex fs-4 flex-row justify-content-between mt-0 mt-md-0 mb-md-2 mb-0">
                        <span style={{ fontSize: "calc(15px + 0.5vw)", fontWeight: "700" }}>
                            TOTAL :
                        </span>
                        <span
                            style={{
                                fontSize: "calc(15px + 0.5vw)",
                                color: ctxm.finished ? "rgb(41 169 18)" : "#D63736",
                                fontWeight: "700",
                            }}
                        >
                            Rp. {numberWithCommas(ctxm.TotalPrice)},00
                        </span>
                    </div>
                    <span className="mt-2 mt-md-3" style={{ fontSize: "calc(6px + 0.3vw)", color: 'grey' }}>*Nota ini adalah bukti pembayaran sementara. <br />*Nota yang berlaku adalah nota asli setelah laundry diterima di outlet.</span>
                </ModalBody>
                <ModalFooter
                    className="py-2 py-md-3"
                    style={{ justifyContent: "center" }}
                >

                    <Button
                        size="sm"
                        className="py-1 px-2 py-md-2 px-md-3"
                        color="danger"
                        onClick={handlePrint}
                        style={{
                            color: 'white',
                            border: '1px solid var(--primary-color)',
                            backgroundColor: 'var(--primary-color)',
                            fontSize: "calc(10px + 0.5vw)"
                        }}
                    >
                        Download Receipt
                    </Button>
                    <Button
                        size="sm"
                        className="py-1 px-2 py-md-2 px-md-3"
                        color="secondary"
                        onClick={toggleModal}
                        style={{
                            color: 'var(--primary-color)',
                            border: '1px solid var(--primary-color)',
                            backgroundColor: 'transparent',
                            fontSize: "calc(10px + 0.5vw)"
                        }}
                    >
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
}
export default FinishOrder