import React from 'react'
import { Container } from 'reactstrap'

const News = () => {
    return (
        <Container>
            
        </Container>
    )
}

export default News
