import React, { useState, useContext } from 'react';
import { Collapse, Input, Card, CardImg, CardBody, CardFooter } from "reactstrap";
import { mainContext } from "../../store/MainContext";
import { MdEventNote } from "react-icons/md";
import { numberWithCommas } from "../../utils/utils";
import { HiPencil } from "react-icons/hi";
import "./ItemPriceListCard.scss"

const ItemPricelistCard = (props) => {
    const ctxm = useContext(mainContext);
    const [collapse, setCollapse] = useState(false);

    const handleColapse = () => {
        setCollapse(!collapse);
    }

    return (
        <>
            <Card className='price-card'>
                <CardImg className='price-card-image'
                    alt={props.name}
                    src={props.image}
                    top
                />
                <CardBody>
                    <div className='price-card-title'>
                        <h5>{props.name} <small>{props.subname}</small></h5>
                        <p>Atur jumlah dan beri catatan</p>
                    </div>
                    <span className="d-flex flex-row align-items-center">
                        <button
                            type="button"
                            className="price-card-button minus"
                            onClick={
                                () => {
                                    if (props.item !== 0) {
                                        ctxm.setTotalItem(ctxm.totalItem - 1);

                                        // update data service
                                        ctxm.setService(currentService =>
                                            currentService.map(item =>
                                                item.name === props.name + ' ' + props.subname
                                                    ? {
                                                        ...item,
                                                        qty: props.item - 1
                                                    }
                                                    : item
                                            )
                                        )
                                    }

                                    if (props.item === 1 && ctxm.totalService !== 0) {
                                        ctxm.setTotalService(ctxm.totalService - 1);

                                        // delete data service
                                        ctxm.setService(currentService =>
                                            currentService.filter(item => item.name !== props.name + ' ' + props.subname)
                                        );

                                        setCollapse(false);
                                    }
                                }
                            }
                        >
                            -
                        </button>{" "}
                        <span className="price-card-amout-item">{props.item}</span>
                        <button
                            type="button"
                            className="price-card-button plus"
                            onClick={() => {
                                ctxm.setTotalItem(ctxm.totalItem + 1);

                                if (props.item === 0) {
                                    ctxm.setTotalService(ctxm.totalService + 1);

                                    ctxm.setService(arr => [
                                        ...arr,
                                        {
                                            name: props.name + ' ' + props.subname,
                                            price: props.itemPrice,
                                            qty: props.item + 1,
                                            note: props.note
                                        }
                                    ]);

                                }

                                // update data service
                                ctxm.setService(currentService =>
                                    currentService.map(item =>
                                        item.name === props.name + ' ' + props.subname
                                            ? {
                                                ...item,
                                                qty: props.item + 1
                                            }
                                            : item
                                    )
                                )

                            }}
                        >
                            +
                        </button>
                        {props.item > 0 ? (
                            <button className='price-card-note' type="button" onClick={handleColapse}><HiPencil /> Tulis catatan</button>
                        ) : (
                            <button className='price-card-note' type="button"><HiPencil /> Tulis catatan</button>
                        )}
                    </span>
                    <Collapse isOpen={collapse}>
                        <span className="d-flex align-items-center">
                            <MdEventNote
                                color="#999999"
                                className="me-1 mt-2"
                                size="1.5rem"
                            />
                            <Input
                                className="mt-2 placeholderText"
                                onChange={(e) => {
                                    // update data service
                                    ctxm.setService(currentService =>
                                        currentService.map(item =>
                                            item.name === props.name + ' ' + props.subname
                                                ? {
                                                    ...item,
                                                    note: e.target.value
                                                }
                                                : item
                                        )
                                    )
                                }}
                                type="text"
                                placeholder="Tambahkan catatan..."
                                value={props.note}
                            />
                        </span>
                    </Collapse>
                </CardBody>
                <CardFooter style={{ backgroundColor: 'transparent' }}>
                    <p className='mb-0'>Harga <span className='price-card-price'>Rp {numberWithCommas(props.itemPrice)},00</span></p>
                </CardFooter>
            </Card>
        </>
    )
}
export default ItemPricelistCard