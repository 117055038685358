import React, { useContext } from 'react'
import { Container, Row, Col } from 'reactstrap'
import CustomSimpleLottiePlayer from './CustomSimpleLottiePlayer'
import animation from '../animations/delivery-guy.json'
import LanguageContext from '../store/LanguageContext'

const HighlightAnimation = () => {
    const ctx = useContext(LanguageContext)
    return (
        <Container className='my-5 hlAnimation'>
            <Row className='d-flex flex-column-reverse flex-lg-row'>
                <Col className='p-0 text-center text-lg-left d-flex flex-column justify-content-center'>
                    <h2 style={{ fontSize: 'calc(1rem + 0.7vw)' }}>{ctx.language.hlAnimation1Title}</h2>
                    <p style={{ color: 'var(--color-grey)' }}>
                        {ctx.language.hlAnimation1}</p>
                    <br />
                    <h2 style={{ fontSize: 'calc(1rem + 0.7vw)' }}>{ctx.language.hlAnimation2Title}</h2>
                    <p style={{ color: 'var(--color-grey)' }}>
                        {ctx.language.hlAnimation2}
                    </p>
                </Col>
                <Col className='p-0 d-flex justify-content-center text-center'>
                    <CustomSimpleLottiePlayer animationData={animation} height='auto' width='calc(22vw + 10rem)' />
                </Col>
            </Row>
        </Container>
    )
}

export default HighlightAnimation
