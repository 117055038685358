import React, { useContext, useState, useEffect } from "react";
import {
    Container,
    Navbar,
    NavbarBrand,
    NavbarToggler,
    Collapse,
    Nav,
    NavItem,
    NavLink,
    Button,
} from "reactstrap";
import { NavLink as RRNavLink, useNavigate } from 'react-router-dom';
import "./NavHeader.scss";
import Logo from '../../logo.png'
import { mainContext } from "../../store/MainContext";
import { HiX, HiShoppingCart } from "react-icons/hi";
import { numberWithCommas } from "../../utils/utils";
import { getOperationalTime } from "../../utils/utils";
import ModalOperational from "./ModalOperational";

const NavHeader = () => {
    const ctxm = useContext(mainContext);

    const [isOpen, setIsOpen] = useState(false);
    // const toggle = () => setIsOpen(!isOpen);

    const [showSidebar, setShowSidebar] = useState(false);

    const [openModal, setOpenModal] = useState(false);
    const handleModal = () => setOpenModal(!openModal);


    const handleShowSidebar = () => {
        if (showSidebar === true) {
            setShowSidebar(false);
        } else {
            setShowSidebar(true);
        }
    };

    const toggleShowSidebar = () => {
        setShowSidebar(!showSidebar);
    };

    const [scrollNavbar, setScrollNavbar] = useState(false);

    useEffect(() => {
        if (typeof window !== "undefined") {
            window.addEventListener("scroll", () => {
                if (window.scrollY >= 80) {
                    setScrollNavbar(true);
                } else {
                    setScrollNavbar(false)
                }
            });
        }
    }, [scrollNavbar]);

    const handleShowCart = () => {
        if (ctxm.showCart === true) {
            ctxm.setShowCart(false);
        } else {
            ctxm.setShowCart(true);
        }
    };

    const toggleShowCart = () => {
        ctxm.setShowCart(!ctxm.showCart);
    };

    const navigate = useNavigate();
    const handleNext = () => {
        if (getOperationalTime() === 0) {
            return setOpenModal(true);
        }

        if (ctxm.TotalPrice === 0) {
            ctxm.setShowCart(false);
        } else {
            ctxm.setShowCart(false);
            navigate("/service/address")
        }
    }

    return (
        <>
            <div className="navheader">
                <Navbar light expand="lg" fixed="top" style={{ backgroundColor: '#ffffff' }} className={scrollNavbar ? 'scroll-active' : ''}>
                    <Container>
                        {/* <NavbarToggler onClick={toggle} /> */}
                        <NavbarToggler onClick={handleShowSidebar} />
                        <NavbarBrand href="/" className="pelangi-logo" style={{ margin: '0' }}>
                            <img src={Logo} alt="logo"></img>
                        </NavbarBrand>
                        <Nav className="ml-auto flex-md align-items-center header__navbar justify-content-end" navbar>
                            <NavItem className="d-lg-none">
                                <NavLink><Button outline className="cart-link" onClick={handleShowCart}>{ctxm.totalItem} <HiShoppingCart /></Button></NavLink>
                            </NavItem>
                        </Nav>
                        <Collapse isOpen={isOpen} navbar className="justify-content-end">
                            <Nav className="ml-auto flex-md align-items-center header__navbar justify-content-end" navbar>
                                <NavItem className="mx-2">
                                    <NavLink to="/" tag={RRNavLink}><button className="regular-link">Services</button></NavLink>
                                </NavItem>
                                <NavItem className="mx-2">
                                    <NavLink to="/about" tag={RRNavLink}><button className="regular-link">About</button></NavLink>
                                </NavItem>
                                <NavItem className="mx-2">
                                    <button className="regular-link">Location</button>
                                </NavItem>
                                <NavItem className="mx-2">
                                    <button className="regular-link">Price List</button>
                                </NavItem>
                                <NavItem className="mx-2">
                                    <button className="regular-link">Contact</button>
                                </NavItem>
                                <NavItem className="d-none d-lg-block">
                                    <NavLink><Button outline className="cart-link" onClick={handleShowCart}>{ctxm.totalItem} item <HiShoppingCart /></Button></NavLink>
                                </NavItem>
                            </Nav>
                        </Collapse>
                    </Container>
                </Navbar>
            </div>

            <div
                className={`${ctxm.showCart ? "show-cartbar" : "hidden-cartbar"
                    }`}
            >
                <div className="cartbar-container">
                    <div className="d-flex align-items-center justify-content-between">
                        <Button className="cart-close" onClick={toggleShowCart}>Kembali</Button>
                        <Button className="cart-next" onClick={handleNext}>Lanjut</Button>
                    </div>
                    <br />
                    {!ctxm.finished && ctxm.service.length > 0 && (
                        <>
                            {ctxm.service.map((item, i) =>
                                <div className="cart-detail-item" key={i}>
                                    <hr />
                                    <h5>{item.name}</h5>
                                    <small>{item.qty} x Rp. {numberWithCommas(item.price)}</small>
                                    <span>Rp. {numberWithCommas(item.qty * item.price)}</span>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>

            <div
                className={`${showSidebar ? "show-sidebar" : "hidden-sidebar"}`}
            >
                <HiX onClick={toggleShowSidebar} className="icon-x" />
                <div className="sidebar-container">
                    <div className="mb-3">
                        <RRNavLink to="/" className="regular-link">Services</RRNavLink>
                    </div>
                    <div className="mb-3">
                        <RRNavLink to="/about" className="regular-link">About</RRNavLink>
                    </div>
                    <div className="mb-3">
                        <RRNavLink to="/location" className="regular-link">Location</RRNavLink>
                    </div>
                    <div className="mb-3">
                        <RRNavLink to="/price-list" className="regular-link">Price List</RRNavLink>
                    </div>
                    <div className="mb-3">
                        <RRNavLink to="/contact" className="regular-link">Contact</RRNavLink>
                    </div>
                </div>
            </div>

            {showSidebar && (
                <div className="content-overlay"></div>
            )}

            {ctxm.showCart && (
                <div className="content-overlay"></div>
            )}

            <ModalOperational
                open={openModal}
                handleClose={handleModal}
            />
        </>
    )
};

export default NavHeader;
