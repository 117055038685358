import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import { LanguageContextProvider } from "./store/LanguageContext";
import MainContextProvider from "./store/MainContext";
import { ToastProvider } from "react-toast-notifications";

ReactDOM.render(
  <React.Fragment>
    <ToastProvider
      placement={"top-center"}
      autoDismiss={true}
      autoDismissTimeout={3000}
    >
      <MainContextProvider>
        <LanguageContextProvider>
          <App />
        </LanguageContextProvider>
      </MainContextProvider>
    </ToastProvider>
  </React.Fragment>,
  document.getElementById("root")
);
