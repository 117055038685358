import React, { useContext } from "react";
import { Container, Row, Col } from "reactstrap";
import LanguangeContext from "../store/LanguageContext";
import { BsHandIndexThumb, BsCartDash } from "react-icons/bs";
import { FaArrowRight, FaHandHoldingUsd } from "react-icons/fa";
import { MdOutlineReceiptLong } from "react-icons/md";
import { RiTruckLine } from "react-icons/ri";

const HowItWorks = () => {
  const ctx = useContext(LanguangeContext);

  return (
    <>
      <Container className="d-flex mt-3 mt-md-5 px-5 px-md-0 justify-content-between align-items-center">
        <div className="d-flex flex-column justify-content-center">
          <h2 style={{ fontSize: "calc(24px + 2vw)" }}>ALUR</h2>
          <h5
            style={{
              letterSpacing: "1.2px",
              fontWeight: 700,
              fontSize: "calc(4px + 1.4vw)",
            }}
          >
            DELIVERY ORDER
          </h5>
        </div>
        <div className="d-flex flex-column justify-content-center">
          <h3
            style={{
              color: "#54F3DA",
              fontWeight: 700,
              fontSize: "calc(10px + 1.8vw)",
            }}
          >
            ONLINE ORDERING
          </h3>
          <h5
            style={{
              letterSpacing: "1.2px",
              fontWeight: 700,
              fontSize: "calc(4px + 1.4vw)",
            }}
          >
            A 5-STEP PROCESS
          </h5>
        </div>
      </Container>
      <Container fluid className="mt-2 mt-md-4"
        style={{ backgroundColor: "#54F3DA" }}>
        <Container>
          <Row
            className="py-4 px-0 py-md-5"
          >
            <Col className="d-flex flex-row justify-content-center align-items-center">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <span className="icon-hand">
                  <BsHandIndexThumb size="100%" />
                </span>
                <span className="step-icon d-flex p-2 p-md-3 my-2 my-md-4 fw-bold align-items-center justify-content-center rounded-circle bg-light">
                  1
                </span>
                <h6 className="fw-bold text-center text-flow">
                  {ctx.language.hl3flow1}
                </h6>
              </div>
              <span className="d-flex flex-column align-items-center pt-4 icon-arrow">
                <FaArrowRight size="100%" />
              </span>
              <div className="d-flex flex-column justify-content-center  align-items-center">
                <span className="icon-cart">
                  <BsCartDash size="100%" />
                </span>
                <span className="step-icon d-flex p-2 p-md-3 my-2 my-md-4 fw-bold align-items-center justify-content-center rounded-circle bg-light">
                  2
                </span>
                <h6 className="fw-bold text-center text-flow">
                  {ctx.language.hl3flow2}
                </h6>
              </div>
              <span className="d-flex flex-column align-items-center pt-4 icon-arrow">
                <FaArrowRight size="100%" />
              </span>
              <div className="d-flex flex-column justify-content-center  align-items-center">
                <span className="icon-money">
                  <FaHandHoldingUsd size="100%" />
                </span>
                <span className="step-icon d-flex p-2 p-md-3 my-2 my-md-4 fw-bold align-items-center justify-content-center rounded-circle bg-light">
                  3
                </span>
                <h6 className="fw-bold text-center text-flow">
                  {ctx.language.hl3flow3}
                </h6>
              </div>
              <span className="d-flex flex-column align-items-center pt-4 icon-arrow">
                <FaArrowRight size="100%" />
              </span>
              <div className="d-flex flex-column justify-content-center align-items-center">
                <span className="icon-receipt">
                  <MdOutlineReceiptLong size="100%" />
                </span>
                <span className="step-icon d-flex p-2 p-md-3 my-2 my-md-4 fw-bold align-items-center justify-content-center rounded-circle bg-light">
                  4
                </span>
                <h6 className="fw-bold text-center text-flow">
                  {ctx.language.hl3flow4}
                </h6>
              </div>
              <span className="d-flex flex-column align-items-center pt-4 icon-arrow">
                <FaArrowRight size="100%" />
              </span>
              <div className="d-flex flex-column justify-content-center  align-items-center">
                <span className="icon-truck">
                  <RiTruckLine size="100%" />
                </span>
                <span className="step-icon d-flex p-2 p-md-3 my-2 my-md-4 fw-bold align-items-center justify-content-center rounded-circle bg-light">
                  5
                </span>
                <h6 className="fw-bold text-center text-flow">
                  {ctx.language.hl3flow5}
                </h6>
              </div>
            </Col>
          </Row>
          <Row>
            <Col
              style={{ backgroundColor: "#54F3DA", letterSpacing: "3px" }}
              className="d-flex text-light fw-bold flex-column justify-content-center text-center"
            >
              <p>PELANGI LAUNDRY</p>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default HowItWorks;
