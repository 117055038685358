export function numberWithCommas(value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function formatDate(x) {
    return new Date(x).toLocaleDateString("id-ID", {
        month: "short",
        day: "numeric",
        year: "numeric",
    });
}

export function getOperationalTime() {
    const today = new Date();
    const dayOfWeek = today.getDay();
    const currentHour = today.getHours();

    // Periksa apakah hari ini adalah Senin-Sabtu (1 hingga 6 mewakili Senin hingga Sabtu)
    if (dayOfWeek >= 1 && dayOfWeek <= 6) {
        // Periksa apakah waktu saat ini antara pukul 09.00 hingga 17.00 (inklusif)
        if (currentHour >= 9 && currentHour <= 17) {
            return 1;
        }
    }
    return 0;
}