import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Input,
  Row,
  Col,
  Container,
  Button,
  Label,
  Form,
  FormGroup,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Collapse,
} from "reactstrap";
import { useToasts } from "react-toast-notifications";
import { useReactToPrint } from "react-to-print";
import Branch from "../components/Branch";
import CustomSimpleLottiePlayer from "../components/CustomSimpleLottiePlayer";
import animation from "../animations/delivery.json";
import axios from "axios";
import "./bookingpage.scss";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { send } from "emailjs-com";
import { init } from "emailjs-com";
import LanguageContext from "../store/LanguageContext";
import { MdEventNote } from "react-icons/md";
import ItemPricelist from "../components/ItemPricelist";

init("user_dO2KRHE0dFWDNSfHW6cRp");

//Branch 1 - Pelangi Pakel
const placeid_branch1 = "ChIJDxk1DLYVei4Ro41YTODjlOs";
//Branch 2 - Pelangi Nusukan
const placeid_branch2 = "ChIJx4OS0jIXei4R5E3HjTwvIwQ";
//Branch 3 - Pelangi Gentan
const placeid_branch3 = "ChIJnUnXIpMVei4R754meHcCkko";

const Bookingpage = (props) => {
  const ctx = useContext(LanguageContext);
  const didMount = useRef(false);
  const [destinationName, setDestinationName] = useState("");
  const [origin, setOrigin] = useState("ChIJjaWSfyoUei4R51HTSQ7Kr34"); // Solo Grand Mall
  const [address, setAddress] = useState("");
  const [destination, setDestination] = useState(placeid_branch1);
  const [duration, setDuration] = useState(0);
  const [selectedOption, setSelectedOption] = useState(0);
  // const [mapCenter, setMapCenter] = useState({ lat: -7.575489, lng: 110.824326 });.
  const [isOpenStep1, setIsOpenStep1] = useState(true);
  const [isOpenStep2, setIsOpenStep2] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [finished, setFinished] = useState(false);
  const [addressSelection, setAddressSelection] = useState(false);

  const deliveryPrice = Math.round((duration * 19 / 2) / 1000) * 1000;
  // const deliveryPrice = Math.ceil((duration * 19 / 2) / 1000) * 1000;
  const [phoneContainer, setPhoneContainer] = useState();

  // Payment Process State
  const [selectedPayment, setSelectedPayment] = useState("BCA");
  const [isPaid, setIsPaid] = useState(false);

  //Contact Details Form
  const [firstname, setFirstname] = useState("");
  const [phone, setPhone] = useState("");
  const [laundryNotes, setLaundryNotes] = useState("Tidak ada catatan");
  const [deliveryNotes, setDeliveryNotes] = useState("Tidak ada catatan");

  //Price Definition
  const priceKiloan5kg = 25000;
  const priceKiloan7kg = 30000;
  const priceBantal = 30000;
  const priceGuling = 35000;
  const priceKaos = 10000;
  const priceKemeja = 15000;
  const priceKebaya = 20000;
  const priceJas = 25000;
  const priceJasSetelan = 35000;
  const priceJasRompi = 15000;
  const priceGaunAnak = 15000;
  const priceGaunDewasa = 20000;
  const priceGaunLongGown = 30000;
  const priceGaunPengantin = 75000;
  const priceJaketParasut = 20000;
  const priceJaketJeans = 25000;
  const priceJaketKulit = 30000;
  const priceSajadah = 10000;
  const priceTasKulit = 30000;
  const priceTasKainKecil = 20000;
  const priceTasKainBesar = 30000;
  const priceTopi = 10000;
  const priceSepatu = 25000;
  const priceSepatuKulit = 25000;
  const priceSepatuBoot = 30000;
  const priceSelimutBuluSingle = 10000;
  const priceSelimutBuluKing = 20000;
  const priceBonekaKecil = 10000;
  const priceBoneka = 20000;
  const priceBonekaBesar = 30000;
  const priceBonekaJumbo = 40000;
  const priceBonekaSuperJumbo = 80000;
  const priceBedCover = 30000;
  const priceBedCoverKing = 40000;
  const priceKarpet = 15000;
  const priceKarpetTebal = 20000;
  const priceKorden = 30000;
  const priceKordenJumbo = 40000;
  const priceKoperCabin = 40000;
  const priceKoperMedium = 55000;
  const priceKoperLarge = 80000;
  const priceTikar = 20000;

  //Item Quantity
  const [kiloan5kg, setKiloan5kg] = useState(0);
  const [kiloan7kg, setKiloan7kg] = useState(0);
  const [bantal, setBantal] = useState(0);
  const [guling, setGuling] = useState(0);
  const [kaos, setKaos] = useState(0);
  const [kemeja, setKemeja] = useState(0);
  const [kebaya, setKebaya] = useState(0);
  const [jas, setJas] = useState(0);
  const [jasSetelan, setJasSetelan] = useState(0);
  const [jasRompi, setJasRompi] = useState(0);
  const [gaunAnak, setGaunAnak] = useState(0);
  const [gaunDewasa, setGaunDewasa] = useState(0);
  const [gaunLongGown, setGaunLongGown] = useState(0);
  const [gaunPengantin, setGaunPengantin] = useState(0);
  const [jaketParasut, setJaketParasut] = useState(0);
  const [jaketJeans, setJaketJeans] = useState(0);
  const [jaketKulit, setJaketKulit] = useState(0);
  const [sajadah, setSajadah] = useState(0);
  const [tasKulit, setTasKulit] = useState(0);
  const [tasKainKecil, setTasKainKecil] = useState(0);
  const [tasKainBesar, setTasKainBesar] = useState(0);
  const [topi, setTopi] = useState(0);
  const [sepatu, setSepatu] = useState(0);
  const [sepatuKulit, setSepatuKulit] = useState(0);
  const [sepatuBoot, setSepatuBoot] = useState(0);
  const [selimutBuluSingle, setSelimutBuluSingle] = useState(0);
  const [selimutBuluKing, setSelimutBuluKing] = useState(0);
  const [bonekaKecil, setBonekaKecil] = useState(0);
  const [boneka, setBoneka] = useState(0);
  const [bonekaBesar, setBonekaBesar] = useState(0);
  const [bonekaJumbo, setBonekaJumbo] = useState(0);
  const [bonekaSuperJumbo, setBonekaSuperJumbo] = useState(0);
  const [bedCover, setBedCover] = useState(0);
  const [bedCoverKing, setBedCoverKing] = useState(0);
  const [karpet, setKarpet] = useState(0);
  const [karpetTebal, setKarpetTebal] = useState(0);
  const [korden, setKorden] = useState(0);
  const [kordenJumbo, setKordenJumbo] = useState(0);
  const [koperCabin, setKoperCabin] = useState(0);
  const [koperMedium, setKoperMedium] = useState(0);
  const [koperLarge, setKoperLarge] = useState(0);
  const [tikar, setTikar] = useState(0);

  let waLink = "";

  useEffect(() => {
    if (selectedPayment === "QRIS") {
      waLink = `https://api.whatsapp.com/send/?phone=${phoneContainer}&text=Terima+kasih+telah+menggunakan+jasa+online+order+Pelangi+Laundry.+Pesanan+anda+telah+kami+terima.+Tunggu+kurir+kami+menjemput+laundry+anda.+Have+a+nice+day+!&app_absent=0`;
    } else if (selectedPayment === "BCA") {
      waLink = `https://api.whatsapp.com/send/?phone=${phoneContainer}&text=Terima+kasih+telah+menggunakan+jasa+online+order+Pelangi+Laundry.+Pesanan+anda+telah+kami+terima.+Mohon+kirimkan+bukti+transfer+dan+tunggu+kurir+kami+menjemput+laundry+anda.+Have+a+nice+day+!&app_absent=0`;
    } else {
      waLink = `https://api.whatsapp.com/send/?phone=${phoneContainer}&text=Terima+kasih+telah+menggunakan+jasa+online+order+Pelangi+Laundry.+Pesanan+anda+telah+kami+terima.+Mohon+menyiapkan+pembayaran+tunai+dan+tunggu+kurir+kami+menjemput+laundry+anda.+Have+a+nice+day+!&app_absent=0`;
    }
  }, [selectedPayment])


  const TotalPrice =
    deliveryPrice +
    kiloan5kg * priceKiloan5kg +
    kiloan7kg * priceKiloan7kg +
    bantal * priceBantal +
    guling * priceGuling +
    kaos * priceKaos +
    kemeja * priceKemeja +
    kebaya * priceKebaya +
    jas * priceJas +
    jasSetelan * priceJasSetelan +
    jasRompi * priceJasRompi +
    gaunAnak * priceGaunAnak +
    gaunDewasa * priceGaunDewasa +
    gaunLongGown * priceGaunLongGown +
    gaunPengantin * priceGaunPengantin +
    jaketParasut * priceJaketParasut +
    jaketJeans * priceJaketJeans +
    jaketKulit * priceJaketKulit +
    sajadah * priceSajadah +
    tasKulit * priceTasKulit +
    tasKainKecil * priceTasKainKecil +
    tasKainBesar * priceTasKainBesar +
    topi * priceTopi +
    sepatu * priceSepatu +
    sepatuKulit * priceSepatuKulit +
    sepatuBoot * priceSepatuBoot +
    selimutBuluSingle * priceSelimutBuluSingle +
    selimutBuluKing * priceSelimutBuluKing +
    bonekaKecil * priceBonekaKecil +
    boneka * priceBoneka +
    bonekaBesar * priceBonekaBesar +
    bonekaJumbo * priceBonekaJumbo +
    bonekaSuperJumbo * priceBonekaSuperJumbo +
    bedCover * priceBedCover +
    bedCoverKing * priceBedCoverKing +
    karpet * priceKarpet +
    karpetTebal * priceKarpetTebal +
    korden * priceKorden +
    kordenJumbo * priceKordenJumbo +
    koperCabin * priceKoperCabin +
    koperMedium * priceKoperMedium +
    koperLarge * priceKoperLarge +
    tikar * priceTikar;

  // Noted Laundry
  const [noteKiloan5kg, setNoteKiloan5kg] = useState("");
  const [noteKiloan7kg, setNoteKiloan7kg] = useState("");
  const [noteBantal, setNoteBantal] = useState("");
  const [noteGuling, setNoteGuling] = useState("");
  const [noteKaos, setNoteKaos] = useState("");
  const [noteKemeja, setNoteKemeja] = useState("");
  const [noteKebaya, setNoteKebaya] = useState("");
  const [noteJas, setNoteJas] = useState("");
  const [noteJasSetelan, setNoteJasSetelan] = useState("");
  const [noteJasRompi, setNoteJasRompi] = useState("");
  const [noteGaunAnak, setNoteGaunAnak] = useState("");
  const [noteGaunDewasa, setNoteGaunDewasa] = useState("");
  const [noteGaunLongGown, setNoteGaunLongGown] = useState("");
  const [noteGaunPengantin, setNoteGaunPengantin] = useState("");
  const [noteJaketParasut, setNoteJaketParasut] = useState("");
  const [noteJaketJeans, setNoteJaketJeans] = useState("");
  const [noteJaketKulit, setNoteJaketKulit] = useState("");
  const [noteSajadah, setNoteSajadah] = useState("");
  const [noteTasKulit, setNoteTasKulit] = useState("");
  const [noteTasKainKecil, setNoteTasKainKecil] = useState("");
  const [noteTasKainBesar, setNoteTasKainBesar] = useState("");
  const [noteTopi, setNoteTopi] = useState("");
  const [noteSepatu, setNoteSepatu] = useState("");
  const [noteSepatuKulit, setNoteSepatuKulit] = useState("");
  const [noteSepatuBoot, setNoteSepatuBoot] = useState("");
  const [noteSelimutBuluSingle, setNoteSelimutBuluSingle] = useState("");
  const [noteSelimutBuluKing, setNoteSelimutBuluKing] = useState("");
  const [noteBonekaKecil, setNoteBonekaKecil] = useState("");
  const [noteBoneka, setNoteBoneka] = useState("");
  const [noteBonekaBesar, setNoteBonekaBesar] = useState("");
  const [noteBonekaJumbo, setNoteBonekaJumbo] = useState("");
  const [noteBonekaSuperJumbo, setNoteBonekaSuperJumbo] = useState("");
  const [noteBedCover, setNoteBedCover] = useState("");
  const [noteBedCoverKing, setNoteBedCoverKing] = useState("");
  const [noteKarpet, setNoteKarpet] = useState("");
  const [noteKarpetTebal, setNoteKarpetTebal] = useState("");
  const [noteKorden, setNoteKorden] = useState("");
  const [noteKordenJumbo, setNoteKordenJumbo] = useState("");
  const [noteKoperCabin, setNoteKoperCabin] = useState("");
  const [noteKoperMedium, setNoteKoperMedium] = useState("");
  const [noteKoperLarge, setNoteKoperLarge] = useState("");
  const [noteTikar, setNoteTikar] = useState("");

  //Collapses
  const [showKiloan5kg, setShowKiloan5kg] = useState(false);
  const [showKiloan7kg, setShowKiloan7kg] = useState(false);
  const [showBantal, setShowBantal] = useState(false);
  const [showGuling, setShowGuling] = useState(false);

  // QRIS
  const [showQrisModal, setShowQrisModal] = useState(false);
  const [qrisImage, setQrisImage] = useState(false);
  const [qrisImageUrl, setQrisImageUrl] = useState("");
  const [qrisData, setQrisData] = useState([]);

  const [durations, setDurations] = useState([]);

  // React Toast (Alert)
  const { addToast } = useToasts();

  // Print
  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const toggleConfirmationModal = () => {
    setShowConfirmationModal(!showConfirmationModal)
  }

  const getDirectionAPI = async () => {
    // console.log("Google Map charge => Direction API");
    try {
      // console.log("Payment => rendering GoogleMaps API");
      await axios.get(`https://pelangilaundryapi.herokuapp.com/api/gmap/directions/place_id:${origin}/place_id:${destination}`)
        .then((response) => setDuration(response.data.routes[0].legs[0].duration.value));
      // console.log(duration)
      // .then((response) => setDuration(response.data.routes[0].legs[0].duration.value));
    } catch (e) {
      console.log(e);
    }
  };

  const postData = async (name, phone, from, to, totalPrice, paymentStatus, userTotalTransaction) => {
    try {
      await axios.post(`https://pelangilaundryapi.herokuapp.com/api/users/${name}/${phone}/${from}/${to}/${totalPrice}/${paymentStatus}/${userTotalTransaction}`);
      // console.log("Pushing Data from API");
    } catch (e) {
      console.log(e);
    }
  };


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [isOpenStep1, isOpenStep2])

  useEffect(() => {
    if (didMount.current) phoneValidation(phone);
    else didMount.current = true;
  }, [phone])

  const handleInputChange = (address) => {
    setAddress(address);
    handleSelect(address);
  };

  const handleSelect = (value) => {
    setAddressSelection(true);
    setAddress(value);
    geocodeByAddress(value)
      .then((result) => {
        setOrigin(result[0].place_id)
        // getAllDirection(result[0].place_id)
      })
      .then((results) => getLatLng(results[0]))
      .catch((error) => console.error("Error", error));
  };

  const handleSelectedBranch = (value) => {
    setSelectedOption(value);
    if (value === 1) {
      setDestination(placeid_branch1);
      setDestinationName("Pakel");
    } else if (value === 2) {
      setDestination(placeid_branch2);
      setDestinationName("Nusukan");
    } else if (value === 3) {
      setDestination(placeid_branch3);
      setDestinationName("Gentan");
    } else if (value === 4) {
      setDestinationName("Solo Baru");
    }
  };

  const handleSelectedPayment = (value) => {
    setSelectedPayment(value);
  };

  const handleNextButton = (e) => {
    e.preventDefault();
    if (TotalPrice === 0) {
      alert("Tentukan pilihan jenis laundry Anda terlebih dahulu !");
    } else if (addressSelection === false) {
      alert("Pilih lokasi alamat sesuai dengan google map yang di tampilkan !");
    } else if (selectedOption === 0) {
      alert("Tentukan pilihan cabang yang dituju terlebih dahulu !")
    } else if (selectedOption > 0 && selectedOption < 5 && selectedOption !== 0 && addressSelection === true && TotalPrice !== 0) {
      getDirectionAPI();
      // calculateDistance();
      setIsOpenStep1(false);
    } else alert("Sistem kami sedang dalam gangguan, mohon menghubungi kami melalui live chat !");
  };

  const onClickPaid = (e) => {
    e.preventDefault();
    if (selectedPayment === "Cash") {
      onSubmit();
    } else if (selectedPayment === "QRIS") {
      if (qrisImage === true) {
        setShowQrisModal(true);
      } else {
        generateQris();
      }
    } else {
      setShowConfirmationModal(true);
      // console.log(phoneContainer)
    }
  }

  const onSubmit = () => {
    setIsOpenStep2(false);
    setFinished(true);
    setIsPaid(true);
    setShowConfirmationModal(false);
    setShowQrisModal(false);
    setQrisImage(false);

    // POSTING DATA TO BACKEND / CLOUD STORAGE
    // postData(
    //   firstname,
    //   phone,
    //   address,
    //   destinationName,
    //   deliveryPrice,
    //   "Belum Bayar",
    //   "1010101010"
    // );

    //POSTING DATA TO EMAIL
    // send(
    //   "service_qk2nhbq",
    //   "template_pelangi",
    //   {
    //     fullname: firstname,
    //     address,
    //     origin,
    //     destinationName,
    //     phone,
    //     phoneContainer,
    //     laundryNotes,
    //     deliveryNotes,
    //     kiloan5kg,
    //     kiloan7kg,
    //     bantal,
    //     guling,
    //     kaos,
    //     kemeja,
    //     kebaya,
    //     jas,
    //     jasSetelan,
    //     jasRompi,
    //     gaunAnak,
    //     gaunDewasa,
    //     gaunLongGown,
    //     gaunPengantin,
    //     jaketParasut,
    //     jaketJeans,
    //     jaketKulit,
    //     sajadah,
    //     tasKulit,
    //     tasKainKecil,
    //     tasKainBesar,
    //     topi,
    //     sepatu,
    //     sepatuKulit,
    //     sepatuBoot,
    //     selimutBuluSingle,
    //     selimutBuluKing,
    //     bonekaKecil,
    //     boneka,
    //     bonekaBesar,
    //     bonekaJumbo,
    //     bonekaSuperJumbo,
    //     bedCover,
    //     bedCoverKing,
    //     karpet,
    //     karpetTebal,
    //     korden,
    //     kordenJumbo,
    //     koperCabin,
    //     koperMedium,
    //     koperLarge,
    //     tikar,
    //     TotalPrice: "Rp " + numberWithCommas(TotalPrice) + ",00",
    //     OrderTime: formatDate(Date.now()),
    //     selectedPayment,
    //     noteKiloan5kg,
    //     noteKiloan7kg,
    //     noteBantal,
    //     noteGuling,
    //     noteKaos,
    //     noteKemeja,
    //     noteKebaya,
    //     noteJas,
    //     noteJasSetelan,
    //     noteJasRompi,
    //     noteGaunAnak,
    //     noteGaunDewasa,
    //     noteGaunLongGown,
    //     noteGaunPengantin,
    //     noteJaketParasut,
    //     noteJaketJeans,
    //     noteJaketKulit,
    //     noteSajadah,
    //     noteTasKulit,
    //     noteTasKainKecil,
    //     noteTasKainBesar,
    //     noteTopi,
    //     noteSepatu,
    //     noteSepatuKulit,
    //     noteSepatuBoot,
    //     noteSelimutBuluSingle,
    //     noteSelimutBuluKing,
    //     noteBonekaKecil,
    //     noteBoneka,
    //     noteBonekaBesar,
    //     noteBonekaJumbo,
    //     noteBonekaSuperJumbo,
    //     noteBedCover,
    //     noteBedCoverKing,
    //     noteKarpet,
    //     noteKarpetTebal,
    //     noteKorden,
    //     noteKordenJumbo,
    //     noteKoperCabin,
    //     noteKoperMedium,
    //     noteKoperLarge,
    //     noteTikar,
    //     waLink,
    //   },
    //   "user_dO2KRHE0dFWDNSfHW6cRp"
    // )
    //   // .then((response) => {
    //   //   console.log("SUCCESS!", response.status, response.text);
    //   // })
    //   .catch((err) => {
    //     console.log("FAILED...", err);
    //   });
    
    function Comp() {
      return <span className="alert-notif">Pesanan berhasil dibuat!</span>;
    }
    addToast(Comp(), {
      appearance: "success",
    });
    setTimeout(() => {
      toggleModal();
    }, 3200);
  };

  const phoneValidation = (a) => {
    const Temp = a.split('');
    if (Temp[0] === '0') {
      Temp.splice(0, 1, "6", "2");
      a = Temp.join('');
      setPhoneContainer(a);
    }
    else if (Temp[0] === '+') {
      Temp.shift();
      a = Temp.join('');
      setPhoneContainer(a);
    }
    else if (Temp[0] === '8') {
      Temp.splice(0, 0, "6", "2");
      a = Temp.join('');
      setPhoneContainer(a);
    }
    else if (Temp[0] === '6' && Temp[1] === '2') {
      a = Temp.join('');
      setPhoneContainer(a);
    }
    else {
      return
    }
  }

  function numberWithCommas(value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  function formatDate(x) {
    return new Date(x).toLocaleDateString("id-ID", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  }

  const toggleQrisModal = () => {
    setShowQrisModal(!showQrisModal)
  }

  const getPaymentConfirmationQRIS = async () => {

    try {
      await axios.get(`/v2/${qrisData.order_id}/status`, {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          // Authorization: Basic <Base64Encode("YourServerKey"+":")>
          "Authorization": "Basic U0ItTWlkLXNlcnZlci1rcHJOenNXeTFXNlFnLWl5ZEo4U2VmdTY6",
        },
      })
        .then((response) => {
          setQrisData(response.data);
        });
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if (qrisImage === true) {
      if (qrisData.status_code !== '407') { // transaksi akan kadaluwarsa dalam 15 menit (otomatis dari sistem midtrans)
        if (qrisData.status_code === '200') {
          onSubmit();
        } else {
          const interval = setTimeout(() => {
            getPaymentConfirmationQRIS();
          }, 1000);
          return () => {
            clearTimeout(interval);
          }
        }
      } else {
        console.log('Expired transaction.');
        setQrisImage(false);
        setShowQrisModal(false);
        setQrisImageUrl("");
      }
    }
  }, [qrisData])

  const generateQris = async () => {

    const jsonData = JSON.stringify({
      "payment_type": "qris",
      "transaction_details": {
        "gross_amount": TotalPrice,
        "order_id": "Q-" + Math.floor(Math.random() * Date.now()),
      },
      "customer_details": {
        "first_name": firstname,
        "phone": phone,
      },
      "qris": {
        "acquirer": "gopay"
      }
    });

    try {
      await axios.post("/v2/charge", jsonData, {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          // Authorization: Basic <Base64Encode("YourServerKey"+":")>
          "Authorization": "Basic U0ItTWlkLXNlcnZlci1rcHJOenNXeTFXNlFnLWl5ZEo4U2VmdTY6",
        },
      })
        .then((response) => {
          // console.log('chargeResponse:', response.data);
          setQrisImage(true);
          setShowQrisModal(true);
          setQrisData(response.data);
          setQrisImageUrl(response.data.actions[0].url);
        });
    } catch (e) {
      console.log(e);
    }

  }

  // const getAllDirection = async (val) => {
  //   try {
  //     await Promise.all([
  //       axios.get(`https://pelangilaundryapi.herokuapp.com/api/gmap/directions/place_id:${val}/place_id:${placeid_branch1}`),
  //       axios.get(`https://pelangilaundryapi.herokuapp.com/api/gmap/directions/place_id:${val}/place_id:${placeid_branch2}`),
  //       axios.get(`https://pelangilaundryapi.herokuapp.com/api/gmap/directions/place_id:${val}/place_id:${placeid_branch3}`)
  //     ])
  //       .then(response => {
  //         setDurations([
  //           response[0].data.routes[0].legs[0].duration.value,
  //           response[1].data.routes[0].legs[0].duration.value,
  //           response[2].data.routes[0].legs[0].duration.value
  //         ])
  //       })
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  // const calculateDistance = () => {

  //   setDuration(Math.min(...durations));

  //   if (Math.min(...durations) === durations[0]) {
  //     setSelectedOption(1);
  //     setDestinationName("Pakel");
  //   } else if (Math.min(...durations) === durations[1]) {
  //     setSelectedOption(2);
  //     setDestinationName("Nusukan");
  //   } else if (Math.min(...durations) === durations[2]) {
  //     setSelectedOption(3);
  //     setDestinationName("Gentan");
  //   }

  //   console.log("Pakel : " + durations[0] + ", Nusukan : " + durations[1] + ", Gentan : " + durations[2]);

  // }

  return (
    <div
      className="p-0 p-md-4"
      style={{
        backgroundColor: "#F5F5F5",
        minHeight: "100vh",
      }}
    >
      <Container className={`p-0 ${isOpenStep1 ? "p-md-5" : "p-md-0"}`} style={{ backgroundColor: "white", marginTop: "0.5rem" }}>
        {isOpenStep1 ? (
          <section>
            <Row className="m-0 p-3 p-md-0">
              <Col>
                <h3 style={{ fontSize: "calc(1rem + 1vw)", color: "#EA4335" }}>
                  <u>
                    <b>{ctx.language.step1title}</b>
                  </u>
                </h3>
              </Col>
            </Row>
            <Row className="m-0 my-md-3">
              <Col>
                <Alert color="warning" className="alertText">
                  {ctx.language.step1Warning}
                </Alert>
              </Col>
            </Row>
            <form onSubmit={handleNextButton}>
              <div className="p-4 mb-4 pb-0 bookingContainer">
                <Row className="m-1 m-md-3">
                  <Col className="p-0">
                    <h4>{ctx.language.subTitle1}</h4>
                  </Col>
                </Row>
                <Row className="p-0 p-md-3">
                  <Col className="my-2 my-md-0" xs={12} md={6}>
                    <div className="laundryContainer my-md-2 d-flex flex-column justify-content-center justify-content-md-start">
                      <div className="laundryBody d-flex flex-row justify-content-between">
                        <div className="d-flex flex-row align-items-center">
                          <img className="laundryImg" src='pictures/laundryTypes/laundry.jpg' alt='icon' />
                          <div className="laundryItem d-flex flex-column">
                            <span>Cuci Kering Lipat 5kg</span>
                            <span className="laundryItem_price">Rp {numberWithCommas(kiloan5kg === 0 ? 25000 : priceKiloan5kg * kiloan5kg)},00</span>
                          </div>
                        </div>
                        <span
                          className="d-flex flex-row align-items-center"
                          style={{ fontWeight: "600" }}
                        >
                          <button
                            type="button"
                            className="laundryButton"
                            onClick={
                              kiloan5kg > 1
                                ? () => setKiloan5kg(kiloan5kg - 1)
                                : () => {
                                  if (kiloan5kg !== 0) {
                                    setKiloan5kg(kiloan5kg - 1);
                                  }
                                  setNoteKiloan5kg("");
                                  setShowKiloan5kg(false);
                                }
                            }
                          >
                            -
                          </button>{" "}
                          <span className="amountItem">{kiloan5kg} Item </span>
                          <button
                            type="button"
                            className="laundryButton"
                            onClick={() => {
                              if (kiloan5kg === 0) {
                                setShowKiloan5kg(true);
                              }
                              setKiloan5kg(kiloan5kg + 1);
                            }}
                          >
                            +
                          </button>
                        </span>
                      </div>
                      <Collapse isOpen={showKiloan5kg}>
                        <span className="d-flex align-items-center">
                          <MdEventNote
                            color="#999999"
                            className="me-1 mt-2"
                            size="1.5rem"
                          />
                          <Input
                            className="mt-2 placeholderText"
                            onChange={(e) => setNoteKiloan5kg(e.target.value)}
                            type="text"
                            placeholder="Tambahkan catatan..."
                            value={noteKiloan5kg}
                          />
                        </span>
                      </Collapse>
                    </div>
                  </Col>
                  <Col className="my-2 my-md-0" xs={12} md={6}>
                    <div className="laundryContainer my-md-2 d-flex flex-column justify-content-center justify-content-md-start">
                      <div className="laundryBody d-flex flex-row justify-content-between">
                        <div className="d-flex flex-row align-items-center">
                          <img className="laundryImg" src='pictures/laundryTypes/laundry.jpg' alt='icon' />
                          <div className="laundryItem d-flex flex-column">
                            <span>Cuci Kering Lipat 7kg</span>
                            <span className="laundryItem_price">Rp {numberWithCommas(kiloan7kg === 0 ? 30000 : priceKiloan7kg * kiloan7kg)},00</span>
                          </div>
                        </div>
                        <span
                          className="d-flex flex-row align-items-center"
                          style={{ fontWeight: "600" }}
                        >
                          <button
                            type="button"
                            className="laundryButton"
                            onClick={
                              kiloan7kg > 1
                                ? () => setKiloan7kg(kiloan7kg - 1)
                                : () => {
                                  if (kiloan7kg !== 0) {
                                    setKiloan7kg(kiloan7kg - 1);
                                  }
                                  setNoteKiloan7kg("");
                                  setShowKiloan7kg(false);
                                }
                            }
                          >
                            -
                          </button>{" "}
                          <span className="amountItem">{kiloan7kg} Item </span>
                          <button
                            type="button"
                            className="laundryButton"
                            onClick={() => {
                              if (kiloan7kg === 0) {
                                setShowKiloan7kg(true);
                              }
                              setKiloan7kg(kiloan7kg + 1);
                            }}
                          >
                            +
                          </button>
                        </span>
                      </div>
                      <Collapse isOpen={showKiloan7kg}>
                        <span className="d-flex align-items-center">
                          <MdEventNote
                            color="#999999"
                            className="me-1 mt-2"
                            size="1.5rem"
                          />
                          <Input
                            className="mt-2 placeholderText"
                            onChange={(e) => setNoteKiloan7kg(e.target.value)}
                            type="text"
                            placeholder="Tambahkan catatan..."
                            value={noteKiloan7kg}
                          />
                        </span>
                      </Collapse>
                    </div>
                  </Col>
                  <Col className="my-2 mt-5 mb-md-0" xs={12} md={6}>
                    <div className="laundryContainer my-md-2 d-flex flex-column justify-content-center justify-content-md-start">
                      <div className="laundryBody d-flex flex-row justify-content-between">
                        <div className="d-flex flex-row align-items-center">
                          <img className="laundryImg" src='pictures/laundryTypes/bantal.png' style={{ height: "calc(0.5rem + 2.5vw)" }} alt='icon' />
                          <div className="laundryItem d-flex flex-column">
                            <span>Bantal</span>
                            <span className="laundryItem_price">Rp {numberWithCommas(bantal === 0 ? 30000 : priceBantal * bantal)},00</span>
                          </div>
                        </div>
                        <span
                          className="d-flex flex-row align-items-center"
                          style={{ fontWeight: "600" }}
                        >
                          <button
                            type="button"
                            className="laundryButton"
                            onClick={
                              bantal > 1
                                ? () => setBantal(bantal - 1)
                                : () => {
                                  if (bantal !== 0) {
                                    setBantal(bantal - 1);
                                  }
                                  setNoteBantal("");
                                  setShowBantal(false);
                                }
                            }
                          >
                            -
                          </button>{" "}
                          <span className="amountItem">{bantal} Item </span>
                          <button
                            type="button"
                            className="laundryButton"
                            onClick={() => {
                              if (bantal === 0) {
                                setShowBantal(true);
                              }
                              setBantal(bantal + 1);
                            }}
                          >
                            +
                          </button>
                        </span>
                      </div>
                      <Collapse isOpen={showBantal}>
                        <span className="d-flex align-items-center">
                          <MdEventNote
                            color="#999999"
                            className="me-1 mt-2"
                            size="1.5rem"
                          />
                          <Input
                            className="mt-2 placeholderText"
                            onChange={(e) => setNoteBantal(e.target.value)}
                            type="text"
                            placeholder="Tambahkan catatan..."
                            value={noteBantal}
                          />
                        </span>
                      </Collapse>
                    </div>
                  </Col>
                  <Col className="my-2 mb-md-0 mt-md-5" xs={12} md={6}>
                    <div className="laundryContainer my-md-2 d-flex flex-column justify-content-center justify-content-md-start">
                      <div className="laundryBody d-flex flex-row justify-content-between">
                        <div className="d-flex flex-row align-items-center">
                          <img className="laundryImg" src='pictures/laundryTypes/guling.jpg' alt='icon' />
                          <div className="laundryItem d-flex flex-column">
                            <span>Guling</span>
                            <span className="laundryItem_price">Rp {numberWithCommas(guling === 0 ? 35000 : priceGuling * guling)},00</span>
                          </div>
                        </div>
                        <span
                          className="d-flex flex-row align-items-center"
                          style={{ fontWeight: "600" }}
                        >
                          <button
                            type="button"
                            className="laundryButton"
                            onClick={
                              guling > 1
                                ? () => setGuling(guling - 1)
                                : () => {
                                  if (guling !== 0) {
                                    setGuling(guling - 1);
                                  }
                                  setNoteGuling("");
                                  setShowGuling(false);
                                }
                            }
                          >
                            -
                          </button>{" "}
                          <span className="amountItem">{guling} Item </span>
                          <button
                            type="button"
                            className="laundryButton"
                            onClick={() => {
                              if (guling === 0) {
                                setShowGuling(true);
                              }
                              setGuling(guling + 1);
                            }}
                          >
                            +
                          </button>
                        </span>
                      </div>
                      <Collapse isOpen={showGuling}>
                        <span className="d-flex align-items-center">
                          <MdEventNote
                            color="#999999"
                            className="me-1 mt-2"
                            size="1.5rem"
                          />
                          <Input
                            className="mt-2 placeholderText"
                            onChange={(e) => setNoteGuling(e.target.value)}
                            type="text"
                            placeholder="Tambahkan catatan..."
                            value={noteGuling}
                          />
                        </span>
                      </Collapse>
                    </div>
                  </Col>
                  <ItemPricelist
                    name="Kaos / T-Shirt"
                    image="pictures/laundryTypes/kaos.png"
                    item={kaos}
                    setItem={(x) => setKaos(x)}
                    itemPrice={priceKaos}
                    note={noteKaos}
                    setNote={(x) => setNoteKaos(x)}
                  />
                  <ItemPricelist
                    name="Kemeja"
                    image="pictures/laundryTypes/kemeja.png"
                    item={kemeja}
                    setItem={(x) => setKemeja(x)}
                    itemPrice={priceKemeja}
                    note={noteKemeja}
                    setNote={(x) => setNoteKemeja(x)}
                  />
                  <ItemPricelist
                    name="Kebaya"
                    image="pictures/laundryTypes/kebaya.png"
                    item={kebaya}
                    setItem={(x) => setKebaya(x)}
                    itemPrice={priceKebaya}
                    note={noteKebaya}
                    setNote={(x) => setNoteKebaya(x)}
                  />
                  <ItemPricelist
                    name="Jas"
                    subName="(atasan)"
                    image="pictures/laundryTypes/jas.webp"
                    item={jas}
                    setItem={(x) => setJas(x)}
                    itemPrice={priceJas}
                    note={noteJas}
                    setNote={(x) => setNoteJas(x)}
                  />
                  <ItemPricelist
                    name="Jas"
                    subName="(setelan)"
                    image="pictures/laundryTypes/jas2.jpg"
                    item={jasSetelan}
                    setItem={(x) => setJasSetelan(x)}
                    itemPrice={priceJasSetelan}
                    note={noteJasSetelan}
                    setNote={(x) => setNoteJasSetelan(x)}
                  />
                  <ItemPricelist
                    name="Jas"
                    subName="(rompi)"
                    image="pictures/laundryTypes/jasRompi.png"
                    item={jasRompi}
                    setItem={(x) => setJasRompi(x)}
                    itemPrice={priceJasRompi}
                    note={noteJasRompi}
                    setNote={(x) => setNoteJasRompi(x)}
                  />
                  <ItemPricelist
                    name="Gaun"
                    subName="(Anak)"
                    image="pictures/laundryTypes/gaun_anak.jpg"
                    item={gaunAnak}
                    setItem={(x) => setGaunAnak(x)}
                    itemPrice={priceGaunAnak}
                    note={noteGaunAnak}
                    setNote={(x) => setNoteGaunAnak(x)}
                  />
                  <ItemPricelist
                    name="Gaun"
                    subName="(Pesta Dewasa)"
                    image="pictures/laundryTypes/gaun_dewasa.jpg"
                    item={gaunDewasa}
                    setItem={(x) => setGaunDewasa(x)}
                    itemPrice={priceGaunDewasa}
                    note={noteGaunDewasa}
                    setNote={(x) => setNoteGaunDewasa(x)}
                  />
                  <ItemPricelist
                    name="Gaun"
                    subName="(Long Gown)"
                    image="pictures/laundryTypes/gaun_longgown.jpg"
                    item={gaunLongGown}
                    setItem={(x) => setGaunLongGown(x)}
                    itemPrice={priceGaunLongGown}
                    note={noteGaunLongGown}
                    setNote={(x) => setNoteGaunLongGown(x)}
                  />
                  <ItemPricelist
                    name="Gaun"
                    subName="(Pengantin)"
                    image="pictures/laundryTypes/gaun_wedding.jpg"
                    item={gaunPengantin}
                    setItem={(x) => setGaunPengantin(x)}
                    itemPrice={priceGaunPengantin}
                    note={noteGaunPengantin}
                    setNote={(x) => setNoteGaunPengantin(x)}
                  />
                  <ItemPricelist
                    name="Jaket"
                    subName="(Bomber Parasut)"
                    image="pictures/laundryTypes/jaket_parasut.jpg"
                    item={jaketParasut}
                    setItem={(x) => setJaketParasut(x)}
                    itemPrice={priceJaketParasut}
                    note={noteJaketParasut}
                    setNote={(x) => setNoteJaketParasut(x)}
                  />
                  <ItemPricelist
                    name="Jaket"
                    subName="(Jeans / Tebal)"
                    image="pictures/laundryTypes/jaket_jeans.jpg"
                    item={jaketJeans}
                    setItem={(x) => setJaketJeans(x)}
                    itemPrice={priceJaketJeans}
                    note={noteJaketJeans}
                    setNote={(x) => setNoteJaketJeans(x)}
                  />
                  <ItemPricelist
                    name="Jaket"
                    subName="(Kulit)"
                    image="pictures/laundryTypes/jaket_kulit.jpg"
                    item={jaketKulit}
                    setItem={(x) => setJaketKulit(x)}
                    itemPrice={priceJaketKulit}
                    note={noteJaketKulit}
                    setNote={(x) => setNoteJaketKulit(x)}
                  />
                  <ItemPricelist
                    name="Sajadah"
                    image="pictures/laundryTypes/sajadah.jpg"
                    item={sajadah}
                    setItem={(x) => setSajadah(x)}
                    itemPrice={priceSajadah}
                    note={noteSajadah}
                    setNote={(x) => setNoteSajadah(x)}
                  />
                  <ItemPricelist
                    name="Tas Kulit"
                    image="pictures/laundryTypes/tas.jpg"
                    item={tasKulit}
                    setItem={(x) => setTasKulit(x)}
                    itemPrice={priceTasKulit}
                    note={noteTasKulit}
                    setNote={(x) => setNoteTasKulit(x)}
                  />

                  <ItemPricelist
                    name="Tas Kain"
                    subName="(Kecil < 30cm)"
                    image="pictures/laundryTypes/tas_kain-kecil.jpg"
                    item={tasKainKecil}
                    setItem={(x) => setTasKainKecil(x)}
                    itemPrice={priceTasKainKecil}
                    note={noteTasKainKecil}
                    setNote={(x) => setNoteTasKainKecil(x)}
                  />

                  <ItemPricelist
                    name="Tas Kain"
                    subName="(Besar > 30cm)"
                    image="pictures/laundryTypes/tas_kain-besar.jpg"
                    item={tasKainBesar}
                    setItem={(x) => setTasKainBesar(x)}
                    itemPrice={priceTasKainBesar}
                    note={noteTasKainBesar}
                    setNote={(x) => setNoteTasKainBesar(x)}
                  />
                  <ItemPricelist
                    name="Topi"
                    // subName="(Biasa)"
                    image="pictures/laundryTypes/topi.jpg"
                    item={topi}
                    setItem={(x) => setTopi(x)}
                    itemPrice={priceTopi}
                    note={noteTopi}
                    setNote={(x) => setNoteTopi(x)}
                  />
                  <ItemPricelist
                    name="Sepatu"
                    subName="(Kanvas)"
                    image="pictures/laundryTypes/sepatu_kain.jpg"
                    item={sepatu}
                    setItem={(x) => setSepatu(x)}
                    itemPrice={priceSepatu}
                    note={noteSepatu}
                    setNote={(x) => setNoteSepatu(x)}
                  />
                  <ItemPricelist
                    name="Sepatu"
                    subName="(Kulit)"
                    image="pictures/laundryTypes/sepatu_kulit.jpg"
                    item={sepatuKulit}
                    setItem={(x) => setSepatuKulit(x)}
                    itemPrice={priceSepatuKulit}
                    note={noteSepatuKulit}
                    setNote={(x) => setNoteSepatuKulit(x)}
                  />
                  <ItemPricelist
                    name="Sepatu"
                    subName="(Boot)"
                    image="pictures/laundryTypes/sepatu_boot.jpg"
                    item={sepatuBoot}
                    setItem={(x) => setSepatuBoot(x)}
                    itemPrice={priceSepatuBoot}
                    note={noteSepatuBoot}
                    setNote={(x) => setNoteSepatuBoot(x)}
                  />
                  <ItemPricelist
                    name="Selimut Bulu"
                    subName="(Single)"
                    image="pictures/laundryTypes/selimut.jpg"
                    item={selimutBuluSingle}
                    setItem={(x) => setSelimutBuluSingle(x)}
                    itemPrice={priceSelimutBuluSingle}
                    note={noteSelimutBuluSingle}
                    setNote={(x) => setNoteSelimutBuluSingle(x)}
                  />
                  <ItemPricelist
                    name="Selimut Bulu"
                    subName="(Queen / King)"
                    image="pictures/laundryTypes/selimut.jpg"
                    item={selimutBuluKing}
                    setItem={(x) => setSelimutBuluKing(x)}
                    itemPrice={priceSelimutBuluKing}
                    note={noteSelimutBuluKing}
                    setNote={(x) => setNoteSelimutBuluKing(x)}
                  />
                  <ItemPricelist
                    name="Boneka"
                    subName="(Kecil <15cm)"
                    image="pictures/laundryTypes/boneka.jpg"
                    item={bonekaKecil}
                    setItem={(x) => setBonekaKecil(x)}
                    itemPrice={priceBonekaKecil}
                    note={noteBonekaKecil}
                    setNote={(x) => setNoteBonekaKecil(x)}
                  />
                  <ItemPricelist
                    name="Boneka"
                    subName="(Sedang <30cm)"
                    image="pictures/laundryTypes/boneka.jpg"
                    item={boneka}
                    setItem={(x) => setBoneka(x)}
                    itemPrice={priceBoneka}
                    note={noteBoneka}
                    setNote={(x) => setNoteBoneka(x)}
                  />
                  <ItemPricelist
                    name="Boneka"
                    subName="(Besar <50cm)"
                    image="pictures/laundryTypes/boneka.jpg"
                    item={bonekaBesar}
                    setItem={(x) => setBonekaBesar(x)}
                    itemPrice={priceBonekaBesar}
                    note={noteBonekaBesar}
                    setNote={(x) => setNoteBonekaBesar(x)}
                  />
                  <ItemPricelist
                    name="Boneka"
                    subName="(Jumbo <100cm)"
                    image="pictures/laundryTypes/boneka.jpg"
                    item={bonekaJumbo}
                    setItem={(x) => setBonekaJumbo(x)}
                    itemPrice={priceBonekaJumbo}
                    note={noteBonekaJumbo}
                    setNote={(x) => setNoteBonekaJumbo(x)}
                  />
                  <ItemPricelist
                    name="Boneka"
                    subName="(Super Jumbo >100cm)"
                    image="pictures/laundryTypes/boneka.jpg"
                    item={bonekaSuperJumbo}
                    setItem={(x) => setBonekaSuperJumbo(x)}
                    itemPrice={priceBonekaSuperJumbo}
                    note={noteBonekaSuperJumbo}
                    setNote={(x) => setNoteBonekaSuperJumbo(x)}
                  />
                  <ItemPricelist
                    name="Bed Cover"
                    subName="(Single)"
                    image="pictures/laundryTypes/bedcover.jpg"
                    item={bedCover}
                    setItem={(x) => setBedCover(x)}
                    itemPrice={priceBedCover}
                    note={noteBedCover}
                    setNote={(x) => setNoteBedCover(x)}
                  />
                  <ItemPricelist
                    name="Bed Cover"
                    subName="(Queen / King)"
                    image="pictures/laundryTypes/bedcover.jpg"
                    item={bedCoverKing}
                    setItem={(x) => setBedCoverKing(x)}
                    itemPrice={priceBedCoverKing}
                    note={noteBedCoverKing}
                    setNote={(x) => setNoteBedCoverKing(x)}
                  />
                  <ItemPricelist
                    name="Karpet"
                    subName="(Tipis / m2)"
                    image="pictures/laundryTypes/karpet.jpg"
                    item={karpet}
                    setItem={(x) => setKarpet(x)}
                    itemPrice={priceKarpet}
                    note={noteKarpet}
                    setNote={(x) => setNoteKarpet(x)}
                  />
                  <ItemPricelist
                    name="Karpet"
                    subName="(Tebal / m2)"
                    image="pictures/laundryTypes/karpet.jpg"
                    item={karpetTebal}
                    setItem={(x) => setKarpetTebal(x)}
                    itemPrice={priceKarpetTebal}
                    note={noteKarpetTebal}
                    setNote={(x) => setNoteKarpetTebal(x)}
                  />
                  <ItemPricelist
                    name="Korden"
                    subName="(Besar)"
                    image="pictures/laundryTypes/korden.jpg"
                    item={korden}
                    setItem={(x) => setKorden(x)}
                    itemPrice={priceKorden}
                    note={noteKorden}
                    setNote={(x) => setNoteKorden(x)}
                  />
                  <ItemPricelist
                    name="Korden"
                    subName="(Jumbo)"
                    image="pictures/laundryTypes/korden.jpg"
                    item={kordenJumbo}
                    setItem={(x) => setKordenJumbo(x)}
                    itemPrice={priceKordenJumbo}
                    note={noteKordenJumbo}
                    setNote={(x) => setNoteKordenJumbo(x)}
                  />
                  <ItemPricelist
                    name="Koper"
                    subName="(Cabin < 48cm)"
                    image="pictures/laundryTypes/koper.jpg"
                    item={koperCabin}
                    setItem={(x) => setKoperCabin(x)}
                    itemPrice={priceKoperCabin}
                    note={noteKoperCabin}
                    setNote={(x) => setNoteKoperCabin(x)}
                  />
                  <ItemPricelist
                    name="Koper"
                    subName="(Medium <67cm)"
                    image="pictures/laundryTypes/koper.jpg"
                    item={koperMedium}
                    setItem={(x) => setKoperMedium(x)}
                    itemPrice={priceKoperMedium}
                    note={noteKoperMedium}
                    setNote={(x) => setNoteKoperMedium(x)}
                  />
                  <ItemPricelist
                    name="Koper"
                    subName="(Large <78cm)"
                    image="pictures/laundryTypes/koper.jpg"
                    item={koperLarge}
                    setItem={(x) => setKoperLarge(x)}
                    itemPrice={priceKoperLarge}
                    note={noteKoperLarge}
                    setNote={(x) => setNoteKoperLarge(x)}
                  />
                  <ItemPricelist
                    name="Tikar"
                    // subName="(Biasa)"
                    image="pictures/laundryTypes/tikar.jpg"
                    item={tikar}
                    setItem={(x) => setTikar(x)}
                    itemPrice={priceTikar}
                    note={noteTikar}
                    setNote={(x) => setNoteTikar(x)}
                  />
                </Row>
                <Row className="my-5 py-3 px-1 px-md-2 gmaps">
                  <Col xs={12}>
                    <PlacesAutocomplete
                      value={address}
                      onChange={handleInputChange}
                      onSelect={handleSelect}
                      debounce={500}
                      searchOptions={{
                        componentRestrictions: {
                          country: ['id'],
                        },
                        location: new window.google.maps.LatLng(-7.55921, 110.81903), // Surakarta
                        radius: 500,
                      }}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          {/* <h4>Tentukan Lokasi Pengambilan</h4> */}
                          <h4>{ctx.language.subTitle2}</h4>
                          <Input
                            required
                            className="placeholderText"
                            type="address"
                            name="address"
                            // placeholder="Cari lokasi penjemputan sesuai peta. Contoh : Perumahan Fajar Indah Permata 2 Surakarta / Fave Hotel Manahan"
                            placeholder="Contoh : Perumahan Fajar Indah Permata 2 Surakarta / Fave Hotel Manahan"
                            style={{ width: "100%", margin: "1rem 0" }}
                            {...getInputProps({
                              className: "location-search-input placeholderText",
                            })}
                            autoComplete="on"
                          />
                          <div className="autocomplete-dropdown-container">
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => {
                              const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                              // inline style htmlFor demonstration purpose
                              const style = suggestion.active
                                ? {
                                  backgroundColor: "#fafafa",
                                  cursor: "pointer",
                                }
                                : {
                                  backgroundColor: "#ffffff",
                                  cursor: "pointer",
                                };
                              return (
                                <div
                                  key={suggestion.placeId}
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </Col>
                  {/* GOOGLE MAP */}
                  <Col xs={12} className="mt-2">
                    <iframe
                      title="googlemap"
                      style={{
                        width: "100%",
                        height: "40vh",
                        border: "0",
                        borderRadius: "10px",
                      }}
                      loading="lazy"
                      allowFullScreen
                      src={`https://www.google.com/maps/embed/v1/place?q=place_id:${origin}&key=${process.env.REACT_APP_GMAP_API_KEY}`}
                    ></iframe>
                  </Col >

                  <Col className="my-3" sm={12}>
                    <FormGroup>
                      <Label htmlFor="deliverynotes">
                        <b>Catatan Detail Lokasi (opsional):</b>
                      </Label>
                      <Input
                        className="mt-2 placeholderText"
                        type="textarea"
                        name="text"
                        id="deliverynotes"
                        placeholder="Tambahkan detail lokasi penjemputan. Contoh: Blok A2 Nomor 5 / Rumah No 2 sebelah warung makan Nini Thowong"
                        onChange={(e) => setDeliveryNotes(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row >
                <Row className="m-1 m-md-3">
                  {/* <Col>
                    <h4>{ctx.language.subTitle3}</h4>
                  </Col> */}
                </Row>
                <Row className="mt-4">
                  <Col
                    className="d-flex justify-content-center align-items-center my-2 my-sm-0"
                    xs={6}
                    lg={4}
                  >
                    <Branch
                      title="Pelangi Pakel"
                      image="/pictures/pelangi_pakel.jpg"
                      review={
                        <span>
                          Telp. 0895326418881
                          <br />
                          <br />
                          Jl. Pakel 11 C Ruko no.2 Kerten
                          <br />
                          Laweyan, Solo
                        </span>
                      }
                      onClick={
                        selectedOption !== 1
                          ? () => handleSelectedBranch(1)
                          : () => handleSelectedBranch(0)
                      }
                      checked={selectedOption === 1 ? true : false}
                      area="Daerah Manahan"
                    />
                  </Col>
                  <Col
                    className="d-flex justify-content-center align-items-center my-2 my-sm-0"
                    xs={6}
                    lg={4}
                  >
                    <Branch
                      title="Pelangi Nusukan"
                      image="/pictures/pelangi_nusukan.jpg"
                      review={
                        <>
                          Telp. 081542163595
                          <br />
                          <br />
                          Jl. Kapt. Pierre Tendean 87A
                          <br />
                          Nusukan, Solo
                        </>
                      }
                      onClick={
                        selectedOption !== 2
                          ? () => handleSelectedBranch(2)
                          : () => handleSelectedBranch(0)
                      }
                      checked={selectedOption === 2 ? true : false}
                      area="Daerah Nusukan"
                    />
                  </Col>
                  <Col
                    className="d-flex justify-content-center align-items-center my-2 my-sm-0"
                    xs={6}
                    lg={4}
                  >
                    <Branch
                      title="Pelangi Gentan"
                      image="/pictures/pelangi_gentan.jpg"
                      review={
                        <>
                          Telp. 089514781888
                          <br />
                          <br />
                          Jl. Raya Songgolangit 30B
                          <br />
                          Gentan, Sukoharjo
                        </>
                      }
                      onClick={
                        selectedOption !== 3
                          ? () => handleSelectedBranch(3)
                          : () => handleSelectedBranch(0)
                      }
                      checked={selectedOption === 3 ? true : false}
                      area="Daerah Gentan"
                    />
                  </Col>

                  {/* <Col
                    className="d-flex justify-content-center align-items-center my-2 my-sm-0"
                    xs={6}
                    lg={4}
                  >
                    <Branch
                      title="Pelangi Solo Baru"
                      image="/pictures/5edit.png"
                      review={
                        <>
                          Jl. Raya Djlopo GP37, Langenharjo
                          <br /> Sukoharjo
                        </>
                      }
                      onClick={
                        selectedOption !== 4
                          ? () => handleSelectedBranch(4)
                          : () => handleSelectedBranch(0)
                      }
                      checked={selectedOption === 4 ? true : false}
                      area="Daerah Solo Baru"
                    />
                  </Col> */}
                </Row>
              </div >
              {/* <Button color='primary' type='submit'>Book Now</Button> */}
              < Row
                className="py-4 mx-2 d-flex align-items-center justify-content-between"
                style={{
                  borderTop: "1px solid #F5F5F5",
                }}
              >
                <Col sm={12} md={6}>
                  <p
                    style={{
                      paddingLeft: "2vw",
                      fontSize: "calc(0.5vw + 5px)",
                    }}
                  >
                    {ctx.language.step2info1}
                    <br></br>
                    {ctx.language.step2info2}
                  </p>
                </Col>
                <Col sm={12} md={6}>
                  {/* <Button onClick={() => getDirectionAPI()}>Check Delivery Price : {deliveryPrice}</Button> */}
                  <Button
                    color="danger"
                    size="lg"
                    className="py-2 py-md-3"
                    style={{
                      fontWeight: "600",
                      width: "100%",
                    }}
                    type="submit"
                  >
                    Next
                  </Button>
                </Col>
              </Row >
            </form >
          </section >
        ) : (
          <Row className="m-0 p-3 p-md-4"
            style={{
              padding: "calc(1vw + 1rem) 0px",
              borderBottom: "1px solid #F5F5F5",
            }}>
            <Col>
              <h3 style={{ fontSize: "calc(1rem + 1vw)", color: "#28A745" }}>
                <u><b>{ctx.language.step1title}</b></u>
              </h3>
            </Col>
          </Row>
        )}
      </Container >
      <Container
        style={{ backgroundColor: "white", padding: "2rem", marginTop: "0.5rem" }}
      >
        {isOpenStep2 && !isOpenStep1 ? (
          <>
            <Row>
              <Col>
                <h3 style={{ fontSize: "calc(1rem + 1vw)", color: "#FF5722" }}>
                  <u><b>{ctx.language.step2title}</b></u>
                </h3>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <Alert color="warning" className="alertText">
                  {ctx.language.step2Warning}
                </Alert>
              </Col>
            </Row>
            <Form onSubmit={onClickPaid}>
              <Row className="d-flex justify-content-between">
                {/* Customer Form */}
                <Col xs={12} md={6}>
                  <Row>
                    <Col className="my-2 my-md-3" sm={12}>
                      <FormGroup>
                        <Label htmlFor="from_name" className="labelText">
                          {ctx.language.step2Fullname} <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input
                          required
                          type="text"
                          name="from_name"
                          id="firstName"
                          className="placeholderText"
                          placeholder={ctx.language.step2FullnamePlaceholder}
                          onChange={(e) => setFirstname(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="my-2 my-md-3" sm={12}>
                      <Label htmlFor="exampleEmail" className="labelText">
                        {ctx.language.step2Whatsapp} <span style={{ color: "red" }}>*</span>{" "}
                        {ctx.language.step2Whatsapp2}
                      </Label>
                      <Input
                        required
                        type="number"
                        name="phone"
                        id="phoneNumber"
                        className="placeholderText"
                        placeholder={ctx.language.step2WhatsappPlaceholder}
                        autoComplete="on"
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </Col>
                    {/* <Col className="my-2 my-md-3" sm={12}>
                      <Label htmlFor="email" className="labelText">Email (opsional)</Label>
                      <Input
                        type="email"
                        name="email"
                        id="email"
                        className="placeholderText"
                        placeholder={ctx.language.step2EmailPlaceholder}
                      />
                    </Col> */}
                  </Row>
                  <Row>
                    <Col className="my-2 my-md-3" sm={12}>
                      <FormGroup>
                        <Label htmlFor="laundrynotes" className="labelText">
                          {ctx.language.Step2Notes}
                        </Label>
                        <Input
                          className="placeholderText"
                          type="textarea"
                          name="text"
                          id="laundrynotes"
                          onChange={(e) => setLaundryNotes(e.target.value)}
                          style={{ minHeight: "7.5rem" }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>

                {/* Payment Method */}
                <Col className="my-3" xs={12} md={6}>
                  <Row className="mt-md-4 justify-content-center">
                    {/* <Col
                      xs={4}
                      lg={4}
                      className="d-flex justify-content-center"
                    >
                      <Branch
                        payment
                        border="#6f42c1"
                        containImg
                        onClick={() => handleSelectedPayment("QRIS")}
                        checked02={selectedPayment === "QRIS" ? true : false}
                        image="/pictures/qris_logo.png"
                      />
                    </Col> */}
                    <Col
                      xs={6}
                      lg={6}
                      className="d-flex justify-content-center"
                    >
                      <Branch
                        payment
                        border="#3278bd"
                        containImg
                        onClick={() => handleSelectedPayment("BCA")}
                        checked02={selectedPayment === "BCA" ? true : false}
                        image="/pictures/bca-logo.png"
                      />
                    </Col>
                    {/* <Col
                      xs={6}
                      lg={6}
                      className="d-flex justify-content-center"
                    >
                      <Branch
                        containImg
                        onClick={() => handleSelectedPayment("ShopeePay")}
                        checked02={
                          selectedPayment === "ShopeePay" ? true : false
                        }
                        image="/pictures/shopeePay-logo.png"
                      />
                    </Col> */}
                    <Col
                      xs={6}
                      lg={6}
                      className="d-flex justify-content-center"
                    >
                      <Branch
                        payment
                        border="rgb(3, 197, 153)"
                        containImg
                        onClick={() => handleSelectedPayment("Cash")}
                        checked02={selectedPayment === "Cash" ? true : false}
                        image="/pictures/cash.png"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      className="paidProcess px-3 py-5 d-flex mx-auto rounded-3 flex-column justify-content-center align-items-center"
                      xs={11}
                      style={{
                        backgroundColor:
                          selectedPayment === "QRIS"
                            ? "#6f42c1" :
                            selectedPayment === "ShopeePay"
                              ? "#ff4d00"
                              : selectedPayment === "Cash"
                                ? "#03c599"
                                : "",
                      }}
                    >
                      <span className="paidProcess__info text-center text-wrap text-md-nowrap">
                        {selectedPayment === "QRIS" &&
                          "Anda akan melakukan pembayaran melalui scan QRIS. QR Code pembayaran akan tampil setelah anda menyelesaikan proses pemesanan :"}
                        {selectedPayment === "BCA" &&
                          "Silahkan lakukan pembayaran anda ke rekening BCA :"}
                        {selectedPayment === "ShopeePay" &&
                          "Silahkan scan barcode dibawah dengan Shopee Pay :"}
                        {selectedPayment === "Cash" &&
                          "Anda akan melakukan pembayaran di tempat / Cash on Delivery :"}

                        <br />
                        {selectedPayment !== "Cash" &&
                          <>
                            {selectedOption === 1 && "Pelangi Laundy Pakel"}
                            {selectedOption === 2 && "Pelangi Laundy Nusukan"}
                            {selectedOption === 3 && "Pelangi Laundy Gentan"}
                            {selectedOption === 4 && "Pelangi Laundy Solo Baru"}
                          </>
                        }
                        <br />
                        <strong>
                          {selectedPayment === "BCA" && (
                            <>
                              {selectedOption === 1 &&
                                "015 413 5011 a.n Devi Rianasari"}
                              {selectedOption === 2 &&
                                "015 130 7611 a.n Yenny Sri Murwani"}
                              {selectedOption === 3 &&
                                "015 426 5879 a.n Devi Rianasari"}
                              {selectedOption === 4 &&
                                "Maaf untuk cabang Pelangi Laundry Solo Baru belum aktif"}
                            </>
                          )}
                          {selectedPayment === "ShopeePay" && <></>}
                          {selectedPayment === "Cash" && <>Mohon lakukan pembayaran<br />melalui kurir kami di saat penjemputan</>}
                        </strong>
                      </span>
                      {selectedPayment === "ShopeePay" ? (
                        <>
                          <span className="paidProcess__img mt-2 mt-md-4">
                            <img
                              src="/pictures/barcode.png"
                              alt="Shopee Pay Barcode"
                              width="100%"
                              height="100%"
                            />
                          </span>
                          <span className="paidProcess__total mt-2 mb-3 mt-md-2 text-center text-nowrap">
                            Rp.{numberWithCommas(TotalPrice)},00
                          </span>
                        </>
                      ) : (
                        <span className="paidProcess__total mt-2 mb-3 my-md-5 text-center text-nowrap">
                          Rp.{numberWithCommas(TotalPrice)},00
                        </span>
                      )}

                      <Button
                        color="#3278bd"
                        className="paidProcess__button py-1 py-md-2"
                        style={{
                          fontWeight: "600",
                          fontSize: "calc(6px + 0.8vw)",
                        }}
                        type="button"
                        onClick={() => setShowModal(true)}
                      >
                        Lihat Rincian Pemesanan
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row
                className="my-5 d-flex align-items-center justify-content-between"
                style={{
                  borderTop: "1px solid #F5F5F5",
                  paddingBottom: "2rem",
                }}
              >
                <Col sm={12} md={6} className="pt-4">
                  <p style={{ fontSize: "calc(0.5vw + 5px)" }}>
                    {ctx.language.step1info1}
                    <br></br>
                    {ctx.language.step1info2}
                  </p>
                </Col>
                <Col sm={12} md={6} className="pt-1 pt-md-4">
                  {/* <Button onClick={() => PhoneValidation()}>Check</Button> */}
                  <Button
                    color="danger"
                    size="lg"
                    className="py-2 py-md-3"
                    style={{
                      fontWeight: "600",
                      width: "100%",
                    }}
                    type="submit"
                  >
                    {selectedPayment === "QRIS" ?
                      qrisImage === true ?
                        "Cek QR Code" :
                        "Lakukan Pembayaran" : "Pembayaran Telah Selesai"}
                  </Button>
                </Col>
              </Row>
            </Form>
          </>
        ) : (
          <Row>
            <Col>
              <h3 style={{ fontSize: "calc(1rem + 1vw)", color: "#28A745" }}>
                <u><b>{ctx.language.step2title}</b></u>
              </h3>
            </Col>
          </Row>
        )}
      </Container>

      {/* Modal Konfirmasi Pembayaran */}
      <Modal centered isOpen={showConfirmationModal} toggle={toggleConfirmationModal}>
        <ModalHeader cssModule={{ 'modal-title': 'w-100 text-center' }}><span style={{ fontSize: "calc(0.2vw + 12px)" }}>Apakah Anda sudah melakukan pembayaran?</span><br /><span style={{ fontSize: 'calc(0.2vw + 10px)', color: 'red' }}>*Jika belum, mohon selesaikan pembayaran terlebih dahulu.*</span></ModalHeader>
        <ModalFooter className="m-auto">
          <Button color="danger" onClick={onSubmit}>Ya, Sudah Bayar</Button>{' '}
          <Button color="secondary" onClick={toggleConfirmationModal}>Belum Bayar</Button>
        </ModalFooter>
      </Modal>

      {/* Modal QRIS */}
      <Modal centered isOpen={showQrisModal} toggle={toggleQrisModal}>
        <ModalHeader toggle={toggleQrisModal} cssModule={{ 'modal-title': 'w-100 text-center', 'close': 'btn-close' }} charCode="">
          <span style={{ fontSize: "calc(0.2vw + 12px)", marginLeft: "calc(0.2vw + 4%)" }}>Online Payment</span>
        </ModalHeader>
        <ModalBody className="p-4 p-md-4 d-flex flex-column">
          <div className="mb-4">
            <img src="/pictures/qris-logo.png" alt="QRIS Logo" style={{ width: "30%", position: "absolute", top: "5px", left: "10px" }} />
            <img src="/pictures/gpn-logo.png" alt="GPN Logo" style={{ width: "6%", position: "absolute", top: "5px", right: "20px" }} />
          </div>
          <h5 className="text-uppercase text-center" style={{ fontSize: "calc(0.2vw + 16px)" }}>Pelangi Laundry</h5>
          <h5 className="text-center" style={{ fontSize: "calc(0.2vw + 18px)", fontWeight: "700" }}>Rp. {numberWithCommas(TotalPrice)}</h5>
          <div className="text-center">
            <img src={qrisImageUrl} alt="QRIS CODE" style={{ width: "60%", margin: "auto" }} />
          </div>
          <div className="text-center">
            <img src="/pictures/dana-logo.png" alt="Dana Logo" style={{ width: "20%", margin: "0 3px" }} />
            <img src="/pictures/gopay-logo.png" alt="Gopay Logo" style={{ width: "25%", margin: "0 3px" }} />
            <img src="/pictures/shopee-pay-logo.png" alt="ShopeePay Logo" style={{ width: "17%", margin: "0 3px" }} />
          </div>
          <div className="text-center">
            <img src="/pictures/link-aja-logo.png" alt="LinkAja Logo" style={{ width: "6%", margin: "0 3px" }} />
            <img src="/pictures/ovo-logo.png" alt="Ovo Logo" style={{ width: "10%", margin: "0 3px" }} />
            <img src="/pictures/bni-logo.png" alt="BNI Logo" style={{ width: "13%", margin: "0 3px" }} />
            <img src="/pictures/bca-logo.png" alt="BCA Logo" style={{ width: "15%", margin: "0 3px" }} />
            <img src="/pictures/bri-logo.png" alt="BRI Logo" style={{ width: "6%", margin: "0 3px" }} />
          </div>
        </ModalBody>
      </Modal>

      {/* Modal Receipt */}
      <Modal
        size="md"
        ref={printRef}
        scrollable
        isOpen={showModal}
        toggle={toggleModal}
        centered
        className="modal"
      >
        <ModalBody className="p-4 p-md-4 d-flex flex-column">
          <Row>
            <Col xs={3} className="text-muted modal__header">
              Order: {Math.floor(Math.random() * 100000)}
            </Col>
            <Col
              xs={6}
              className="d-flex modal__lottie flex-column text-center mx-auto mb-2 justify-content-center align-items-center"
            >
              {finished && (
                <>
                  <CustomSimpleLottiePlayer
                    animationData={animation}
                    width="calc(10vw + 5.8rem)"
                    height="calc(7vw + 3.8rem)"
                  />
                  <span
                    className="my-1 mt-sm-0 text-nowrap"
                    style={{
                      fontSize: "calc(15px + 0.5vw)",
                      fontWeight: "bold",
                    }}
                  >
                    Your order is on its way!
                  </span>
                </>
              )}
            </Col>
            <Col xs={3} className="text-muted text-end modal__header">
              {formatDate(Date.now())}
            </Col>
          </Row>

          <span
            className="mt-4"
            style={{ fontSize: "calc(8px + 0.4vw)", fontWeight: "700" }}
          >
            PAYMENT SUMMARY
          </span>
          <table className="mt-2">
            <thead className="text-muted">
              <tr>
                <th>ITEMS</th>
                <th className="text-center">QUANTITY</th>
                <th className="text-end">PRICE</th>
              </tr>
            </thead>
            <tbody>
              <tr className="whitespace"></tr>
              {kiloan5kg > 0 && (
                <tr>
                  <td>Cuci Kering Lipat 5kg</td>
                  <td align="center">{kiloan5kg}</td>
                  <td align="right">
                    Rp. {numberWithCommas(kiloan5kg * priceKiloan5kg)},00
                  </td>
                </tr>
              )}
              {kiloan7kg > 0 && (
                <tr>
                  <td>Cuci Kering Lipat 7kg</td>
                  <td align="center">{kiloan7kg}</td>
                  <td align="right">
                    Rp. {numberWithCommas(kiloan7kg * priceKiloan7kg)},00
                  </td>
                </tr>
              )}
              {bantal > 0 && (
                <tr>
                  <td>Bantal</td>
                  <td align="center">{bantal}</td>
                  <td align="right">
                    Rp. {numberWithCommas(bantal * priceBantal)},00
                  </td>
                </tr>
              )}
              {guling > 0 && (
                <tr>
                  <td>Guling</td>
                  <td align="center">{guling}</td>
                  <td align="right">
                    Rp. {numberWithCommas(guling * priceGuling)},00
                  </td>
                </tr>
              )}
              {kaos > 0 && (
                <tr>
                  <td>Kaos</td>
                  <td align="center">{kaos}</td>
                  <td align="right">
                    Rp. {numberWithCommas(kaos * priceKaos)},00
                  </td>
                </tr>
              )}
              {kemeja > 0 && (
                <tr>
                  <td>Kemeja</td>
                  <td align="center">{kemeja}</td>
                  <td align="right">
                    Rp. {numberWithCommas(kemeja * priceKemeja)},00
                  </td>
                </tr>
              )}
              {kebaya > 0 && (
                <tr>
                  <td>Kebaya</td>
                  <td align="center">{kebaya}</td>
                  <td align="right">
                    Rp. {numberWithCommas(kebaya * priceKebaya)},00
                  </td>
                </tr>
              )}
              {jas > 0 && (
                <tr>
                  <td>Jas</td>
                  <td align="center">{jas}</td>
                  <td align="right">
                    Rp. {numberWithCommas(jas * priceJas)},00
                  </td>
                </tr>
              )}
              {jasSetelan > 0 && (
                <tr>
                  <td>Jas Setelan</td>
                  <td align="center">{jasSetelan}</td>
                  <td align="right">
                    Rp. {numberWithCommas(jasSetelan * priceJasSetelan)},00
                  </td>
                </tr>
              )}
              {jasRompi > 0 && (
                <tr>
                  <td>Jas Rompi</td>
                  <td align="center">{jasRompi}</td>
                  <td align="right">
                    Rp. {numberWithCommas(jasRompi * priceJasRompi)},00
                  </td>
                </tr>
              )}
              {gaunAnak > 0 && (
                <tr>
                  <td>Gaun Anak</td>
                  <td align="center">{gaunAnak}</td>
                  <td align="right">
                    Rp. {numberWithCommas(gaunAnak * priceGaunAnak)},00
                  </td>
                </tr>
              )}
              {gaunDewasa > 0 && (
                <tr>
                  <td>Gaun Dewasa</td>
                  <td align="center">{gaunDewasa}</td>
                  <td align="right">
                    Rp. {numberWithCommas(gaunDewasa * priceGaunDewasa)},00
                  </td>
                </tr>
              )}{gaunLongGown > 0 && (
                <tr>
                  <td>Gaun Long Gown</td>
                  <td align="center">{gaunLongGown}</td>
                  <td align="right">
                    Rp. {numberWithCommas(gaunLongGown * priceGaunLongGown)},00
                  </td>
                </tr>
              )}{gaunPengantin > 0 && (
                <tr>
                  <td>Gaun Pengantin</td>
                  <td align="center">{gaunPengantin}</td>
                  <td align="right">
                    Rp. {numberWithCommas(gaunPengantin * priceGaunPengantin)},00
                  </td>
                </tr>
              )}{jaketParasut > 0 && (
                <tr>
                  <td>Jaket Parasut</td>
                  <td align="center">{jaketParasut}</td>
                  <td align="right">
                    Rp. {numberWithCommas(jaketParasut * priceJaketParasut)},00
                  </td>
                </tr>
              )}{jaketJeans > 0 && (
                <tr>
                  <td>Jaket Jeans</td>
                  <td align="center">{jaketJeans}</td>
                  <td align="right">
                    Rp. {numberWithCommas(jaketJeans * priceJaketJeans)},00
                  </td>
                </tr>
              )}
              {jaketKulit > 0 && (
                <tr>
                  <td>Jaket Kulit</td>
                  <td align="center">{jaketKulit}</td>
                  <td align="right">
                    Rp. {numberWithCommas(jaketKulit * priceJaketKulit)},00
                  </td>
                </tr>
              )}
              {sajadah > 0 && (
                <tr>
                  <td>Sajadah</td>
                  <td align="center">{sajadah}</td>
                  <td align="right">
                    Rp. {numberWithCommas(sajadah * priceSajadah)},00
                  </td>
                </tr>
              )}{tasKulit > 0 && (
                <tr>
                  <td>Tas Kulit</td>
                  <td align="center">{tasKulit}</td>
                  <td align="right">
                    Rp. {numberWithCommas(tasKulit * priceTasKulit)},00
                  </td>
                </tr>
              )}{tasKainKecil > 0 && (
                <tr>
                  <td>Tas Kain Kecil</td>
                  <td align="center">{tasKainKecil}</td>
                  <td align="right">
                    Rp. {numberWithCommas(tasKainKecil * priceTasKainKecil)},00
                  </td>
                </tr>
              )}{tasKainBesar > 0 && (
                <tr>
                  <td>Tas Kain Besar</td>
                  <td align="center">{tasKainBesar}</td>
                  <td align="right">
                    Rp. {numberWithCommas(tasKainBesar * priceTasKainBesar)},00
                  </td>
                </tr>
              )}{topi > 0 && (
                <tr>
                  <td>Topi</td>
                  <td align="center">{topi}</td>
                  <td align="right">
                    Rp. {numberWithCommas(topi * priceTopi)},00
                  </td>
                </tr>
              )}{sepatu > 0 && (
                <tr>
                  <td>Sepatu</td>
                  <td align="center">{sepatu}</td>
                  <td align="right">
                    Rp. {numberWithCommas(sepatu * priceSepatu)},00
                  </td>
                </tr>
              )}
              {sepatuKulit > 0 && (
                <tr>
                  <td>Sepatu Kulit</td>
                  <td align="center">{sepatuKulit}</td>
                  <td align="right">
                    Rp. {numberWithCommas(sepatuKulit * priceSepatuKulit)},00
                  </td>
                </tr>
              )}
              {sepatuBoot > 0 && (
                <tr>
                  <td>Sepatu Boot</td>
                  <td align="center">{sepatuBoot}</td>
                  <td align="right">
                    Rp. {numberWithCommas(sepatuBoot * priceSepatuBoot)},00
                  </td>
                </tr>
              )}
              {selimutBuluSingle > 0 && (
                <tr>
                  <td>Selimut Bulu</td>
                  <td align="center">{selimutBuluSingle}</td>
                  <td align="right">
                    Rp. {numberWithCommas(selimutBuluSingle * priceSelimutBuluSingle)},00
                  </td>
                </tr>
              )}{selimutBuluKing > 0 && (
                <tr>
                  <td>Selimut Bulu</td>
                  <td align="center">{selimutBuluKing}</td>
                  <td align="right">
                    Rp. {numberWithCommas(selimutBuluKing * priceSelimutBuluKing)},00
                  </td>
                </tr>
              )}
              {bonekaKecil > 0 && (
                <tr>
                  <td>Boneka Kecil</td>
                  <td align="center">{bonekaKecil}</td>
                  <td align="right">
                    Rp. {numberWithCommas(bonekaKecil * priceBonekaKecil)},00
                  </td>
                </tr>
              )}
              {boneka > 0 && (
                <tr>
                  <td>Boneka Sedang</td>
                  <td align="center">{boneka}</td>
                  <td align="right">
                    Rp. {numberWithCommas(boneka * priceBoneka)},00
                  </td>
                </tr>
              )}
              {bonekaBesar > 0 && (
                <tr>
                  <td>Boneka Besar</td>
                  <td align="center">{bonekaBesar}</td>
                  <td align="right">
                    Rp. {numberWithCommas(bonekaBesar * priceBonekaBesar)},00
                  </td>
                </tr>
              )}
              {bonekaJumbo > 0 && (
                <tr>
                  <td>Boneka Jumbo</td>
                  <td align="center">{bonekaJumbo}</td>
                  <td align="right">
                    Rp. {numberWithCommas(bonekaJumbo * priceBonekaJumbo)},00
                  </td>
                </tr>
              )}
              {bonekaSuperJumbo > 0 && (
                <tr>
                  <td>Boneka Super Jumbo</td>
                  <td align="center">{bonekaSuperJumbo}</td>
                  <td align="right">
                    Rp. {numberWithCommas(bonekaSuperJumbo * priceBonekaSuperJumbo)},00
                  </td>
                </tr>
              )}
              {bedCover > 0 && (
                <tr>
                  <td>Bed Cover</td>
                  <td align="center">{bedCover}</td>
                  <td align="right">
                    Rp. {numberWithCommas(bedCover * priceBedCover)},00
                  </td>
                </tr>
              )}
              {bedCoverKing > 0 && (
                <tr>
                  <td>Bed Cover (King Size)</td>
                  <td align="center">{bedCoverKing}</td>
                  <td align="right">
                    Rp. {numberWithCommas(bedCoverKing * priceBedCoverKing)},00
                  </td>
                </tr>
              )}
              {karpet > 0 && (
                <tr>
                  <td>Karpet Tipis</td>
                  <td align="center">{karpet}</td>
                  <td align="right">
                    Rp. {numberWithCommas(karpet * priceKarpet)},00
                  </td>
                </tr>
              )}
              {karpetTebal > 0 && (
                <tr>
                  <td>Karpet Tebal</td>
                  <td align="center">{karpetTebal}</td>
                  <td align="right">
                    Rp. {numberWithCommas(karpetTebal * priceKarpetTebal)},00
                  </td>
                </tr>
              )}
              {korden > 0 && (
                <tr>
                  <td>Korden</td>
                  <td align="center">{korden}</td>
                  <td align="right">
                    Rp. {numberWithCommas(korden * priceKorden)},00
                  </td>
                </tr>
              )}
              {kordenJumbo > 0 && (
                <tr>
                  <td>Korden Jumbo</td>
                  <td align="center">{kordenJumbo}</td>
                  <td align="right">
                    Rp. {numberWithCommas(kordenJumbo * priceKordenJumbo)},00
                  </td>
                </tr>
              )}
              {koperCabin > 0 && (
                <tr>
                  <td>Koper Cabin</td>
                  <td align="center">{koperCabin}</td>
                  <td align="right">
                    Rp. {numberWithCommas(koperCabin * priceKoperCabin)},00
                  </td>
                </tr>
              )}
              {koperMedium > 0 && (
                <tr>
                  <td>Koper Medium</td>
                  <td align="center">{koperMedium}</td>
                  <td align="right">
                    Rp. {numberWithCommas(koperMedium * priceKoperMedium)},00
                  </td>
                </tr>
              )}
              {koperLarge > 0 && (
                <tr>
                  <td>Koper Large</td>
                  <td align="center">{koperLarge}</td>
                  <td align="right">
                    Rp. {numberWithCommas(koperLarge * priceKoperLarge)},00
                  </td>
                </tr>
              )}
              {tikar > 0 && (
                <tr>
                  <td>Tikar</td>
                  <td align="center">{tikar}</td>
                  <td align="right">
                    Rp. {numberWithCommas(tikar * priceTikar)},00
                  </td>
                </tr>
              )}
              <tr className="whitespace"></tr>
              <tr className="bordered"></tr>
              <tr>
                <td>Delivery Fee <span style={{ marginLeft: '5px', fontSize: "calc(6px + 0.3vw)", color: 'grey' }}> <br />Disc 50% (21 Feb - 31 May 2022)</span></td>
                <td></td>
                <td align="right">Rp. {numberWithCommas(deliveryPrice)},00</td>
              </tr>
              <tr>
                <td>Online Charge Fee <span style={{ marginLeft: '5px', fontSize: "calc(6px + 0.3vw)", color: 'grey' }}> <br />Free Charge (21 Feb - 31 May 2022)</span></td>
                <td></td>
                <td align="right" style={{ textDecoration: 'line-through' }}>Rp. 2,000,00</td>
              </tr>
            </tbody>
          </table>
          <br />
          <br />
          {/* <span>
            Ordered by : {firstname} {lastname}
          </span> */}
          <div className="d-flex fs-4 flex-row justify-content-between mt-0 mt-md-0 mb-md-2 mb-0">
            <span style={{ fontSize: "calc(15px + 0.5vw)", fontWeight: "700" }}>
              TOTAL :
            </span>
            <span
              style={{
                fontSize: "calc(15px + 0.5vw)",
                color: finished ? "rgb(41 169 18)" : "#D63736",
                fontWeight: "700",
              }}
            >
              Rp. {numberWithCommas(TotalPrice)},00
            </span>
          </div>
          {/* <div className="d-flex fs-4 flex-row justify-content-between mb-md-2 mb-0">
            <span style={{ fontSize: "calc(10px + 0.5vw)", fontWeight: "600" }}>
              Payment Status :
            </span>
            <span
              style={{
                fontSize: "calc(10px + 0.5vw)",
                color: finished ? "rgb(81 241 52)" : "#D63736",
                fontWeight: "600",
              }}
            >
              {finished ? "Paid" : "Unpaid"}
            </span>
          </div> */}
          {/* CATATAN LAUNDRY DI RECEIPT */}
          {/* {(noteKiloan5kg ||
            noteKiloan7kg ||
            noteBantal ||
            noteGuling ||
            noteJas ||
            noteKorden ||
            noteBedCover ||
            noteBedCoverKing ||
            noteBoneka) && (
              <div className="d-flex flex-column mt-md-2 mb-1 my-0">
                <span
                  style={{
                    fontSize: "calc(6px + 0.5vw)",
                    fontWeight: "600",
                    color: "#3a3939",
                  }}
                >
                  Tambahan Catatan :
                </span>
                <ul
                  style={{
                    fontSize: "calc(4px + 0.5vw)",
                    fontWeight: "600",
                    color: "#3a3939",
                    margin: "4px 0",
                  }}
                  className="d-flex flex-column"
                >
                  {noteKiloan5kg && (
                    <li>Cuci kering lipat 5kg: {noteKiloan5kg}</li>
                  )}
                  {noteKiloan7kg && (
                    <li>Cuci kering lipat 7kg: {noteKiloan7kg}</li>
                  )}
                  {noteBantal && <li>Bantal: {noteBantal}</li>}
                  {noteGuling && <li>Guling: {noteGuling}</li>}
                  {noteJas && <li>Jas: {noteJas}</li>}
                  {noteKorden && <li>Korden: {noteKorden}</li>}
                  {noteBedCover && <li>Bed Cover: {noteBedCover}</li>}
                  {noteBedCoverKing && <li>Bed Cover (King Size): {noteBedCoverKing}</li>}
                  {noteBoneka && <li>Boneka: {noteBoneka}</li>}
                </ul>
              </div>
            )} */}
          <span className="mt-2 mt-md-3" style={{ fontSize: "calc(6px + 0.3vw)", color: 'grey' }}>*Nota ini adalah bukti pembayaran sementara. <br />*Nota yang berlaku adalah nota asli setelah laundry diterima di outlet.</span>
        </ModalBody>
        <ModalFooter
          className="py-2 py-md-3"
          style={{ justifyContent: "center" }}
        >
          {isPaid && (
            <Button
              size="sm"
              className="py-1 px-2 py-md-2 px-md-3"
              color="danger"
              onClick={handlePrint}
              style={{ fontSize: "calc(10px + 0.5vw)" }}
            >
              Download Receipt
            </Button>
          )}
          <Button
            size="sm"
            className="py-1 px-2 py-md-2 px-md-3"
            color="secondary"
            onClick={toggleModal}
            style={{ fontSize: "calc(10px + 0.5vw)" }}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
      {
        finished && (
          <Container>
            <Row>
              <Col className="mt-5 d-flex flex-column flex-sm-row justify-content-center">
                <Button
                  color="danger"
                  size="lg"
                  className="m-2 mx-md-5 my-md-0"
                  onClick={() => setShowModal(true)}
                >
                  Check Receipt Again
                </Button>
                <a
                  href="https://pelangilaundrycoin.com"
                  className="m-auto m-md-0"
                >
                  <Button
                    color="secondary"
                    size="lg"
                    className="m-2 mx-md-5 my-md-0"
                  >
                    Back to Home Page
                  </Button>
                </a>
              </Col>
            </Row>
            <Row className="my-3 my-md-5 text-center">
              <h5 className="my-5 pb-5" style={{ fontSize: 'calc(0.2vw + 15px)' }}>Terima kasih telah menggunakan Layanan Pelangi Laundry Coin Delivery kami.<br />
                Anda akan menerima whatsapp message dari Admin kami dalam 5 menit. <br />Jika Anda tidak menerimanya mohon hubungi ke nomor customer service kami di 08978774888.</h5>
              <br />
              <br />
              <br />
              <br />
            </Row>
          </Container>
        )
      }
    </div >
  );
};

export default Bookingpage;
