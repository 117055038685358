import React from "react";
import { Container, Col, Row } from "reactstrap";
import "./Footer.scss";

const Footer = () => {
  return (
    <Container
      fluid
      className="pelangi-footer pt-5"
    >
      <Container>
        <Row>
          <Col xs={12} md={4} xl={3} className="d-flex flex-column">
            <h5 className="mb-4">Pelangi Laundry</h5>
            <span>Our Locations</span>
            <span>Our News</span>
            <br />
          </Col>
          <Col xs={12} md={4} xl={3} className="d-flex flex-column">
            <h5 className="mb-4">Media Sosial</h5>
            {/* <a href="https://instagram.com/pelangilaundry_pakel?utm_medium=copy_link"><img style={{ height: 'calc(12px + 0.4vw)', width: 'calc(12px + 0.4vw)' }} alt="ig" src="/icons/instagram.png"></img> Pelangi Pakel</a>
            <a href="https://instagram.com/pelangilaundry_nusukan?utm_medium=copy_link"> <img style={{ height: 'calc(12px + 0.4vw)', width: 'calc(12px + 0.4vw)' }} alt="ig" src="/icons/instagram.png"></img> Pelangi Nusukan</a>
            <a href="https://instagram.com/pelangilaundry_gentan?utm_medium=copy_link"><img style={{ height: 'calc(12px + 0.4vw)', width: 'calc(12px + 0.4vw)' }} alt="ig" src="/icons/instagram.png"></img> Pelangi Gentan</a>
            <a href="https://instagram.com/pelangilaundry_soba?utm_medium=copy_link"> <img style={{ height: 'calc(12px + 0.4vw)', width: 'calc(12px + 0.4vw)' }} alt="ig" src="/icons/instagram.png"></img> Pelangi Solo Baru</a> */}
            
            <a href="https://instagram.com/pelangilaundry_pakel?utm_medium=copy_link">Instagram Pelangi Pakel</a>
            <a href="https://instagram.com/pelangilaundry_nusukan?utm_medium=copy_link"> Instagram Pelangi Nusukan</a>
            <a href="https://instagram.com/pelangilaundry_gentan?utm_medium=copy_link">Instagram Pelangi Gentan</a>
            <a href="https://instagram.com/pelangilaundry_soba?utm_medium=copy_link"> Instagram Pelangi Solo Baru</a>
            <br />
          </Col>
          <Col xs={12} md={4} xl={3} className="d-flex flex-column">
            <h5 className="mb-4">Contact Us</h5>
            <h6>Email</h6>
            <span>pelangilaundrycare@gmail.com</span>
            <br />
            <h6>Phone Call</h6>
            <span>0895-3264-18881</span>
            <br />
          </Col>

          <Col xs={12} md={4} xl={3} className="d-flex flex-column">
            <h5 className="mb-4">Jam Operasional</h5>
            <span>
              Outlet Pelangi Laundry Senin - Minggu
              <br />07:00 AM - 21:00 PM WIB
              <br />
              <br />
              Pemesanan Online Senin - Sabtu
              <br />09:00 AM - 17:00 PM WIB
            </span>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col
            className="text-center py-4"
            style={{ fontSize: "14px" }}
          >
            <span>
              Copyright © 2021. All rights reserved.
              <br />
              Web Application created by{" "}
              <a
                href="https://evetechsolution.com"
                style={{ textDecoration: "underline" }}
              >
                EVE Technology Solution
              </a>
            </span>
          </Col>
        </Row>
        <Row className="py-4 py-md-2"></Row>
      </Container>
    </Container>
  );
};

export default Footer;
