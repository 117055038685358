import React from "react";
import Highlights from "../components/Highlights";
import Banner from "../components/Banner";
import Header from "../components/Header";
import HowItWorks from "../components/HowItWorks";
import SpecialProcessing from "../components/SpecialProcessing";
// import ServiceArea from "../components/ServiceArea";
import FAQ from "../components/FAQ";
import GetStartedToday from "../components/GetStartedToday";
import Footer from "../components/Footer";
import HighlightAnimation from "../components/HighlightAnimation";
import video from "../pelangiVideo.mp4"

const Homepage = () => {
  return (
    <React.Fragment>
      <Header />
      <Highlights />
      <Banner />
      <HighlightAnimation />
      <SpecialProcessing />
      <video autoPlay muted className="w-100">
        <source src={video} type="video/mp4" />
      </video>
      <HowItWorks />
      {/* <ServiceArea /> */}
      <FAQ />
      <GetStartedToday />
      <Footer />
    </React.Fragment>
  );
};

export default Homepage;
