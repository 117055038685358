export const service_data = [
    {
        title:  "Kiloan",
        image:  "/pictures/card-kiloan.jpg",
        detail: [
            {
                name:    "Cuci Kering Lipat 5kg",
                subname: "",
                image:   "pictures/laundryTypes/laundry.jpg",
                price:   25000
            },
            {
                name:    "Cuci Kering Lipat 7kg",
                subname: "",
                image:   "pictures/laundryTypes/laundry.jpg",
                price:   30000
            },
        ]
    },
    {
        title:  "Setrika",
        image:  "/pictures/card-setrika.jpg",
        detail: [
            {
                name:    "Setrika",
                subname: "(4rb/Kg)",
                image:   "pictures/laundryTypes/setrika.jpg",
                price:   4000
            }
        ]
    },
    {
        title:  "Dress Pesta",
        image:  "/pictures/card-dress.jpg",
        detail: [
            {
                name:    "Gaun",
                subname: "(Anak)",
                image:   "pictures/laundryTypes/gaun_anak.jpg",
                price:   15000
            },
            {
                name:    "Gaun",
                subname: "(Dewasa)",
                image:   "pictures/laundryTypes/gaun_dewasa.jpg",
                price:   20000
            },
            {
                name:    "Gaun",
                subname: "(Long Gown)",
                image:   "pictures/laundryTypes/gaun_longgown.jpg",
                price:   30000
            }
        ]
    },
    {
        title:  "Jas",
        image:  "/pictures/card-jas.webp",
        detail: [
            {
                name:    "Jas",
                subname: "(Atasan)",
                image:   "pictures/laundryTypes/jas.webp",
                price:   25000
            },
            {
                name:    "Jas",
                subname: "(Rompi)",
                image:   "pictures/laundryTypes/jasRompi.png",
                price:   15000
            },
            {
                name:    "Jas",
                subname: "(Setelan)",
                image:   "pictures/laundryTypes/jas2.jpg",
                price:   35000
            }
        ]
    },
    {
        title:  "Jaket",
        image:  "/pictures/card-jaket.jpg",
        detail: [
            {
                name:    "Jaket",
                subname: "(Bomber Parasut)",
                image:   "pictures/laundryTypes/jaket_parasut.jpg",
                price:   20000
            },
            {
                name:    "Jaket",
                subname: "(Jeans / Tebal)",
                image:   "pictures/laundryTypes/jaket_jeans.jpg",
                price:   25000
            },
            {
                name:    "Jaket",
                subname: "(Kulit)",
                image:   "pictures/laundryTypes/jaket_kulit.jpg",
                price:   30000
            }
        ]
    },
    {
        title:  "Kebaya",
        image:  "/pictures/card-kebaya.jpg",
        detail: [
            {
                name:    "Kebaya",
                subname: "",
                image:   "pictures/laundryTypes/kebaya.png",
                price:   20000
            }
        ]
    },
    {
        title:  "Kemeja",
        image:  "/pictures/card-kemeja.webp",
        detail: [
            {
                name:    "Kemeja",
                subname: "",
                image:   "pictures/laundryTypes/kemeja.png",
                price:   15000
            }
        ]
    },
    {
        title:  "Kaos / T-shirt",
        image:  "/pictures/card-kaos.jpg",
        detail: [
            {
                name:    "Kaos / T-shirt",
                subname: "",
                image:   "pictures/laundryTypes/kaos.png",
                price:   10000
            }
        ]
    },
    {
        title:  "Koper",
        image:  "/pictures/card-koper.jpg",
        detail: [
            {
                name:    "Koper",
                subname: "(Cabin <48cm)",
                image:   "pictures/laundryTypes/koper.jpg",
                price:   40000
            },
            {
                name:    "Koper",
                subname: "(Medium <67cm)",
                image:   "pictures/laundryTypes/koper.jpg",
                price:   55000
            },
            {
                name:    "Koper",
                subname: "(Large <48cm)",
                image:   "pictures/laundryTypes/koper.jpg",
                price:   80000
            }
        ]
    },
    {
        title:  "Boneka",
        image:  "/pictures/card-boneka.jpg",
        detail: [
            {
                name:    "Boneka",
                subname: "(Kecil <15cm)",
                image:   "pictures/laundryTypes/boneka.jpg",
                price:   10000
            },
            {
                name:    "Boneka",
                subname: "(Sedang <30cm)",
                image:   "pictures/laundryTypes/boneka.jpg",
                price:   20000
            },
            {
                name:    "Boneka",
                subname: "(Besar <50cm)",
                image:   "pictures/laundryTypes/boneka.jpg",
                price:   30000
            },
            {
                name:    "Boneka",
                subname: "(Jumbo <100cm)",
                image:   "pictures/laundryTypes/boneka.jpg",
                price:   40000
            },
            {
                name:    "Boneka",
                subname: "(Super Jumbo >100cm)",
                image:   "pictures/laundryTypes/boneka.jpg",
                price:   80000
            }
        ]
    },
    {
        title:  "Sajadah",
        image:  "/pictures/card-sajadah.webp",
        detail: [
            {
                name:    "Sajadah",
                subname: "",
                image:   "pictures/laundryTypes/sajadah.jpg",
                price:   10000
            }
        ]
    },
    {
        title:  "Selimut Bulu",
        image:  "/pictures/card-selimut-bulu.webp",
        detail: [
            {
                name:    "Selimut Bulu",
                subname: "(Single)",
                image:   "pictures/laundryTypes/selimut.jpg",
                price:   10000
            },
            {
                name:    "Selimut Bulu",
                subname: "(Queen / King)",
                image:   "pictures/laundryTypes/selimut.jpg",
                price:   20000
            },
        ]
    },
    {
        title:  "Alas Lantai",
        image:  "/pictures/card-alas-lantai.jpg",
        detail: [
            {
                name:    "Karpet",
                subname: "(Tipis / m2)",
                image:   "pictures/laundryTypes/karpet.jpg",
                price:   15000
            },
            {
                name:    "Karpet",
                subname: "(Tebal / m2)",
                image:   "pictures/laundryTypes/karpet.jpg",
                price:   20000
            },
            {
                name:    "Tikar",
                subname: "",
                image:   "pictures/laundryTypes/tikar.jpg",
                price:   20000
            },
        ]
    },
    {
        title:  "Tas",
        image:  "/pictures/card-tas.jpg",
        detail: [
            {
                name:    "Tas",
                subname: "(Kulit)",
                image:   "pictures/laundryTypes/tas.jpg",
                price:   20000
            },
            {
                name:    "Tas",
                subname: "(Kecil <30cm)",
                image:   "pictures/laundryTypes/tas_kain-kecil.jpg",
                price:   20000
            },
            {
                name:    "Tas",
                subname: "(Besar >30cm)",
                image:   "pictures/laundryTypes/tas_kain-besar.jpg",
                price:   20000
            }
        ]
    },
    {
        title:  "Topi",
        image:  "/pictures/card-topi.webp",
        detail: [
            {
                name:    "Topi",
                subname: "",
                image:   "pictures/laundryTypes/topi.jpg",
                price:   10000
            }
        ]
    },
    {
        title:  "Sepatu",
        image:  "/pictures/card-sepatu.webp",
        detail: [
            {
                name:    "Sepatu",
                subname: "(Kanvas)",
                image:   "pictures/laundryTypes/sepatu_kain.jpg",
                price:   25000
            },
            {
                name:    "Sepatu",
                subname: "(Kulit)",
                image:   "pictures/laundryTypes/sepatu_kulit.jpg",
                price:   25000
            },
            {
                name:    "Sepatu",
                subname: "(Boot)",
                image:   "pictures/laundryTypes/sepatu_boot.jpg",
                price:   30000
            }
        ]
    },
    {
        title:  "Bedcover",
        image:  "/pictures/card-bedcover.webp",
        detail: [
            {
                name:    "Bedcover",
                subname: "(Single)",
                image:   "pictures/laundryTypes/bedcover.jpg",
                price:   30000
            },
            {
                name:    "Bedcover",
                subname: "(Queen / King)",
                image:   "pictures/laundryTypes/bedcover.jpg",
                price:   40000
            }
        ]
    },
    {
        title:  "Bantal & Guling",
        image:  "/pictures/card-bantal-guling.jpg",
        detail: [
            {
                name:    "Bantal",
                subname: "",
                image:   "pictures/laundryTypes/bantal.jpg",
                price:   30000
            },
            {
                name:    "Guling",
                subname: "",
                image:   "pictures/laundryTypes/guling.jpg",
                price:   35000
            }
        ]
    },
    {
        title:  "Korden",
        image:  "/pictures/card-korden.jpg",
        detail: [
            {
                name:    "Korden",
                subname: "(Besar)",
                image:   "pictures/laundryTypes/korden.jpg",
                price:   30000
            },
            {
                name:    "Korden",
                subname: "(Jumbo)",
                image:   "pictures/laundryTypes/korden.jpg",
                price:   40000
            }
        ]
    }
];