import React, { useState, useEffect, useContext } from 'react'
import {
    Input,
    Row,
    Col,
    Container,
    Card,
    CardHeader,
    CardBody,
    Button,
    Label,
    Form,
    FormGroup,
    Alert,
    Collapse,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "reactstrap";
import "./PaymentMethod.scss";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { numberWithCommas, formatDate } from "../utils/utils";
import LanguageContext from "../store/LanguageContext";
import { mainContext } from "../store/MainContext";
import Layout from "../components/Reusable/Layout";
import { send } from "emailjs-com";
import { init } from "emailjs-com";

init("user_dO2KRHE0dFWDNSfHW6cRp");

const PaymentMethod = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
        for (let i = 0; i < ctxm.service.length; i++) {
            if (i === 0) {
                setNama0(ctxm.service[0].name);
                setQty0(ctxm.service[0].qty);
                setNote0(ctxm.service[0].note);
            }
            else if (i === 1) {
                setNama1(ctxm.service[1].name);
                setQty1(ctxm.service[1].qty);
                setNote1(ctxm.service[1].note);
            }
            else if (i === 2) {
                setNama2(ctxm.service[2].name);
                setQty2(ctxm.service[2].qty);
                setNote2(ctxm.service[2].note);
            }
            else if (i === 3) {
                setNama3(ctxm.service[3].name);
                setQty3(ctxm.service[3].qty);
                setNote3(ctxm.service[3].note);
            }
            else if (i === 4) {
                setNama4(ctxm.service[4].name);
                setQty4(ctxm.service[4].qty);
                setNote4(ctxm.service[4].note);
            }
            else if (i === 5) {
                setNama5(ctxm.service[5].name);
                setQty5(ctxm.service[5].qty);
                setNote5(ctxm.service[5].note);
            }
            else if (i === 6) {
                setNama6(ctxm.service[6].name);
                setQty6(ctxm.service[6].qty);
                setNote6(ctxm.service[6].note);
            }
            else if (i === 7) {
                setNama7(ctxm.service[7].name);
                setQty7(ctxm.service[7].qty);
                setNote7(ctxm.service[7].note);
            }
            else if (i === 8) {
                setNama8(ctxm.service[8].name);
                setQty8(ctxm.service[8].qty);
                setNote8(ctxm.service[8].note);
            }
            else if (i === 9) {
                setNama9(ctxm.service[9].name);
                setQty9(ctxm.service[9].qty);
                setNote9(ctxm.service[9].note);
            }
            else if (i === 10) {
                setNama10(ctxm.service[10].name);
                setQty10(ctxm.service[10].qty);
                setNote10(ctxm.service[10].note);
            }
        }
    }, [])

    const ctx = useContext(LanguageContext);
    const ctxm = useContext(mainContext);
    const [nama0, setNama0] = useState("");
    const [nama1, setNama1] = useState("");
    const [nama2, setNama2] = useState("");
    const [nama3, setNama3] = useState("");
    const [nama4, setNama4] = useState("");
    const [nama5, setNama5] = useState("");
    const [nama6, setNama6] = useState("");
    const [nama7, setNama7] = useState("");
    const [nama8, setNama8] = useState("");
    const [nama9, setNama9] = useState("");
    const [nama10, setNama10] = useState("");
    const [qty0, setQty0] = useState("");
    const [qty1, setQty1] = useState("");
    const [qty2, setQty2] = useState("");
    const [qty3, setQty3] = useState("");
    const [qty4, setQty4] = useState("");
    const [qty5, setQty5] = useState("");
    const [qty6, setQty6] = useState("");
    const [qty7, setQty7] = useState("");
    const [qty8, setQty8] = useState("");
    const [qty9, setQty9] = useState("");
    const [qty10, setQty10] = useState("");
    const [note0, setNote0] = useState("");
    const [note1, setNote1] = useState("");
    const [note2, setNote2] = useState("");
    const [note3, setNote3] = useState("");
    const [note4, setNote4] = useState("");
    const [note5, setNote5] = useState("");
    const [note6, setNote6] = useState("");
    const [note7, setNote7] = useState("");
    const [note8, setNote8] = useState("");
    const [note9, setNote9] = useState("");
    const [note10, setNote10] = useState("");
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [showQrisModal, setShowQrisModal] = useState(false);
    const [qrisImage, setQrisImage] = useState(false);
    const [qrisImageUrl, setQrisImageUrl] = useState("");
    const [qrisData, setQrisData] = useState([]);
    const [qrisStatus, setQrisStatus] = useState("");
    const [countdown, setCountdown] = useState(300); // 300 detik = 5 menit
    const [waLink, setWaLink] = useState(`https://api.whatsapp.com/send/?phone=${ctxm.phoneContainer}&text=Terima+kasih+telah+menggunakan+jasa+online+order+Pelangi+Laundry.+Pesanan+anda+telah+kami+terima.+Mohon+menyiapkan+pembayaran+tunai+dan+tunggu+kurir+kami+menjemput+laundry+anda.+Have+a+nice+day+!&app_absent=0`);
    const [showModalOrder, setShowModalOrder] = useState(false);

    const toggleModalOrder = () => {
        setShowModalOrder(!showModalOrder);
    };


    const navigate = useNavigate();

    useEffect(() => {
        if (ctxm.service.length === 0) {
            navigate("/service");
        }
        if (ctxm.addressSelection === false) {
            navigate("/service/address");
        }
        if (ctxm.selectedOption === 0) {
            navigate("/service/address");
        }
        if (ctxm.finished) {
            navigate("/service");
        }
    }, [ctxm.service, ctxm.addressSelection, ctxm.selectedOption, ctxm.finished]);



    // Prepend `0` for one digit numbers. For that the number has to be
    // converted to string, as numbers don't have length method
    const padTime = (time) => {
        return String(time).length === 1 ? `0${time}` : `${time}`;
    };

    const formatCount = (time) => {
        // Convert seconds into minutes and take the whole part
        const minutes = Math.floor(time / 60);

        // Get the seconds left after converting minutes
        const seconds = time % 60;

        //Return combined values as string in format mm:ss
        return `${padTime(minutes)}:${padTime(seconds)}`;
    };

    useEffect(() => {
        if (qrisImage === true) {
            let timer;
            if (countdown > 0) {
                timer = setTimeout(() => setCountdown(c => c - 1), 1000);
            }

            return () => {
                if (timer) {
                    clearTimeout(timer);
                }
            };
        }
    }, [qrisImage, countdown]);


    const toggleConfirmationModal = () => {
        setShowConfirmationModal(!showConfirmationModal)
    }

    useEffect(() => {
        if (ctxm.didMount.current) phoneValidation(ctxm.phone);
        else ctxm.didMount.current = true;
    }, [ctxm.phone])


    useEffect(() => {
        if (ctxm.selectedPayment === "QRIS") {
            setWaLink(`https://api.whatsapp.com/send/?phone=${ctxm.phoneContainer}&text=Terima+kasih+telah+menggunakan+jasa+online+order+Pelangi+Laundry.+Pesanan+anda+telah+kami+terima.+Tunggu+kurir+kami+menjemput+laundry+anda.+Have+a+nice+day+!&app_absent=0`);
        } else if (ctxm.selectedPayment === "BCA") {
            setWaLink(`https://api.whatsapp.com/send/?phone=${ctxm.phoneContainer}&text=Terima+kasih+telah+menggunakan+jasa+online+order+Pelangi+Laundry.+Pesanan+anda+telah+kami+terima.+Mohon+kirimkan+bukti+transfer+dan+tunggu+kurir+kami+menjemput+laundry+anda.+Have+a+nice+day+!&app_absent=0`);
        } else {
            setWaLink(`https://api.whatsapp.com/send/?phone=${ctxm.phoneContainer}&text=Terima+kasih+telah+menggunakan+jasa+online+order+Pelangi+Laundry.+Pesanan+anda+telah+kami+terima.+Mohon+menyiapkan+pembayaran+tunai+dan+tunggu+kurir+kami+menjemput+laundry+anda.+Have+a+nice+day+!&app_absent=0`);
        }
    }, [ctxm.selectedPayment, ctxm.phoneContainer])

    const handleSelectedPayment = (value) => {
        ctxm.setSelectedPayment(value);
    };

    const onClickPaid = (e) => {
        e.preventDefault();
        if (ctxm.selectedPayment === "Cash") {
            onSubmit();
        } else if (ctxm.selectedPayment === "QRIS") {
            if (qrisImage === true) {
                setShowQrisModal(true);
            } else {
                generateQris();
            }
        } else {
            setShowConfirmationModal(true);
        }
    }

    const phoneValidation = (a) => {
        const Temp = a.split('');
        if (Temp[0] === '0') {
            Temp.splice(0, 1, "6", "2");
            a = Temp.join('');
            ctxm.setPhoneContainer(a);
        }
        else if (Temp[0] === '+') {
            Temp.shift();
            a = Temp.join('');
            ctxm.setPhoneContainer(a);
        }
        else if (Temp[0] === '8') {
            Temp.splice(0, 0, "6", "2");
            a = Temp.join('');
            ctxm.setPhoneContainer(a);
        }
        else if (Temp[0] === '6' && Temp[1] === '2') {
            a = Temp.join('');
            ctxm.setPhoneContainer(a);
        }
        else {
            return
        }
    }

    const toggleQrisModal = () => {
        setShowQrisModal(!showQrisModal)
    }

    useEffect(() => {
        if (qrisImage === true) {
            if (qrisStatus === '200') {
                onSubmit();
            }
            else if (qrisStatus === '407') {
                setShowQrisModal(false);
                setQrisImage(false);
                console.log('Expired transaction (' + qrisStatus + ')');
            } else {
                const interval = setInterval(() => {
                    getPaymentConfirmationQRIS();
                    console.log('Get confirmation qris (' + qrisStatus + ')');
                }, 1000);
                return () => {
                    clearInterval(interval);
                }
            }
        }
    }, [qrisImage, qrisStatus])

    const getPaymentConfirmationQRIS = async () => {

        try {
            await axios.get(`/v2/${qrisData.order_id}/status`, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    // Authorization: Basic <Base64Encode("YourServerKey"+":")>
                    "Authorization": "Basic U0ItTWlkLXNlcnZlci1rcHJOenNXeTFXNlFnLWl5ZEo4U2VmdTY6",
                },
            })
                .then((response) => {
                    setQrisStatus(response.data.status_code);
                });
        } catch (e) {
            console.log(e);
        }
    }

    const generateQris = async () => {

        const jsonData = JSON.stringify({
            "payment_type": "qris",
            "transaction_details": {
                "order_id": "Q-" + Math.floor(Math.random() * Date.now()),
                "gross_amount": ctxm.TotalPrice
            },
            "customer_details": {
                "first_name": ctxm.firstname,
                "phone": ctxm.phone
            },
            "qris": {
                "acquirer": "gopay"
            },
            "custom_field1": ctxm.destinationName,
            "custom_expiry": {
                "expiry_duration": 5,
                "unit": "minute"
            }
        });

        try {
            await axios.post("/v2/charge", jsonData, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    // Authorization: Basic <Base64Encode("YourServerKey"+":")>
                    "Authorization": "Basic U0ItTWlkLXNlcnZlci1rcHJOenNXeTFXNlFnLWl5ZEo4U2VmdTY6",
                },
            })
                .then((response) => {
                    if (response.data.status_code === '201') {
                        setQrisImage(true);
                        setShowQrisModal(true);
                        setQrisData(response.data);
                        setQrisStatus(response.data.status_code);
                        setQrisImageUrl(response.data.actions[0].url);
                        setCountdown(300);
                    } else {
                        console.log('QRIS failed to create');
                    }
                });
        } catch (e) {
            console.log(e);
        }

    }

    const postData = async (name, phone, from, to, totalPrice, deliveryPrice, paymentType, paymentStatus, userTotalTransaction) => {
        try {
            await axios.post(`${process.env.REACT_APP_BE_URL}/orders/create/${name}/${phone}/${from}/${to}/${totalPrice}/${deliveryPrice}/${paymentType}/${paymentStatus}/${userTotalTransaction}`);
            // await axios.post(`http://localhost:5000/api/users/${name}/${phone}/${from}/${to}/${totalPrice}/${deliveryPrice}/${paymentType}/${paymentStatus}/${userTotalTransaction}`);
        } catch (e) {
            console.log(e);
        }
    };

    const onSubmit = () => {
        ctxm.setTotalService(0);
        ctxm.setTotalItem(0);
        ctxm.setFinished(true);
        setShowConfirmationModal(false);
        setShowQrisModal(false);
        setQrisImage(false);

        const paymentStatus = ctxm.selectedPayment === "QRIS" ? "Lunas" : "Belum Bayar";

        //POSTING DATA TO BACKEND / CLOUD STORAGE
        // postData(
        //     ctxm.firstname,
        //     ctxm.phone,
        //     ctxm.address,
        //     ctxm.destinationName,
        //     ctxm.TotalPrice,
        //     ctxm.deliveryPrice,
        //     ctxm.selectedPayment,
        //     paymentStatus,
        //     "1010101010"
        // );

        //POSTING DATA TO EMAIL
        send(
            "service_qk2nhbq",
            ctxm.emailTemplate,
            {
                fullname: ctxm.firstname,
                address: ctxm.address,
                origin: ctxm.origin,
                destinationName: ctxm.destinationName,
                phone: ctxm.phone,
                phoneContainer: ctxm.phoneContainer,
                laundryNotes: ctxm.laundryNotes,
                deliveryNotes: ctxm.deliveryNotes,
                nama0,
                nama1,
                nama2,
                nama3,
                nama4,
                nama5,
                nama6,
                nama7,
                nama8,
                nama9,
                nama10,
                qty0,
                qty1,
                qty2,
                qty3,
                qty4,
                qty5,
                qty6,
                qty7,
                qty8,
                qty9,
                qty10,
                note0,
                note1,
                note2,
                note3,
                note4,
                note5,
                note6,
                note7,
                note8,
                note9,
                note10,
                waLink,
                totalPrice: "Rp " + numberWithCommas(ctxm.TotalPrice) + ",00",
                orderTime: formatDate(Date.now()),
                selectedPayment: ctxm.selectedPayment,
            },
            "user_dO2KRHE0dFWDNSfHW6cRp"
        )
            .then((response) => {
                console.log("SUCCESS!", response.status, response.text);
            })
            .catch((err) => {
                console.log("FAILED...", err);
            });

        navigate("/service/finish");
    };

    return (
        <>
            <Layout>
                <Container className="select-payment mb-5">
                    <Form onSubmit={onClickPaid}>
                        <Row>
                            <Col md="12">
                                <Card className='payment-card'>
                                    <CardHeader className='payment-card-header'>
                                        <Row>
                                            <Col md={6} xs={12} className="mb-3">
                                                <span className="d-block d-md-none">Langkah 3/3</span>
                                                <h3 className="mb-0">Pembayaran</h3>
                                                <span className="d-none d-md-block">Langkah 3/3</span>
                                            </Col>
                                            <Col md={6} xs={12} className="text-md-end">
                                                <span><b>{ctxm.totalService}</b> Layanan yang anda pilih</span>
                                                <br />
                                                <b>Rp {numberWithCommas(ctxm.TotalPrice)}</b>
                                                <br />
                                                <Link
                                                    to="#"
                                                    style={{ color: 'white', lineHeight: '1' }}
                                                    onClick={() => setShowModalOrder(true)}
                                                >
                                                    Lihat Rincian
                                                </Link>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <Row className="mt-2">
                                            <Col>
                                                <Alert style={{ backgroundColor: 'var(--primary-color)', color: 'white', borderRadius: '10px', opacity: '0.7' }} className="alertText">
                                                    {ctx.language.step2Warning}
                                                </Alert>
                                            </Col>
                                        </Row>
                                        <Row className="d-flex justify-content-between">
                                            {/* Customer Form */}
                                            <Col xs={12} md={6}>
                                                <Row>
                                                    <Col className="my-2 my-md-3" sm={12}>
                                                        <FormGroup>
                                                            <Label htmlFor="from_name" className="labelText mb-1">
                                                                <span style={{ fontWeight: "600" }}>{ctx.language.step2Fullname}</span> <span style={{ color: "red" }}>*</span>
                                                            </Label>
                                                            <Input
                                                                required
                                                                type="text"
                                                                name="from_name"
                                                                id="firstName"
                                                                className="placeholderText"
                                                                placeholder={ctx.language.step2FullnamePlaceholder}
                                                                onChange={(e) => ctxm.setFirstname(e.target.value)}
                                                                style={{ borderRadius: '10px' }}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="my-2 my-md-3" sm={12}>
                                                        <Label htmlFor="phone" className="labelText mb-1">
                                                            <span style={{ fontWeight: "600" }}>{ctx.language.step2Whatsapp}</span> <span style={{ color: "red" }}>*</span>{" "}
                                                            {ctx.language.step2Whatsapp2}
                                                        </Label>
                                                        <Input
                                                            required
                                                            type="number"
                                                            name="phone"
                                                            id="phone"
                                                            className="placeholderText"
                                                            placeholder={ctx.language.step2WhatsappPlaceholder}
                                                            autoComplete="on"
                                                            onChange={(e) => ctxm.setPhone(e.target.value)}
                                                            style={{ borderRadius: '10px' }}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="my-2 my-md-3" sm={12}>
                                                        <FormGroup>
                                                            <Label htmlFor="laundrynotes" className="labelText mb-1">
                                                                <span style={{ fontWeight: "600" }}>{ctx.language.Step2Notes}</span>{" "}{ctx.language.Step2Notes2}
                                                            </Label>
                                                            <Input
                                                                className="placeholderText"
                                                                type="textarea"
                                                                name="text"
                                                                id="laundrynotes"
                                                                onChange={(e) => ctxm.setLaundryNotes(e.target.value)}
                                                                style={{ minHeight: "7.5rem", borderRadius: '10px' }}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </Col>

                                            {/* Payment Method */}
                                            <Col className="my-3" xs={12} md={6}>
                                                <h4 style={{ fontSize: 'calc(13px + 0.17vw)' }}>Pilihan pembayaran</h4>
                                                <Row>
                                                    <Col md={12} className="mb-4">
                                                        <Label className={`payment-card-label ${ctxm.selectedPayment === "BCA" && `checked`}`}>
                                                            <Card>
                                                                <CardBody style={{ padding: '1rem' }}>
                                                                    <Row>
                                                                        <Col xs={2} sm={1} className="pr-0">
                                                                            <Input
                                                                                className="payment-card-input"
                                                                                type="radio"
                                                                                name="payment"
                                                                                style={{ marginRight: '1vw' }}
                                                                                checked={ctxm.selectedPayment === "BCA" ? true : false}
                                                                                onChange={() => handleSelectedPayment("BCA")} />
                                                                        </Col>
                                                                        <Col xs={10} sm={11} className="pl-0">
                                                                            <span style={{ fontSize: 'calc(13px + 0.2vw)', fontWeight: '600' }}>Transfer BCA</span>
                                                                            <Collapse isOpen={ctxm.selectedPayment === "BCA" ? true : false}>
                                                                                {ctxm.selectedPayment === "BCA" && (
                                                                                    <div className='payment-card-text'>
                                                                                        <div className='d-flex align-items-center'>
                                                                                            {ctxm.selectedOption === 1 && (
                                                                                                <div>
                                                                                                    Nomor Rekening <b>015 413 5011</b>
                                                                                                    <br /> a.n <b>Devi Rianasari</b>
                                                                                                    <br />Pelangi Laundy Pakel
                                                                                                </div>
                                                                                            )}
                                                                                            {ctxm.selectedOption === 2 && (
                                                                                                <div>
                                                                                                    Nomor Rekening <b>015 130 7611</b>
                                                                                                    <br /> a.n <b>Yenny Sri Murwani</b>
                                                                                                    <br />Pelangi Laundy Nusukan
                                                                                                </div>
                                                                                            )}
                                                                                            {ctxm.selectedOption === 3 && (
                                                                                                <div>
                                                                                                    Nomor Rekening <b>015 426 5879</b>
                                                                                                    <br /> a.n <b>Devi Rianasari</b>
                                                                                                    <br />Pelangi Laundy Gentan
                                                                                                </div>
                                                                                            )}
                                                                                            {ctxm.selectedOption === 4 && (
                                                                                                <div>
                                                                                                    Nomor Rekening <b>785 114 4548</b>
                                                                                                    <br /> a.n <b>Charlie Yulianto</b>
                                                                                                    <br />Pelangi Laundy Solo Baru
                                                                                                </div>
                                                                                            )}
                                                                                            <div className="bca-icon">
                                                                                                <img src="/pictures/bca-logo-white.png" alt="" style={{ width: 'calc(1rem + 2vw)' }} />
                                                                                            </div>
                                                                                        </div>
                                                                                        <hr style={{ marginTop: '3vw' }} />
                                                                                        <div className='d-flex flex-column flex-md-row justify-content-between'>
                                                                                            <span>Pembayaran sebesar <b>Rp {numberWithCommas(ctxm.TotalPrice)}</b></span>
                                                                                            <Link
                                                                                                to="#"
                                                                                                style={{ color: '#3DCFDC' }}
                                                                                                onClick={() => setShowModalOrder(true)}
                                                                                            >
                                                                                                Lihat Rincian
                                                                                            </Link>
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                            </Collapse>
                                                                        </Col>
                                                                    </Row>
                                                                </CardBody>
                                                            </Card>
                                                        </Label>
                                                    </Col>

                                                    {/* <Col md={12} className="mb-4">
                                                        <Label className={`payment-card-label ${ctxm.selectedPayment === "QRIS" && `checked`}`}>
                                                            <Card>
                                                                <CardBody style={{ padding: '1rem' }}>
                                                                    <Row>
                                                                        <Col xs={2} sm={1} className="pr-0">
                                                                            <Input
                                                                                className="payment-card-input"
                                                                                type="radio"
                                                                                name="payment"
                                                                                style={{ marginRight: '1vw' }}
                                                                                onChange={() => handleSelectedPayment("QRIS")}
                                                                                checked={ctxm.selectedPayment === "QRIS" ? true : false} />
                                                                        </Col>
                                                                        <Col xs={10} sm={11} className="pl-0">
                                                                            <span style={{ fontSize: 'calc(13px + 0.2vw)', fontWeight: '600' }}>QRIS</span>
                                                                            <Collapse isOpen={ctxm.selectedPayment === "QRIS" ? true : false}>
                                                                                {ctxm.selectedPayment === "QRIS" && (
                                                                                    <div className='payment-card-text'>
                                                                                        <span>Barcode QRIS <strong>Pelangi Laundry</strong></span>
                                                                                        <br />
                                                                                        <div className="qris-icon">
                                                                                            <img src="/pictures/qris.png" alt="" style={{ width: 'calc(1.5rem + 2vw)' }} />
                                                                                        </div>
                                                                                        <hr />
                                                                                        <div className='d-flex flex-column flex-md-row justify-content-between'>
                                                                                            <span>Pembayaran sebesar <b>Rp {numberWithCommas(ctxm.TotalPrice)}</b></span>
                                                                                            <Link
                                                                                                to="#"
                                                                                                style={{ color: '#3DCFDC' }}
                                                                                                onClick={() => setShowModalOrder(true)}
                                                                                            >
                                                                                                Lihat Rincian
                                                                                            </Link>
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                            </Collapse>
                                                                        </Col>
                                                                    </Row>
                                                                </CardBody>
                                                            </Card>
                                                        </Label>
                                                    </Col> */}

                                                    <Col md={12} className="mb-4">
                                                        <Label className={`payment-card-label ${ctxm.selectedPayment === "Cash" && `checked`}`}>
                                                            <Card>
                                                                <CardBody style={{ padding: '1rem' }}>
                                                                    <Row>
                                                                        <Col xs={2} sm={1} className="pr-0">
                                                                            <Input
                                                                                className="payment-card-input"
                                                                                type="radio"
                                                                                name="payment"
                                                                                style={{ marginRight: '1vw' }}
                                                                                onChange={() => handleSelectedPayment("Cash")}
                                                                                checked={ctxm.selectedPayment === "Cash" ? true : false} />
                                                                        </Col>
                                                                        <Col xs={10} sm={11} className="pl-0">
                                                                            <span style={{ fontSize: 'calc(13px + 0.2vw)', fontWeight: '600' }}>COD (Cash on Delivery)</span>
                                                                            <Collapse isOpen={ctxm.selectedPayment === "Cash" ? true : false}>
                                                                                {ctxm.selectedPayment === "Cash" && (
                                                                                    <div className='payment-card-text'>
                                                                                        <span>Mohon lakukan pembayaran melalui kurir kami saat penjemputan</span>
                                                                                        <hr />
                                                                                        <div className='d-flex flex-column flex-md-row justify-content-between'>
                                                                                            <span>Pembayaran sebesar <b>Rp {numberWithCommas(ctxm.TotalPrice)}</b></span>
                                                                                            <Link
                                                                                                to="#"
                                                                                                style={{ color: '#3DCFDC' }}
                                                                                                onClick={() => setShowModalOrder(true)}
                                                                                            >
                                                                                                Lihat Rincian
                                                                                            </Link>
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                            </Collapse>
                                                                        </Col>
                                                                    </Row>
                                                                </CardBody>
                                                            </Card>
                                                        </Label>
                                                    </Col>

                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row
                                            className="my-5 d-flex align-items-center justify-content-between"
                                            style={{
                                                borderTop: "1px solid #F5F5F5",
                                                paddingBottom: "2rem",
                                            }}
                                        >
                                            <Col sm={12} md={6} className="pt-4">
                                                <p style={{ fontSize: "calc(0.5vw + 5px)" }}>
                                                    {ctx.language.step1info1}
                                                    <br></br>
                                                    {ctx.language.step1info2}
                                                </p>
                                            </Col>
                                            <Col sm={12} md={6} className="pt-1 pt-md-4" style={{ textAlign: 'right' }}>
                                                <Button
                                                    size="md"
                                                    className="btn-next mt-3"
                                                    type="submit"
                                                >
                                                    {ctxm.selectedPayment === "QRIS" ?
                                                        qrisImage === true ?
                                                            "Cek QR Code" :
                                                            "Selesaikan" : "Selesaikan"}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Form>

                </Container>
            </Layout>

            {/* Modal Konfirmasi Pembayaran */}
            <Modal centered isOpen={showConfirmationModal} toggle={toggleConfirmationModal}>
                <ModalHeader cssModule={{ 'modal-title': 'w-100 text-center' }}><span style={{ fontSize: "calc(0.2vw + 12px)" }}>Apakah Anda sudah melakukan pembayaran?</span><br /><span style={{ fontSize: 'calc(0.2vw + 10px)', color: 'red' }}>*Jika belum, mohon selesaikan pembayaran terlebih dahulu.*</span></ModalHeader>
                <ModalFooter className="m-auto">
                    <Button style={{ backgroundColor: 'var(--primary-color)', border: 'none' }} onClick={onSubmit}>Ya, Sudah Bayar</Button>{' '}
                    <Button
                        onClick={toggleConfirmationModal}
                        style={{
                            color: 'var(--primary-color)',
                            border: '1px solid var(--primary-color)',
                            backgroundColor: 'transparent'
                        }}
                    >
                        Belum Bayar
                    </Button>
                </ModalFooter>
            </Modal>

            {/* Modal QRIS */}
            <Modal centered isOpen={showQrisModal} toggle={toggleQrisModal}>
                <ModalHeader toggle={toggleQrisModal} cssModule={{ 'modal-title': 'w-100 text-center', 'close': 'btn-close' }} charCode="">
                    <span style={{ fontSize: "calc(0.2vw + 12px)", marginLeft: "calc(0.2vw + 4%)" }}>Online Payment</span>
                </ModalHeader>
                <ModalBody className="p-4 p-md-4 d-flex flex-column">
                    <div className="mb-4">
                        <img src="/pictures/qris-logo.png" alt="QRIS Logo" style={{ width: "30%", position: "absolute", top: "5px", left: "10px" }} />
                        <img src="/pictures/gpn-logo.png" alt="GPN Logo" style={{ width: "6%", position: "absolute", top: "5px", right: "20px" }} />
                    </div>
                    <h5 className="text-uppercase text-center" style={{ fontSize: "calc(0.2vw + 16px)" }}>Pelangi Laundry</h5>
                    <h5 className="text-center" style={{ fontSize: "calc(0.2vw + 18px)", fontWeight: "700" }}>Rp. {numberWithCommas(ctxm.TotalPrice)}</h5>
                    <h5 className="text-center" style={{ fontSize: "calc(0.2vw + 10px)", fontWeight: "700" }}>Batas Bayar : {formatCount(countdown)}</h5>
                    <div className="text-center">
                        <img src={qrisImageUrl} alt="QRIS CODE" style={{ width: "60%", margin: "auto" }} />
                    </div>
                    <div className="text-center">
                        <img src="/pictures/dana-logo.png" alt="Dana Logo" style={{ width: "20%", margin: "0 3px" }} />
                        <img src="/pictures/gopay-logo.png" alt="Gopay Logo" style={{ width: "25%", margin: "0 3px" }} />
                        <img src="/pictures/shopee-pay-logo.png" alt="ShopeePay Logo" style={{ width: "17%", margin: "0 3px" }} />
                    </div>
                    <div className="text-center">
                        <img src="/pictures/link-aja-logo.png" alt="LinkAja Logo" style={{ width: "6%", margin: "0 3px" }} />
                        <img src="/pictures/ovo-logo.png" alt="Ovo Logo" style={{ width: "10%", margin: "0 3px" }} />
                        <img src="/pictures/bni-logo.png" alt="BNI Logo" style={{ width: "13%", margin: "0 3px" }} />
                        <img src="/pictures/bca-logo.png" alt="BCA Logo" style={{ width: "15%", margin: "0 3px" }} />
                        <img src="/pictures/bri-logo.png" alt="BRI Logo" style={{ width: "6%", margin: "0 3px" }} />
                    </div>
                </ModalBody>
            </Modal>

            {/* Modal Receipt */}
            <Modal
                size="md"
                scrollable
                isOpen={showModalOrder}
                toggle={toggleModalOrder}
                centered
                className="modal"
            >
                <ModalBody className="p-4 p-md-4 d-flex flex-column">
                    <Row>
                        <Col xs={6} className="text-muted modal__header">
                            Order: {Math.floor(Math.random() * 100000)}
                        </Col>
                        <Col xs={6} className="text-muted text-end modal__header">
                            {formatDate(Date.now())}
                        </Col>
                    </Row>

                    <span
                        className="mt-4"
                        style={{ fontSize: "calc(8px + 0.4vw)", fontWeight: "700" }}
                    >
                        PAYMENT SUMMARY
                    </span>
                    <table className="mt-2">
                        <thead className="text-muted">
                            <tr>
                                <th>ITEMS</th>
                                <th className="text-center">QUANTITY</th>
                                <th className="text-end">PRICE</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="whitespace"></tr>
                            {ctxm.service.length > 0 && (
                                <>
                                    {ctxm.service.map((item, i) =>
                                        <tr key={i}>
                                            <td>{item.name}</td>
                                            <td align="center">{item.qty}</td>
                                            <td align="right">
                                                Rp. {numberWithCommas(item.qty * item.price)},00
                                            </td>
                                        </tr>
                                    )}
                                </>
                            )}
                            <tr className="whitespace"></tr>
                            <tr className="bordered"></tr>
                            <tr>
                                {ctxm.deliveryPrice > 0 ? (
                                    <>
                                        <td>Delivery Fee <span style={{ marginLeft: '5px', fontSize: "calc(6px + 0.3vw)", fontWeight: '500', color: 'grey' }}> <br />Free Delivery Fee (Disc 50%)</span></td>
                                        <td></td>
                                        <td align="right">
                                            <span style={{ textDecoration: 'line-through' }}>Rp. {numberWithCommas(ctxm.deliveryPrice)},00</span>
                                            <br />
                                            <span>Rp. {numberWithCommas(ctxm.deliveryPrice / 2)},00</span>
                                        </td>
                                    </>
                                ) : (
                                    <>
                                        <td>Delivery Fee</td>
                                        <td></td>
                                        <td align="right">To be info by Admin</td>
                                    </>
                                )}
                            </tr>
                            <tr>
                                <td>Online Charge Fee <span style={{ marginLeft: '5px', fontSize: "calc(6px + 0.3vw)", fontWeight: '500', color: 'grey' }}> <br />Free Charge Fee (Disc 100%)</span></td>
                                <td></td>
                                <td align="right" style={{ textDecoration: 'line-through' }}>Rp. 2,000,00</td>
                            </tr>
                        </tbody>
                    </table>
                    <br />
                    <br />
                    <div className="d-flex fs-4 flex-row justify-content-between mt-0 mt-md-0 mb-md-2 mb-0">
                        <span style={{ fontSize: "calc(15px + 0.5vw)", fontWeight: "700" }}>
                            TOTAL :
                        </span>
                        <span
                            style={{
                                fontSize: "calc(15px + 0.5vw)",
                                color: ctxm.finished ? "rgb(41 169 18)" : "#D63736",
                                fontWeight: "700",
                            }}
                        >
                            Rp. {numberWithCommas(ctxm.TotalPrice)},00
                        </span>
                    </div>
                    <span className="mt-2 mt-md-3" style={{ fontSize: "calc(6px + 0.3vw)", color: 'grey' }}>*Nota ini adalah bukti pembayaran sementara. <br />*Nota yang berlaku adalah nota asli setelah laundry diterima di outlet.</span>
                </ModalBody>
                <ModalFooter
                    className="py-2 py-md-3"
                    style={{ justifyContent: "center" }}
                >
                    <Button
                        size="sm"
                        className="py-1 px-2 py-md-2 px-md-3"
                        color="secondary"
                        onClick={toggleModalOrder}
                        style={{
                            color: 'white',
                            border: '1px solid var(--primary-color)',
                            backgroundColor: 'var(--primary-color)',
                            fontSize: "calc(10px + 0.5vw)"
                        }}
                    >
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
}
export default PaymentMethod