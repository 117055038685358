import React from "react";
import Lottie from "react-lottie";

export default function App(props) {
  let height = 300;
  if (props.height) {
    height = props.height;
  }
  let width = 500;
  if (props.width) {
    width = props.width;
  }
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: props.animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div>
      <Lottie options={defaultOptions} height={height} width={width} />
    </div>
  );
}
