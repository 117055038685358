import React, { createContext, useState, useRef, useEffect } from "react";

export const mainContext = createContext({
    placeid_branch1: '',
    placeid_branch2: '',
    placeid_branch3: '',
    placeid_branch4: '',
    didMount: false,
    totalService: 0,
    setTotalService: () => { },
    totalItem: 0,
    setTotalItem: () => { },
    cart: [],
    setCart: () => { },
    showCart: false,
    setShowCart: () => { },
    destinationName: '',
    setDestinationName: () => { },
    origin: '',
    setOrigin: () => { },
    address: '',
    setAddress: () => { },
    destination: '',
    setDestination: () => { },
    duration: 0,
    setDuration: () => { },
    selectedOption: 0,
    setSelectedOption: () => { },
    firstname: '',
    setFirstname: () => { },
    phone: '',
    setPhone: () => { },
    phoneContainer: '',
    setPhoneContainer: () => { },
    laundryNotes: '',
    setLaundryNotes: () => { },
    deliveryNotes: '',
    setDeliveryNotes: () => { },
    addressSelection: false,
    setAddressSelection: () => { },
    selectedPayment: '',
    setSelectedPayment: () => { },
    finished: false,
    setFinished: () => { },
    deliveryPrice: 0,
    TotalPrice: 0,
    setTotalPrice: () => { },
    service: [],
    setService: () => { },
    emailTemplate: '',
    setEmailTemplate: () => { },


});

const MainContextProvider = ({ children }) => {
    //Branch 1 - Pelangi Pakel
    const placeid_branch1 = "ChIJDxk1DLYVei4Ro41YTODjlOs";
    //Branch 2 - Pelangi Nusukan
    const placeid_branch2 = "ChIJx4OS0jIXei4R5E3HjTwvIwQ";
    //Branch 3 - Pelangi Gentan
    const placeid_branch3 = "ChIJnUnXIpMVei4R754meHcCkko";
    //Branch 4 - Pelangi Solo Baru
    const placeid_branch4 = "ChIJg8_Wke0Vei4RCSnnKYYahcM";

    const [showCart, setShowCart] = useState(false);

    const didMount = useRef(false);
    const [totalService, setTotalService] = useState(0);
    const [totalItem, setTotalItem] = useState(0);
    const [destinationName, setDestinationName] = useState("");
    const [origin, setOrigin] = useState("ChIJjaWSfyoUei4R51HTSQ7Kr34"); // Solo Grand Mall
    const [address, setAddress] = useState("");
    const [destination, setDestination] = useState(placeid_branch1);
    const [duration, setDuration] = useState(0);
    const [selectedOption, setSelectedOption] = useState(0);
    const [finished, setFinished] = useState(false);
    const [addressSelection, setAddressSelection] = useState(false);
    const [emailTemplate, setEmailTemplate] = useState("template_pelangi");

    const deliveryPrice = Math.ceil((duration * 19) / 1000) * 1000;
    const [phoneContainer, setPhoneContainer] = useState();

    // Payment Process State
    const [selectedPayment, setSelectedPayment] = useState("BCA");

    //Contact Details Form
    const [firstname, setFirstname] = useState("");
    const [phone, setPhone] = useState("");
    const [laundryNotes, setLaundryNotes] = useState("Tidak ada catatan");
    const [deliveryNotes, setDeliveryNotes] = useState("Tidak ada catatan");

    //Item Quantity
    const [cart, setCart] = useState([]);


    const [service, setService] = useState([]);

    const [TotalPrice, setTotalPrice] = useState(0);

    useEffect(() => {
        let x = 0
        service.map(i => {
            x += i.price * i.qty
            return setTotalPrice(x + (deliveryPrice / 2))
        })
        // console.log('total price : ' + TotalPrice)
    }, [service, deliveryPrice])




    const resetDefault = () => {
        setTotalService(0)
        setTotalItem(0)
        setTotalPrice(0)
        setService([])
        setFinished(false);
    }

    return (
        <mainContext.Provider
            value={{
                resetDefault,
                placeid_branch1,
                placeid_branch2,
                placeid_branch3,
                placeid_branch4,
                didMount,
                totalService,
                setTotalService,
                totalItem,
                setTotalItem,
                cart,
                setCart,
                showCart,
                setShowCart,
                destinationName,
                setDestinationName,
                origin,
                setOrigin,
                address,
                setAddress,
                destination,
                setDestination,
                duration,
                setDuration,
                selectedOption,
                setSelectedOption,
                firstname,
                setFirstname,
                phone,
                setPhone,
                phoneContainer,
                setPhoneContainer,
                laundryNotes,
                setLaundryNotes,
                deliveryNotes,
                setDeliveryNotes,
                addressSelection,
                setAddressSelection,
                selectedPayment,
                setSelectedPayment,
                finished,
                setFinished,
                deliveryPrice,
                TotalPrice,
                setTotalPrice,
                service,
                setService,
                emailTemplate,
                setEmailTemplate
            }}
        >
            {children}
        </mainContext.Provider>
    );
};

export default MainContextProvider;
