import "./App.scss"
import React from 'react'
// import Cursor from './components/Cursor';
// import Notification from './components/Notification';
import Homepage from './pages/Homepage';
import Bookingpage from './pages/Bookingpage';
import News from "./pages/News";
import Service from "./pages/Service";
import SelectAddress from "./components/SelectAddress";
import PaymentMethod from "./components/PaymentMethod";
import FinishOrder from "./components/FinishOrder";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

function App() {

  return (
    <Router>
      <Routes>
        {/* <Cursor /> */}
        {/* <Notification/> */}
        <Route path='/' exact element={<Service />}/>
        <Route path='/about' exact element={<Homepage />}/>
        <Route path='/order' element={<Bookingpage />}/>
        <Route path='/news' element={<News />}/>
        <Route path='/service/address' element={<SelectAddress />}/>
        <Route path='/service/payment' element={<PaymentMethod />}/>
        <Route path='/service/finish' element={<FinishOrder />}/>
      </Routes>
    </Router >
  );
}

export default App;
