import { Alert } from "reactstrap";
import { FcInfo } from "react-icons/fc";
import "./OperationalAlert.scss";

const OperationalAlert = () => {

    return (
        <Alert color="info" style={{ borderRadius: '10px' }}>
            <div className="d-flex align-items-start gap-2 operational-alert">
                <div className="operational-alert-icon">
                    <FcInfo size="100%" />
                </div>
                <div className="operational-alert-content">
                    <h5>Jam Operasional</h5>
                    <span>
                        Pemesanan Online hanya tersedia pada hari <b>Senin</b> hingga <b>Sabtu</b>, mulai dari pukul <b>09:00 WIB</b> hingga <b>17:00 WIB</b>. Terima kasih atas pengertian dan kesabaran Anda.
                    </span>
                </div>
            </div>
        </Alert>
    )
}

export default OperationalAlert