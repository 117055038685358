import React, { useContext } from "react";
import { Container, Row, Col } from "reactstrap";
import LanguageContext from "../store/LanguageContext";

const SpecialProcessing = () => {
  const ctx = useContext(LanguageContext)
  return (
    <Container
      fluid
      className="py-4 py-md-5"
      style={{ backgroundColor: "var(--color-secondary)" }}
    >
      <Container>
        <Row className="d-flex flex-column flex-md-row">
          <Col className="d-flex justify-content-center mb-3 mb-md-0 ">
            <img
              alt="how it works"
              src="../pictures/specialprocessing.png"
              style={{ width: "25vw", height: "21vw", minHeight: '7rem', minWidth: '10rem' }}
            />
          </Col>
          <Col
            className="d-flex flex-column justify-content-center"
            style={{ color: "white" }}
          >
            <h2 style={{ fontSize: "calc(14px + 1.6vw)" }}>
              {ctx.language.hl2ProcessingTitle}
            </h2>
            <h5 className="mt-3" style={{ fontSize: "calc(5px + 1vw)", textAlign: 'justify' }}>
              {ctx.language.hl2ProcessingSubtitle}
            </h5>
            <p style={{ fontSize: "calc(7px + 0.5vw)", textAlign: 'justify' }}>
              {ctx.language.hl2Processing}
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default SpecialProcessing;
