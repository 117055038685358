import React, { useContext } from 'react'
import { Col, Container, Row } from 'reactstrap'
import '../App.scss'
import LanguageContext from '../store/LanguageContext'

const Highlights = () => {
    const ctx = useContext(LanguageContext)
    return (
        <Container>
            <Row className='my-5 pb-3'>
                <Col xs={6} md={4} className="text-center" style={{ paddingTop: '1.5vw' }}>
                    <img src='icons/delivery.png' alt='icon' style={{ height: 'auto', width: '8rem' }} />
                    <h5 className="my-3" style={{ fontSize: 'calc(0.8vw + 8px)' }}>{ctx.language.hl1title}</h5>
                    <p>{ctx.language.hl1desc}</p>
                </Col>
                <Col xs={6} md={4} className="text-center" style={{ paddingTop: '1.5vw' }}>
                    <img src='icons/express.png' alt='icon' style={{ height: 'auto', width: '8rem' }} />
                    <h5 className="my-3" style={{ fontSize: 'calc(0.8vw + 8px)' }}>{ctx.language.hl4title}</h5>
                    <p>{ctx.language.hl4desc}</p>
                </Col>
                <Col xs={6} md={4} className="text-center" style={{ paddingTop: '1.5vw' }}>
                    <img src='icons/booking.png' alt='icon' style={{ height: 'auto', width: '8rem' }} />
                    <h5 className="my-3" style={{ fontSize: 'calc(0.8vw + 8px)' }}>{ctx.language.hl3title}</h5>
                    <p>{ctx.language.hl3desc}</p>
                </Col>
                <Col xs={6} md={4} className="text-center" style={{ paddingTop: '1.5vw' }}>
                    <img src='icons/relax.png' alt='icon' style={{ height: 'auto', width: '6.5rem' }} />
                    <h5 className="my-3" style={{ fontSize: 'calc(0.8vw + 8px)' }}>{ctx.language.hl2title}</h5>
                    <p>{ctx.language.hl2desc}</p>
                </Col>
                <Col xs={6} md={4} className="text-center" style={{ paddingTop: '1.5vw' }}>
                    <img src='icons/dryer.png' alt='icon' style={{ height: 'auto', width: '6.5rem' }} />
                    <h5 className="my-3" style={{ fontSize: 'calc(0.8vw + 8px)' }}>{ctx.language.hl5title}</h5>
                    <p>{ctx.language.hl5desc}</p>
                </Col>
                <Col xs={6} md={4} className="text-center" style={{ paddingTop: '1.5vw' }}>
                    <img src='icons/headset2.png' alt='icon' style={{ height: 'auto', width: '6.5rem' }} />
                    <h5 className="my-3" style={{ fontSize: 'calc(0.8vw + 8px)' }}>{ctx.language.hl6title}</h5>
                    <p>{ctx.language.hl6desc}</p>
                </Col>
            </Row>

        </Container>
    )
}

export default Highlights
