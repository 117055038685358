import React, {useContext} from 'react'
import { Container, Row, Col, Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import LanguageContext from '../store/LanguageContext'

const GetStartedToday = () => {
    const ctx = useContext(LanguageContext)
    return (
        <Container fluid className='py-5' style={{ backgroundColor: 'var(--color-lightgrey)' }}>
            <Row>
                <Col className='d-flex text-center flex-column justify-content-center'>
                    <h2 className='mb-4'>{ctx.language.hl6getstartedtoday}</h2>
                    <Link to='/'>
                        <Button color='danger' size='lg' style={{ fontSize: 'calc(5px + 0.8vw)', fontWeight: '600' }}>{ctx.language.btnClickme}</Button>
                    </Link>
                </Col>
            </Row>
        </Container>
    )
}

export default GetStartedToday
