import React, { useState, useEffect } from "react";
import "./Branch.scss";
import { Alert } from "reactstrap";
import { HiOutlineCheckCircle } from "react-icons/hi";

const Branch = (props) => {
  const [additionalStyle, setAdditionalStyle] = useState();


  useEffect(() => {
    props.checked ? setAdditionalStyle("cards__active") : setAdditionalStyle("");
  }, [props.checked]);

  const paymentClass = props.payment ? "cards__payment" : "";
  const additionalStyle02 = props.checked02 ? "cards__active02" : "";

  return (
    <div
      className={`cards ${paymentClass} ${additionalStyle02}`}
      onClick={props.onClick}
      style={{
        border: props.border ? `3px solid ${props.border}` : "",
      }}
    >
      {props.nearest && <div className="ribbon"><span>Terdekat</span></div>}
      {props.checked && <div className="cards__selected">
        {/* <h1>Selected</h1><img alt="selected" src="/pictures/selected.png" /> */}
        <div className="branch__selected">
          <h1 className="m-0"><HiOutlineCheckCircle style={{ color: 'var(--primary-color)' }} /></h1>
          <h5 style={{ color: 'var(--secondary-color)', fontSize: 'calc(0.5rem + 0.5vw)' }}>Selected</h5>
        </div>
      </div>}
      <div
        className={`cards__image ${additionalStyle}`}
        style={{
          backgroundImage: `url("${props.image}")`,
          backgroundSize: props.containImg ? "contain" : "cover",
          backgroundPosition: props.containImg ? "center" : "right",
          backgroundRepeat: props.containImg ? "no-repeat" : "repeat",
          height: props.containImg ? "100px" : "150px",
        }}
      >
      </div>
      {(props.review || props.area) && (
        <div className={`cards__description ${additionalStyle}`}>
          <div className="cards__rating text-center">
            <h5 className="text-center">{props.title}</h5>
            <span className="text-nowrap" style={{ fontSize: 'calc(5px + 0.4vw)' }}>{props.review}</span>
          </div>
          <div className="d-flex cards__area justify-content-center">
            <Alert
              className="d-flex w-75 mx-auto mx-sm-0 col-10 col-sm-8 col-md-6 col-lg-7 justify-content-center align-items-center"
              color="info"
              style={{
                fontSize: "calc(6px + 0.4vw)",
                padding: "calc(3px + 0.2vw)",
                fontWeight: "600",
                color: "var(--primary-color)",
                margin: "0.5rem 1rem 0.2rem 0rem",
                borderRadius: "10px"
              }}
            >
              {props.area}
            </Alert>
          </div>
        </div>
      )}
    </div>
  );
};

export default Branch;
