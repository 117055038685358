import React, { useState, useContext } from 'react';
import Typewriter from "typewriter-effect";
import { Typography } from "@material-ui/core";
import {
    Button,
    Collapse,
    Navbar,
    NavbarToggler,
    // NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    Container,
    Row,
    Col,
    // UncontrolledDropdown,
    // DropdownToggle,
    // DropdownMenu,
    // DropdownItem,
    // NavbarText
} from 'reactstrap';
import './Header.scss'
import AddressBar from './AddressBar';
// import Branch from './Branch';
import Logo from '../logo.png'
import { Link } from 'react-router-dom'
import CustomSimpleLottiePlayer from '../components/CustomSimpleLottiePlayer'
import animation from '../animations/click-me.json'
import LanguageContext from '../store/LanguageContext';

const Header = () => {
    const ctx = useContext(LanguageContext)
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);
    return (
        <Container fluid className="header">
            <Row>
                <Col>
                    <h1 className="header__icon"><img src={Logo} style={{ position: 'absolute', top: '2%', left: '2%', width: 'calc(10vw + 7rem)', minWidth: '6rem', height: 'auto' }}></img></h1>
                </Col>
                <Col className='d-flex justify-content-end'>
                    <Navbar dark expand="md">
                        <div></div>
                        <NavbarToggler onClick={toggle} />
                        <Collapse isOpen={isOpen} navbar>
                            <Nav className="ml-auto flex-md-row-reverse align-items-center header__navbar" navbar>
                                {/* <NavItem>
                                    <NavLink href="/signup/"><Button color='danger'>SIGNUP</Button></NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="/login/"><Button outline color='danger'>LOGIN</Button></NavLink>
                                </NavItem> */}
                                <NavItem >
                                    <NavLink><Button color='info' onClick={ctx.switchLanguage}><b style={{ color: 'white' }} >{ctx.language.changeName}</b></Button></NavLink>
                                </NavItem>
                                {/* <NavItem>
                                    <NavLink><Button style={{ backgroundColor: 'transparent', border: 'none' }}>FRANCHISE INFO</Button></NavLink>
                                </NavItem> */}
                                {/* <NavItem >
                                    <NavLink><Button style={{ backgroundColor: 'transparent', border: 'none' }}>OUR LOCATIONS</Button></NavLink>
                                </NavItem> */}
                                {/* <NavItem >
                                    <NavLink><Link to=''><Button style={{ backgroundColor: 'transparent', border: 'none' }}>PROMOTION</Button></Link></NavLink>
                                </NavItem>
                                <NavItem >
                                    <NavLink><Link to='/Order'><Button style={{ backgroundColor: 'transparent', border: 'none' }}>ONLINE ORDER</Button></Link></NavLink>
                                </NavItem> */}
                            </Nav>
                            {/* <NavbarText>Simple Text</NavbarText> */}
                        </Collapse>
                    </Navbar>
                </Col>
            </Row>
            <Row>
                <Col className="d-flex flex-column w-100 align-items-center justify-content-center header__title">
                    {/* <span style={{ color: 'white', fontSize: 'calc(7px + 1vw)' }}><b>Cucian menjadi lebih</b></span> */}
                    <span style={{ color: 'white', fontSize: 'calc(7px + 0.7vw)' }}><b>{ctx.language.headerSubtitle}</b></span>
                    {/* <span style={{ color: 'white' }}>Why Pelangi Laundry?</span> */}
                    {/* <Typography m={2} variant="h2" style={{ textAlign:'center', fontSize: 'calc(8px + 2.5vw)', fontWeight: 'bold', color: 'white' }}> */}
                    <Typography m={2} variant="h1" style={{ textAlign: 'center', fontWeight: '900', fontSize: 'calc(15px + 2vw)', color: 'white' }}>
                        <span>{ctx.language.headerTitle}<br /> </span>
                        <Typewriter
                            options={{
                                // strings: ["Budayakan malas mencuci karna mencuci adalah tugas kami", "Bersih, cepat, wangi, murah", "Cuci nggak repot hanya di PELANGI LAUNDRY !"],
                                strings: ctx.language.typewriter,
                                // strings: ["Cuci nggak ribet lagi !", "Baju bersih tanpa repot !", "Cucian bersih seketika !", "Lebih banyak waktu untuk keluarga !"],
                                // strings: ["Less stress !", "More free time !", "More family time !", "More relaxing !"],
                                autoStart: true,
                                loop: true,
                                delay: 200,
                            }}
                        />
                    </Typography>
                </Col>
            </Row>
            <Row>
                <Col className='d-flex justify-content-center align-items-center' style={{ position: 'relative' }}>
                    {/* <AddressBar /> */}
                    <Link to='/' className='header__btnClickme'>
                        <Button color='danger' size='lg' style={{ fontSize: 'calc(5px + 0.8vw)', fontWeight: '600', zIndex: '2' }}>{ctx.language.btnClickme}</Button>
                        {/* <Button color='danger' size='lg'>Pick My Laundry Now</Button> */}
                    </Link>
                    <div className='header__animation' style={{ position: 'absolute', color: 'white' }}>
                        <CustomSimpleLottiePlayer animationData={animation} width='calc(7vw + 3rem)' height='auto' />
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default Header
