import React from "react";
import NavHeader from "./NavHeader";
import Footer from "./Footer";

const Layout = ({ children }) => {
    return (
        <>
            <NavHeader />
            {children}
            <Footer />
        </>
    );
};

export default Layout;
