import React from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import OperationalAlert from './OperationalAlert';

const ModalOperational = ({ open, handleClose }) => {
    return (
        <Modal
            size="md"
            scrollable
            isOpen={open}
            toggle={handleClose}
            centered
            zIndex={3050}
        >
            <ModalHeader
                toggle={handleClose}
                cssModule={{ 'modal-title': 'w-100 text-center', 'close': 'btn-close' }}
                charCode=""
                style={{ backgroundColor: 'var(--primary-color)' }}
            >
                <span style={{ marginLeft: "calc(0.2vw + 4%)", color: "white" }}>Perhatian</span>
            </ModalHeader>
            <ModalBody className="p-4 p-md-4 d-flex flex-column">
                <OperationalAlert />
            </ModalBody>
            <ModalFooter
                className="py-2 py-md-3"
                style={{ justifyContent: "center" }}
            >

                <Button
                    size="sm"
                    className="py-1 px-2 py-md-2 px-md-3"
                    color="danger"
                    style={{
                        color: 'white',
                        border: '1px solid var(--primary-color)',
                        backgroundColor: 'var(--primary-color)',
                        fontSize: "calc(10px + 0.5vw)"
                    }}
                    onClick={handleClose}
                >
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default ModalOperational;