import React, { useState, useEffect, useContext } from 'react'
import {
    Input,
    Row,
    Col,
    Container,
    Card,
    CardHeader,
    CardBody,
    Button,
    Label,
    Form,
    FormGroup
} from "reactstrap";
import "./SelectAddress.scss";
import { HiLocationMarker, HiPhone } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { numberWithCommas } from "../utils/utils";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from "react-places-autocomplete";
import LanguageContext from "../store/LanguageContext";
import { mainContext } from "../store/MainContext";
import Branch from "../components/Branch";
import Layout from "../components/Reusable/Layout";

const SelectAddress = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const ctx = useContext(LanguageContext);
    const ctxm = useContext(mainContext);
    const [durations, setDurations] = useState([]);
    const [nearestPakel, setNearestPakel] = useState(false);
    const [nearestNusukan, setNearestNusukan] = useState(false);
    const [nearestGentan, setNearestGentan] = useState(false);
    const [nearestSoloBaru, setNearestSoloBaru] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    const navigate = useNavigate();
    useEffect(() => {
        if (ctxm.service.length === 0 || ctxm.finished) {
            navigate("/");
        }
    }, [ctxm.service, ctxm.finished]);

    const handleSelect = (value) => {
        ctxm.setAddressSelection(true);
        ctxm.setAddress(value);
        geocodeByAddress(value)
            .then((result) => {
                ctxm.setOrigin(result[0].place_id);
                getAllDirection(result[0].place_id);
            })
            .then((results) => getLatLng(results[0]))
            .catch((error) => console.error("Error", error));
    };

    const handleSelectedBranch = (value) => {
        ctxm.setSelectedOption(value);
        if (value === 1) {
            ctxm.setDestination(ctxm.placeid_branch1);
            ctxm.setDestinationName("Pakel");
            ctxm.setEmailTemplate("template_pakel");
        } else if (value === 2) {
            ctxm.setDestination(ctxm.placeid_branch2);
            ctxm.setDestinationName("Nusukan");
            ctxm.setEmailTemplate("template_pelangi");
        } else if (value === 3) {
            ctxm.setDestination(ctxm.placeid_branch3);
            ctxm.setDestinationName("Gentan");
            ctxm.setEmailTemplate("template_pakel");
        } else if (value === 4) {
            ctxm.setDestination(ctxm.placeid_branch4);
            ctxm.setDestinationName("Solo Baru");
            ctxm.setEmailTemplate("template_pelangi");
        }
    };

    const getAllDirection = async (val) => {
        try {
            await Promise.all([
                axios.get(`${process.env.REACT_APP_BE_URL}/gmap/directions/place_id:${val}/place_id:${ctxm.placeid_branch1}`),
                axios.get(`${process.env.REACT_APP_BE_URL}/gmap/directions/place_id:${val}/place_id:${ctxm.placeid_branch2}`),
                axios.get(`${process.env.REACT_APP_BE_URL}/gmap/directions/place_id:${val}/place_id:${ctxm.placeid_branch3}`),
                axios.get(`${process.env.REACT_APP_BE_URL}/gmap/directions/place_id:${val}/place_id:${ctxm.placeid_branch4}`)
            ])
                .then(response => {
                    setDurations([
                        response[0].data.routes[0].legs[0].duration.value,
                        response[1].data.routes[0].legs[0].duration.value,
                        response[2].data.routes[0].legs[0].duration.value,
                        response[3].data.routes[0].legs[0].duration.value
                    ]);
                })
        } catch (e) {
            console.log(e);
        }
    };

    const calculateDistance = () => {
        const distance = Math.min(...durations);

        if (distance === durations[0]) {
            setNearestPakel(true);
            setNearestNusukan(false);
            setNearestGentan(false);
            setNearestSoloBaru(false);
        } else if (distance === durations[1]) {
            setNearestPakel(false);
            setNearestNusukan(true);
            setNearestGentan(false);
            setNearestSoloBaru(false);
        } else if (distance === durations[2]) {
            setNearestPakel(false);
            setNearestNusukan(false);
            setNearestGentan(true);
            setNearestSoloBaru(false);
        } else if (distance === durations[3]) {
            setNearestPakel(false);
            setNearestNusukan(false);
            setNearestGentan(false);
            setNearestSoloBaru(true);
        }
        // console.log("Pakel : " + durations[0] + ", Nusukan : " + durations[1] + ", Gentan : " + durations[2] + ", Solo Baru : " + durations[3]);
    }

    useEffect(() => {
        if (durations.length > 0) {
            calculateDistance();
        }
    }, [durations])


    const handleNextButton = (e) => {
        e.preventDefault();
        if (ctxm.TotalPrice === 0) {
            alert("Tentukan pilihan jenis laundry Anda terlebih dahulu !");
        } else if (ctxm.addressSelection === false) {
            alert("Pilih lokasi alamat sesuai dengan google map yang di tampilkan !");
        } else if (ctxm.selectedOption === 0) {
            alert("Tentukan pilihan cabang yang dituju terlebih dahulu !")
        } else if (ctxm.selectedOption > 0 && ctxm.selectedOption < 5 && ctxm.selectedOption !== 0 && ctxm.addressSelection === true && ctxm.TotalPrice !== 0) {
            // since it is free, we will comment this out
            getDirectionAPI();
        } else alert("Sistem kami sedang dalam gangguan, mohon menghubungi kami melalui live chat !");
    };

    const getDirectionAPI = async () => {
        setIsLoading(true);
        try {
            await axios.get(`${process.env.REACT_APP_BE_URL}/gmap/directions/place_id:${ctxm.origin}/place_id:${ctxm.destination}`)
                .then((response) => {
                    ctxm.setDuration(response.data.routes[0].legs[0].duration.value);
                    setIsLoading(false);
                    navigate("/service/payment");
                });
        } catch (e) {
            console.log(e);
            ctxm.setDuration(0);
            setIsLoading(false);
            navigate("/service/payment");
        }
    };

    return (
        <Layout>
            <Container className="select-address mb-5">
                <Form onSubmit={handleNextButton}>
                    <Row>
                        <Col md="12">
                            <Card className='address-card'>
                                <CardHeader className='address-card-header'>
                                    <Row>
                                        <Col md={6} xs={12} className="mb-3">
                                            <span className="d-block d-md-none">Langkah 2/3</span>
                                            <h3 className="mb-0">Lokasi Pengambilan</h3>
                                            <span className="d-none d-md-block">Langkah 2/3</span>
                                        </Col>
                                        <Col md={6} xs={12} className="text-md-end">
                                            <span><b>{ctxm.totalService}</b> Layanan yang anda pilih</span>
                                            <br />
                                            <b>Rp {numberWithCommas(ctxm.TotalPrice)}</b>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col xs={12}>
                                            <PlacesAutocomplete
                                                value={ctxm.address}
                                                onChange={handleSelect}
                                                onSelect={handleSelect}
                                                debounce={500}
                                                searchOptions={{
                                                    componentRestrictions: {
                                                        country: ['id'],
                                                    },
                                                    location: new window.google.maps.LatLng(-7.55921, 110.81903), // Surakarta
                                                    radius: 500,
                                                }}
                                            >
                                                {({
                                                    getInputProps,
                                                    suggestions,
                                                    getSuggestionItemProps,
                                                    loading,
                                                }) => (
                                                    <div>
                                                        {/* <h4>Tentukan Lokasi Pengambilan</h4> */}
                                                        <h4>{ctx.language.subTitle2}</h4>
                                                        <Input
                                                            required
                                                            className="placeholderText"
                                                            type="address"
                                                            name="address"
                                                            // placeholder="Cari lokasi penjemputan sesuai peta. Contoh : Perumahan Fajar Indah Permata 2 Surakarta / Fave Hotel Manahan"
                                                            placeholder="Contoh : Perumahan Fajar Indah Permata 2 Surakarta / Fave Hotel Manahan"
                                                            style={{ width: "100%", margin: "1rem 0" }}
                                                            {...getInputProps({
                                                                className: "location-search-input placeholderText",
                                                            })}
                                                            autoComplete="on"
                                                        />
                                                        <div className="autocomplete-dropdown-container">
                                                            {loading && <div>Loading...</div>}
                                                            {suggestions.map((suggestion) => {
                                                                const className = suggestion.active
                                                                    ? "suggestion-item--active"
                                                                    : "suggestion-item";
                                                                // inline style htmlFor demonstration purpose
                                                                const style = suggestion.active
                                                                    ? {
                                                                        backgroundColor: "#fafafa",
                                                                        cursor: "pointer",
                                                                    }
                                                                    : {
                                                                        backgroundColor: "#ffffff",
                                                                        cursor: "pointer",
                                                                    };
                                                                return (
                                                                    <div
                                                                        key={suggestion.placeId}
                                                                        {...getSuggestionItemProps(suggestion, {
                                                                            className,
                                                                            style,
                                                                        })}
                                                                    >
                                                                        <span>{suggestion.description}</span>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                )}
                                            </PlacesAutocomplete>
                                        </Col>
                                        {/* GOOGLE MAP */}
                                        <Col xs={12} className="mt-2">
                                            <iframe
                                                title="googlemap"
                                                style={{
                                                    width: "100%",
                                                    height: "40vh",
                                                    border: "0",
                                                    borderRadius: "10px",
                                                }}
                                                loading="lazy"
                                                allowFullScreen
                                                src={`https://www.google.com/maps/embed/v1/place?q=place_id:${ctxm.origin}&key=${process.env.REACT_APP_GMAP_API_KEY}`}
                                            ></iframe>
                                        </Col >

                                        <Col className="my-3" sm={12}>
                                            <FormGroup>
                                                <Label htmlFor="deliverynotes">
                                                    <b>Catatan Detail Lokasi (opsional):</b>
                                                </Label>
                                                <Input
                                                    className="mt-2 placeholderText"
                                                    type="textarea"
                                                    name="text"
                                                    id="deliverynotes"
                                                    placeholder="Tambahkan detail lokasi penjemputan. Contoh: Blok A2 Nomor 5 / Rumah No 2 sebelah warung makan Nini Thowong"
                                                    onChange={(e) => ctxm.setDeliveryNotes(e.target.value)}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row >

                                    <Row className="mt-4">
                                        <Col lg={12} className="mb-2">
                                            <b>Pilih Cabang Laundry</b>
                                        </Col>
                                        <Col
                                            className="d-flex justify-content-center align-items-center my-2 my-sm-0"
                                            xs={6}
                                            lg={3}
                                        >
                                            <Branch
                                                title="Pelangi Pakel"
                                                image="/pictures/pelangi_pakel.jpg"
                                                nearest={nearestPakel}
                                                review={
                                                    <span>
                                                        <HiPhone style={{ fontSize: 'calc(8px + 0.5vw)' }} /> Telp. 0895326418881
                                                        <br />
                                                        <br />
                                                        <HiLocationMarker style={{ fontSize: 'calc(8px + 0.5vw)' }} /> Jl. Pakel 11 C Ruko no.2 Kerten
                                                        <br />
                                                        Laweyan, Solo
                                                    </span>
                                                }
                                                onClick={
                                                    ctxm.selectedOption !== 1
                                                        ? () => handleSelectedBranch(1)
                                                        : () => handleSelectedBranch(0)
                                                }
                                                checked={ctxm.selectedOption === 1 ? true : false}
                                                area="Daerah Manahan"
                                            />
                                        </Col>
                                        <Col
                                            className="d-flex justify-content-center align-items-center my-2 my-sm-0"
                                            xs={6}
                                            lg={3}
                                        >
                                            <Branch
                                                title="Pelangi Nusukan"
                                                image="/pictures/pelangi_nusukan.jpg"
                                                nearest={nearestNusukan}
                                                review={
                                                    <>
                                                        <HiPhone style={{ fontSize: 'calc(8px + 0.5vw)' }} /> Telp. 081542163595
                                                        <br />
                                                        <br />
                                                        <HiLocationMarker style={{ fontSize: 'calc(8px + 0.5vw)' }} /> Jl. Kapt. Pierre Tendean 87A
                                                        <br />
                                                        Nusukan, Solo
                                                    </>
                                                }
                                                onClick={
                                                    ctxm.selectedOption !== 2
                                                        ? () => handleSelectedBranch(2)
                                                        : () => handleSelectedBranch(0)
                                                }
                                                checked={ctxm.selectedOption === 2 ? true : false}
                                                area="Daerah Nusukan"
                                            />
                                        </Col>
                                        <Col
                                            className="d-flex justify-content-center align-items-center my-2 my-sm-0"
                                            xs={6}
                                            lg={3}
                                        >
                                            <Branch
                                                title="Pelangi Gentan"
                                                image="/pictures/pelangi_gentan.jpg"
                                                nearest={nearestGentan}
                                                review={
                                                    <>
                                                        <HiPhone style={{ fontSize: 'calc(8px + 0.5vw)' }} /> Telp. 089514781888
                                                        <br />
                                                        <br />
                                                        <HiLocationMarker style={{ fontSize: 'calc(8px + 0.5vw)' }} /> Jl. Raya Songgolangit 30B
                                                        <br />
                                                        Gentan, Sukoharjo
                                                    </>
                                                }
                                                onClick={
                                                    ctxm.selectedOption !== 3
                                                        ? () => handleSelectedBranch(3)
                                                        : () => handleSelectedBranch(0)
                                                }
                                                checked={ctxm.selectedOption === 3 ? true : false}
                                                area="Daerah Gentan"
                                            />
                                        </Col>
                                        <Col
                                            className="d-flex justify-content-center align-items-center my-2 my-sm-0"
                                            xs={6}
                                            lg={3}
                                        >
                                            <Branch
                                                title="Pelangi Solo Baru"
                                                image="/pictures/pelangi_pakel.jpg"
                                                // image="/pictures/comingsoon.jpg"
                                                nearest={nearestSoloBaru}
                                                review={
                                                    <>
                                                        <HiPhone style={{ fontSize: 'calc(8px + 0.5vw)' }} /> Telp. 081227929168
                                                        <br />
                                                        <br />
                                                        <HiLocationMarker style={{ fontSize: 'calc(8px + 0.5vw)' }} /> Jl. Raya Djlopo GP37, Gedangan
                                                        <br /> Sukoharjo
                                                    </>
                                                }
                                                onClick={
                                                    ctxm.selectedOption !== 4
                                                        ? () => handleSelectedBranch(4)
                                                        : () => handleSelectedBranch(0)
                                                }
                                                checked={ctxm.selectedOption === 4 ? true : false}
                                                area="Daerah Solo Baru"
                                            />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xs={12} style={{ textAlign: 'right' }}>
                                            <Button
                                                size="md"
                                                className="btn-next mt-3"
                                                type="submit"
                                                disabled={isLoading ? true : false}
                                            >
                                                {isLoading ? (
                                                    <div className="lds-ring">
                                                        <div className="circle" />
                                                        <div className="circle" />
                                                        <div className="circle" />
                                                    </div>
                                                ) : 'Lanjutkan'}
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Form>
            </Container>
        </Layout>
    )
}

export default SelectAddress