import React, { useState, useContext } from 'react'
import { Form, Row, Col, Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import "./ServiceCard.scss";
import { mainContext } from "../../store/MainContext";
import ItemPriceListCard from "./ItemPriceListCard";

const ServiceCard = (props) => {
    const ctxm = useContext(mainContext);

    const [showModal, setShowModal] = useState(false);
    const toggleModal = () => {
        setShowModal(!showModal);
    };

    const handleModal = () => {
        if (props.modal) {
            setShowModal(true)
        }
    }

    const handleClick = (e) => {
        e.preventDefault();
        toggleModal();
        ctxm.setShowCart(true);
    }

    function findQty(value) {
        const data = ctxm.service.find(data => data.name === value);
        return data ? data.qty : 0;
    }

    function findNote(value) {
        const data = ctxm.service.find(data => data.name === value);
        return data ? data.note : "";
    }

    return (
        <>

            <Col xl={2} lg={3} md={4} sm={4} xs={6} className="text-center my-3">
                <div onClick={handleModal} className='service-card' style={{ backgroundImage: `linear-gradient(to bottom, transparent 40%, var(--primary-color)), url("${props.image}")` }}>
                    <span className='service-card-title'>{props.title}</span>
                </div>
            </Col >

            {/* Show Modal */}
            <Modal size="lg" centered isOpen={showModal} toggle={toggleModal} cssModule={{ 'modal-content': 'service-card-modal-content modal-content' }} style={{ maxWidth: '950px' }}>
                <Form onSubmit={handleClick}>
                    <ModalHeader className="service-card-modal-header" toggle={toggleModal} cssModule={{ 'modal-title': 'w-100 text-center fw-bold mb-0', 'close': 'btn-close btn-close-white' }} charCode="">
                        <span style={{ fontSize: "calc(12px + 1vw)", marginLeft: "calc(0.2vw + 4%)" }}>{props.title}</span>
                    </ModalHeader>
                    <ModalBody className="p-4 p-md-4 d-flex flex-column">
                        <Row>
                            {props.modal && props.modal.map((item, i) => {
                                return (
                                    <Col md={4} className="my-2" key={i}>
                                        <ItemPriceListCard
                                            name={item.name}
                                            subname={item.subname}
                                            image={item.image}
                                            itemPrice={item.price}
                                            item={findQty(item.name + ' ' + item.subname)}
                                            note={findNote(item.name + ' ' + item.subname)}
                                        />
                                    </Col>
                                )
                            })}
                        </Row>
                        <Row>
                            <Col md={12}>
                                <hr className='mb-4' />
                                <div className='service-modal-footer'>
                                    <div className='text-sm-end'>
                                        <Button
                                            className='mx-sm-1'
                                            type='button'
                                            style={{
                                                color: 'var(--primary-color)',
                                                border: '1px solid var(--primary-color)',
                                                backgroundColor: 'transparent',
                                                borderRadius: '5px'
                                            }}
                                            onClick={toggleModal}
                                        >Tambah Layanan Lain</Button>
                                        <Button
                                            className='mx-sm-1'
                                            type='submit'
                                            style={{
                                                color: 'white',
                                                border: '1px solid var(--primary-color)',
                                                backgroundColor: 'var(--primary-color)',
                                                borderRadius: '5px'
                                            }}
                                        >Cuci Sekarang</Button>
                                        <span><b>{ctxm.totalService}</b> Layanan yang anda pilih</span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </ModalBody>
                </Form>
            </Modal>
        </>
    )
}

export default ServiceCard