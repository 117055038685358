import React, { useState } from "react";

const LanguageContext = React.createContext({
  language: "",
  switchLanguage: () => { },
});

const LANGUAGE_DATA = {
  ID: {
    name: "Indonesian",
    headerSubtitle: "Cuci nggak ribet hanya di Pelangi Laundry",
    headerTitle: "Cucian menjadi lebih",
    typewriter: ["Bersih", "Cepat", "Wangi", "Murah"],
    btnClickme: "Pesan sekarang juga !",
    hl1title: "Antar Jemput Laundry",
    hl1desc:
      "Tak perlu repot datang ke outlet kami. Kurir kami siap antar jemput pakaian Anda Senin-Sabtu di jam 09.00-17.00",
    hl2title: "Hemat Waktu",
    hl2desc:
      "Miliki lebih banyak waktu untuk aktivitas lainnya. Quality time bersama keluarga, bekerja, atau berlibur selagi laundry Anda kami kerjakan.",
    hl3title: "Pesan Online Dimanapun",
    hl3desc:
      "Pesan layanan laundry dimanapun Anda berada. Laundry tidak pernah semudah itu bukan?",
    hl4title: "Layanan Express",
    hl4desc: "Laundry hanya membutuhkan waktu 1.5 jam. Cuci, keringkan, lalu lipat. Sampaikan kepada kami bila Anda membutuhkan pakaian Anda segera.",
    hl5title: "Garansi Laundry",
    hl5desc:
      "Pelayanan kami bergaransi cuci kembali apabila cucian Anda tidak kering.",
    hl6title: "Layanan Pelanggan",
    hl6desc:
      "Kami siap melayani Anda di jam kerja. Hubungi customer service langsung melalui kolom chat yang tercantum di bawah bila ada pertanyaan.",

    hl0subscriptionTitle: "Berlangganan promosi & penawaran menarik !",
    hl0subscriptionPlaceholder: "Masukkan nomor ponsel Anda - contoh:",
    hlAnimation1Title: "Pengiriman GRATIS!",
    hlAnimation1: (
      <span>
        Free ongkir untuk radius kurang dari 3KM dengan transaksi minimal 40.000{" "}
        <br />
        *Radius lebih dari 3KM atau order kurang dari 40.000 dikenakan ongkir
        sesuai jarak*
      </span>
    ),
    hlAnimation2Title: "Pengiriman 100% Dijamin",
    hlAnimation2: <span>
      #1) Kami mengemasnya dengan kantong plastik khusus.
      <br />
      #2) Kami menggunakan driver berlisensi kami.
      <br />
      #3) Kami melacak setiap rute pengemudi kami.
      <br />
      #4) Kami akan memastikan bahwa pengemudi memiliki semua yang mereka butuhkan untuk menemukan rumah Anda.
      <br />
    </span>,
    hl2ProcessingTitle: "Pemrosesan Khusus",
    hl2ProcessingSubtitle: "Pakaian Anda akan dicuci dengan mesin teknologi terkini dan akan dicuci secara terpisah, 1 mesin untuk 1 customer.",
    hl2Processing: `Tidak seperti laundry lainnya, kami menggunakan real time technology yang memungkinkan mesin untuk memproses pakaian sesuai jumlahnya. Memastikan pakaian Anda tidak "asal cepat selesai" tapi "pasti bersihnya". Dryer kami mengeringkan pakaian tanpa harus dijemur dibawah sinar matahari, sehingga tidak bau matahari dan warna tetap cerah. Pakaian dipanaskan selama 30 - 50 menit untuk memastikan pakaian bebas bakteri, virus dan juga tungau. Proses mencuci hanya membutuhkan waktu 1,5 jam. Hemat waktu dan biaya.`,
    hl3flowTitle: "ALUR",
    hl3flow1: "ORDER PICK UP VIA WA",
    hl3flow2: "PAKAIAN DITIMBANG DI TEMPAT",
    hl3flow3: "PEMBAYARAN SECARA TUNAI/TRANSFER",
    hl3flow4: "NOTA ELEKTRONIK DIKIRIMKAN",
    hl3flow5: "PENGIRIMAN KE CUSTOMER",
    hl6getstartedtoday: "Tunggu apa lagi, mulai hari ini !",
    FAQTitle: "Pertanyaan yang Sering Diajukan",
    FAQ1title: "Berapa biaya laundrynya ?",
    FAQ1desc:
      "Paket cuci kering lipat tidak termasuk setrika. Untuk pakaian dengan berat dibawah 5kg bisa memilih paket 5kg 20.000 dan  pakaian dengan berat lebih dari 5kg masuk dalam paket cuci kering lipat 7kg  dengan harga Rp 30.000.  Anda dapat menambahkan layanan setrika dengan harga Rp 4000/kg.",
    FAQ2title: "Kapan cucian saya akan di kembalikan ?",
    FAQ2desc:
      "Laundry akan dikerjakan di hari yang sama. Pengerjaan cuci kering lipat membutuhkan waktu 1.5jam sedangkan untuk setrika H+1. Jika menginginkan laundry dikirimkan di jam tertentu harap infokan ke kurir untuk mengirimkan sesuai jadwal. Batas waktu delivery adalah jam 5 sore setiap harinya.",
    FAQ3title: "Bagaimana caranya saya memulai order ?",
    FAQ3desc:
      "Anda dapat mengisi form pesanan di halaman web dan mengisi data yang diperlukan seperti nama, alamat penjemputan dan no telepon yang dapat dihubungi. Kami akan mengirimkan konfirmasi pesanan melalui Whatsapp dan kurir akan menngambil laundry ke alamat Anda. Pembayaran dapat dilakukan secara tunai saat pengambilan melalui kurir atau transfer ke rekening kami.",
    FAQ4title: "Bagaimana proses pencuciannya ?",
    FAQ4desc:
      'Pakaian Anda dicuci dengan mesin teknologi terkini. Pakaian dicuci terpisah 1 mesin untuk 1 customer. Tidak seperti laundry lainnya, kami menggunakan real time technology yang memungkinkan mesin untuk memproses pakaian sesuai jumlahnya. Memastikan pakaian Anda tidak "asal cepat selesai" tapi "pasti bersihnya". Dryer kami mengeringkan pakaian tanpa harus dijemur di bawah sinar matahari,sehingga tidak bau matahari dan warna tetap cerah. Pakaian dipanaskan selama 30-50 menit untuk memastikan pakaian bebas bakteri, virus dan juga tungau.   Proses mencuci hanya membutuhkan waktu 1,5jam. Hemat waktu dan biaya.',
    FAQ5title: "Bagaimana jika perhitungan nota dari laundry berbeda dengan timbangan pelanggan?",
    FAQ5desc: 'Barang akan ditimbang didepan pelanggan saat kurir datang. Sehingga baik pelanggan dan kami sama-sama mengetahui berat sebenarnya. Selimut, bedcover, gaun pesta, jas, bantal, guling, sajadah, dan boneka tidak dapat dicampur pakaian, dan dihitung sebagai laundry satuan. Apabila terdapat tambahan barang yang tidak termasuk dalam nota, akan diinfokan sebelumnya. Apabila ada kelebihan atau kekurangan biaya, admin akan menghubungi Anda.',
    step1title: "Langkah 1: Lengkapi informasi pesanan Anda",
    step1info1:
      "Pastikan untuk memilih lokasi penjemputan yang benar dan cabang yang benar.",
    step1info2:
      "Memilih cabang terdekat akan membuat biaya pengiriman Anda lebih murah.",
    subTitle1: "Pilih Jenis Laundry :",
    subTitle2: "Tentukan Lokasi Pengambilan",
    subTitle3: "Pilih Cabang Laundry :",

    step2title: "Langkah 2: Silahkan lakukan pembayaran pesanan Anda",
    step2info1:
      'Pemesanan Anda akan dikirim setelah Anda mengklik "Order Now".',
    step2info2: "Anda akan diarahkan ke halaman berikutnya.",
    step1Warning: "Silahkan masukkan tipe pesanan laundry dan lokasi Anda dengan benar. Anda juga dapat menambahkan catatan jika dibutukan.",
    step2Warning: "Silahkan masukkan nama dan nomor whatsapp Anda dan pilih cara pembayaran Anda.",
    step2Fullname: "Nama Lengkap",
    step2FullnamePlaceholder: "Masukkan nama lengkap Anda",
    step2Whatsapp: "Nomor WhatsApp",
    step2Whatsapp2: '(untuk konfirmasi pemesanan)',
    step2WhatsappPlaceholder: "Masukkan nomor whatsapp Anda. Contoh: 081329003888",
    step2EmailPlaceholder: "Masukkan alamat email Anda",
    Step2Notes: "Catatan Tambahan",
    Step2Notes2: "(opsional)",

    changeName: "ENGLISH",
  },
  EN: {
    name: "English",
    headerSubtitle: "With Pelangi Laundry Coin",
    headerTitle: "Laundry becomes more",
    typewriter: ["Clean", "Simple", "Low Cost", "Fragrant"],
    btnClickme: "Order right now !",
    hl1title: "Laundry Delivery",
    hl1desc:
      "No need to bother coming to our outlet. Our couriers are ready to pick up your clothes Monday-Saturday at 09.00-17.00",
    hl2title: "Time Saving",
    hl2desc:
      "Have more time for other activities. Quality time with family, work, or vacation while we do your laundry.",
    hl3title: "Online Order",
    hl3desc:
      "Order laundry services wherever you are. Laundry was never that easy right?",
    hl4title: "Layanan Express",
    hl4desc: "Laundry only takes 1.5 hours.",
    hl5title: "Laundry Guaranteed",
    hl5desc:
      "Our service is guaranteed to wash again if your laundry is not dry.",
    hl6title: "Customer Service",
    hl6desc:
      "We are ready to serve you during business hours. Contact customer service at the number listed if you have any questions.",

    hl0subscriptionTitle: "Subscribe to new promotions & deals !",
    hl0subscriptionPlaceholder: "Enter your mobile number - ex:",
    hlAnimation1Title: "FREE Delivery!",
    hlAnimation1: (
      <span>
        Free shipping for a radius less than 3KM with a minimum transaction of
        Rp 40,000,00 <br />
        *Radius more than 3KM or orders less than 40,000 subject to postage
        according to distance*
      </span>
    ),
    hlAnimation2Title: "100% Delivery Guaranteed",
    hlAnimation2: <span>
      #1) We pack it with special plastic bag.
      <br />
      #2) We use our lisenced driver.
      <br />
      #3) We track every single of our driver routes.
      <br />
      #4) We will make sure that the driver have everything they need to find your home.
    </span>,
    hl2ProcessingTitle: "Special Processing",
    hl2ProcessingSubtitle: "Your clothes will be machine washed with the latest technology and will be washed separately, 1 machine for 1 customer.",
    hl2Processing: `Unlike other laundry, we use real time technology that allows machines to process clothes according to the amount. your clothes are not "as long as it's done quickly" but "surely clean". Our dryers dry clothes without having to dry them in the sun, so they don't smell from the sun and colors stay bright. Clothes are safe 30 - 50 minutes to ensure clothes are free of bacteria, viruses and mites. The washing process only takes 1.5 hours. Save time and cost.`,

    hl6getstartedtoday: "Get started today !",
    FAQTitle: "Frequently Asked Questions",
    FAQ1title: "How much is the laundry fee?",
    FAQ1desc:
      "The fold-down dry cleaning package does not include an iron. For clothes weighing under 5kg, you can choose a 5kg 20,000 package and clothes weighing more than 5kg are included in a 7kg dry-cleaning package at a price of IDR 30,000. You can add ironing service for IDR 4000/kg.",
    FAQ2title: "When will my laundry be returned?",
    FAQ2desc:
      "Laundry will be done on the same day. The folding dry cleaning process takes 1.5 hours, while the D+1 iron. If you want the laundry to be delivered at a certain time, please inform the courier to send it on schedule. The delivery deadline is 5 pm every day.",
    FAQ3title: "How do I start an order?",
    FAQ3desc:
      "You can fill in the order form on the web page and fill in the required data such as name, pick-up address and contact phone number. We will send an order confirmation via Whatsapp and the courier will pick up the laundry to your address. Payment can be made in cash upon collection via courier or transfer to our account.",
    FAQ4title: "How is the washing process?",
    FAQ4desc:
      'Your clothes are machine washed with the latest technology. Clothes are washed separately 1 machine for 1 customer. Unlike other laundry, we use real time technology which allows the machine to process clothes according to the amount. Make sure your clothes are not "just finished quickly" but "definitely clean". Our dryers dry clothes without having to dry in the sun, so they dont smell of sun and colors stay bright. The clothes are heated for 30-50 minutes to ensure that the clothes are free of bacteria, viruses and mites. The washing process only takes 1.5 hours. Save time and money.',

    step1title: "Step 1: Complete your order information",
    step1info1:
      "Make sure to select the correct pick-up location and correct branch.",
    step1info2:
      "Selecting the nearest branch will make your delivery fee cheaper",
    subTitle1: "Choose your laundry type :",
    subTitle2: "Choose your pick-up location",
    subTitle3: "Select preferece laundry branch :",

    step2title: "Step 2: Complete your account information",
    step2info1: 'Your booking will be submited once you click "Order Now".',
    step2info2: "You will be redirected to the next page.",
    step2Warning: "Please enter your info carefully. Once submitted it cannot be changed.",

    changeName: "BAHASA",


  },
};

export const LanguageContextProvider = (props) => {
  const [language, setLanguage] = useState(LANGUAGE_DATA.ID);

  const switchLanguage = () => {
    if (language !== LANGUAGE_DATA.EN) {
      setLanguage(LANGUAGE_DATA.EN);
    } else {
      setLanguage(LANGUAGE_DATA.ID);
    }
  };

  // const startOver = () => {
  //     setSelectedCharacter('');
  //     setSelectedWeapon('');
  //     setSelectedPlace('');
  //     setSelectedMonster('');
  //     setSelectedSkill('');
  // }

  return (
    <LanguageContext.Provider
      value={{
        language,
        switchLanguage,
      }}
    >
      {props.children}
    </LanguageContext.Provider>
  );
};
export default LanguageContext;
