import React, { useState, useEffect, useContext } from "react";
import {
    Row,
    Col,
    Container,
    Button
} from "reactstrap";
import "./Service.scss";
import Layout from "../components/Reusable/Layout";
import ServiceCard from '../components/Reusable/ServiceCard';
import { HiSearch } from "react-icons/hi";
import { mainContext } from "../store/MainContext";
import { service_data } from "../utils/serviceData";
import Typewriter from "typewriter-effect";

const Service = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const ctxm = useContext(mainContext);
    useEffect(() => {
        if (ctxm.finished) {
            ctxm.resetDefault();
        }
    }, [ctxm.finished])

    const [search, setSearch] = useState('');

    return (
        <Layout>
            <Container className="service mb-5">
                <Row>
                    <Col md="12">
                        <div className="service-header mb-5">
                            <div className="service-header-caption">
                                <p>Cuci nggak ribet hanya di Pelangi Laundry</p>
                                <h1 className="d-flex justify-content-center">
                                    Cucian menjadi lebih&nbsp;
                                    <Typewriter
                                        options={{
                                            strings: ["Bersih", "Cepat", "Wangi", "Murah"],
                                            autoStart: true,
                                            loop: true,
                                            delay: 200,
                                        }}
                                    />
                                </h1>
                            </div>
                        </div>
                    </Col>

                    <Col md="8">
                        <h1 className="service-title">Layanan Kami</h1>
                    </Col>

                    <Col md="4">
                        <div className="d-flex align-items-center justify-content-end">
                            <div className="input-group mb-3">
                                <input className="form-control service-search" type="text" name="search" placeholder="Cari layanan" onChange={(e) => { setSearch(e.target.value) }} />
                                <Button className="input-group-text" type="button"><HiSearch /></Button>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row>
                    {service_data.filter((val) => val.title.toLowerCase().includes(search.toLowerCase())).map((item, i) => {
                        return (
                            <ServiceCard
                                key={i}
                                title={item.title}
                                image={item.image}
                                modal={item.detail}
                            />
                        )
                    })}
                </Row>
            </Container>
        </Layout>
    )
}

export default Service