import React, { useState } from 'react'
import { Col, Collapse, Input } from 'reactstrap';
import { MdEventNote } from "react-icons/md";
import '../pages/bookingpage.scss'

const ItemPricelist = ({ name, subName = "", image, item, setItem, itemPrice, note, setNote }) => {
    const [collapse, setCollapse] = useState(false)

    function numberWithCommas(value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (
        <Col className="my-2 my-md-0" xs={12} md={6}>
            <div className="laundryContainer my-md-2 d-flex flex-column justify-content-center justify-content-md-start">
                <div className="laundryBody d-flex flex-row justify-content-between">
                    <div className="d-flex flex-row align-items-center">
                        <img className="laundryImg" src={image} alt='icon' />
                        <div className="laundryItem d-flex flex-column">
                            <span>{name} <span style={{ color: '#878484', fontSize: 'calc(9px + 0.4vw)', fontWeight: '400' }}>{subName}</span></span>
                            <span className="laundryItem_price">Rp {numberWithCommas(item === 0 ? itemPrice : itemPrice * item)},00</span>
                        </div>
                    </div>
                    <span
                        className="d-flex flex-row align-items-center"
                        style={{ fontWeight: "600" }}
                    >
                        <button
                            type="button"
                            className="laundryButton"
                            onClick={
                                item > 1
                                    ? () => setItem(item - 1)
                                    : () => {
                                        if (item !== 0) {
                                            setItem(item - 1);
                                        }
                                        setNote("");
                                        setCollapse(false);
                                    }
                            }
                        >
                            -
                        </button>{" "}
                        <span className="amountItem">{item} Item </span>
                        <button
                            type="button"
                            className="laundryButton"
                            onClick={() => {
                                if (item === 0) {
                                    setCollapse(true);
                                }
                                setItem(item + 1);
                            }}
                        >
                            +
                        </button>
                    </span>
                </div>
                <Collapse isOpen={collapse}>
                    <span className="d-flex align-items-center">
                        <MdEventNote
                            color="#999999"
                            className="me-1 mt-2"
                            size="1.5rem"
                        />
                        <Input
                            className="mt-2 placeholderText"
                            onChange={(e) => setNote(e.target.value)}
                            type="text"
                            placeholder="Tambahkan catatan..."
                            value={note}
                        />
                    </span>
                </Collapse>
            </div>
        </Col>
    )
}

export default ItemPricelist