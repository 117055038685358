import React, { useContext } from 'react'
import { Button, Container, Row, Col, Input } from 'reactstrap'
import LanguageContext from '../store/LanguageContext'

const Banner = () => {
    const ctx = useContext(LanguageContext)
    return (
        <Container fluid style={{ backgroundColor: '#f5f5f5', height: '12vw', minHeight: '11rem' }} className="d-flex justify-content-around align-items-center">
            <Row className='w-100 h-100 justify-content-center'>
                <Col className="d-none d-lg-flex justify-content-around align-items-center">
                    <img alt='newsletter' src='../pictures/newsletter.jpg' style={{ width: '10rem' }} />
                </Col>
                <Col xs={5} className="d-flex justify-content-around align-items-center">
                    <div className='text-center'>
                        <h3 className='mb-3' style={{ fontSize: 'calc(10px + 1vw)' }}>{ctx.language.hl0subscriptionTitle}</h3>
                        <div className='d-lg-flex align-items-center'>
                            <Input className='mr-0 mr-lg-1' style={{ fontSize: 'calc(8px + 0.5vw)', minWidth: '17rem', width: '25vw' }} type="phone" name="phone" placeholder={ctx.language.hl0subscriptionPlaceholder + " 081234001999"} />
                            <Button className='mt-3 mt-lg-0' style={{ width: '7rem', minWidth: '6rem', fontSize: 'calc(6px + 0.5vw)' }} color="danger"> SUBSCRIBE </Button>
                        </div>
                    </div>
                </Col>
                <Col className="d-none d-lg-flex justify-content-around align-items-center">
                    <img alt='newsletter' src='../pictures/newsletter.jpg' style={{ width: '10rem' }} />
                </Col>
            </Row>
        </Container>
    )
}

export default Banner