import React from 'react'
import { Container, Row, Col, Button, Collapse, Card, CardBody } from 'reactstrap'
import { useState, useContext } from 'react'
import LanguageContext from '../store/LanguageContext'

const FAQ = () => {
    const ctx = useContext(LanguageContext)
    const [collapse1, setCollapse1] = useState(false);
    const [collapse2, setCollapse2] = useState(false);
    const [collapse3, setCollapse3] = useState(false);
    const [collapse4, setCollapse4] = useState(false);
    const [collapse5, setCollapse5] = useState(false);

    const toggle1 = () => {
        setCollapse1(!collapse1);
    }
    const toggle2 = () => {
        setCollapse2(!collapse2);
    }
    const toggle3 = () => {
        setCollapse3(!collapse3);
    }
    const toggle4 = () => {
        setCollapse4(!collapse4);
    }
    const toggle5 = () => {
        setCollapse5(!collapse5);
    }
    return (
        <Container fluid className='py-5' style={{ backgroundColor: 'var(--color-secondary)' }}>
            <Container>
                <Row>
                    <Col className='mx-2 mx-md-5 px-0 px-md-5 d-flex text-center flex-column justify-content-center' style={{ color: 'white' }}>
                        <h2>{ctx.language.FAQTitle} (FAQ)</h2>
                        <Button color='primary' className='mt-4 mb-2' onClick={toggle1}>{ctx.language.FAQ1title}</Button>
                        <Collapse isOpen={collapse1}>
                            <Card>
                                <CardBody style={{ color: '#505050', textAlign: 'justify' }}>
                                    {ctx.language.FAQ1desc}
                                </CardBody>
                            </Card>
                        </Collapse>
                        <Button color='primary' className='my-2' onClick={toggle2}>{ctx.language.FAQ2title}</Button>
                        <Collapse isOpen={collapse2}>
                            <Card>
                                <CardBody style={{ color: '#505050', textAlign: 'justify' }}>
                                    {ctx.language.FAQ2desc}
                                </CardBody>
                            </Card>
                        </Collapse>
                        <Button color='primary' className='my-2' onClick={toggle3}>{ctx.language.FAQ3title}</Button>
                        <Collapse isOpen={collapse3}>
                            <Card>
                                <CardBody style={{ color: '#505050', textAlign: 'justify' }}>
                                    {ctx.language.FAQ3desc}
                                </CardBody>
                            </Card>
                        </Collapse>
                        <Button color='primary' className='my-2' onClick={toggle4}>{ctx.language.FAQ4title}</Button>
                        <Collapse isOpen={collapse4}>
                            <Card>
                                <CardBody style={{ color: '#505050', textAlign: 'justify' }}>
                                    {ctx.language.FAQ4desc}
                                </CardBody>
                            </Card>
                        </Collapse>
                        <Button color='primary' className='my-2' onClick={toggle5}>{ctx.language.FAQ5title}</Button>
                        <Collapse isOpen={collapse5}>
                            <Card>
                                <CardBody style={{ color: '#505050', textAlign: 'justify' }}>
                                    {ctx.language.FAQ5desc}
                                </CardBody>
                            </Card>
                        </Collapse>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

export default FAQ
